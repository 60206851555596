import React from "react";
import "../../Assets/Css/HamburgerMenuIcon.css";

const HamburgerMenuIcon = (props) => {

  return (
    <div className={`hamburger-menu-icon ${props.active}`} onClick={() => props.active===""?props.setActive("active"):props.setActive("")}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
export default HamburgerMenuIcon;