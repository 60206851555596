import React from "react";
import YouTube from "react-youtube";
import { Col, Divider, Row, Typography } from "antd";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import Register1Image from "../../Assets/Images/Register/register-1.png";
import Register2Image from "../../Assets/Images/Register/register-2.png";
import Register3Image from "../../Assets/Images/Register/register-3.png";
import Register4Image from "../../Assets/Images/Register/register-4.png";
import WebPDF from "../../Assets/Pdf/SFAST-User_Guide-Web.pdf";
import DesktopPDF from "../../Assets/Pdf/SFAST-User_Guide-Desktop.pdf";
import { Link } from "react-router-dom";

const { Text } = Typography;

function Register() {
  const opts = {
    height: "718px",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <LayoutPage>
      <PageHeader title="Cara Pendaftaran" path={`/register`} />
      <div className="layout-single-page">
        <YouTube videoId="-4Byh-VBPrc" opts={opts} />
        <Divider className="divider" />
        <Row gutter={20} justify={"space-between"}>
          <Col md={24} lg={12}>
            <Text className="h2">Nasabah Baru</Text>
            <Text className="body">
              <p>Buka rekening baru hanya dengan beberapa langkah mudah</p>
              <p>
                <ol>
                  <li>
                    Mengisi Formulir Pembukaan Rekening Efek (FPRE) dengan
                    lengkap dan benar serta menandatangani Perjanjian Nasabah
                    (PN) diatas materai.
                  </li>
                  <li>
                    Mengisi salah satu Formulir Pembukaan Rekening Dana (FPRD)
                    dengan lengkap dan benar serta menandatangani diatas
                    materai. Pilihan Bank yang tersedia saat ini BCA, CIMB
                    Niaga, Mandiri, Mayapada
                  </li>
                  <li>
                    Mengisi Formulir Tatap Muka dengan lengkap dan benar serta
                    menandatangani
                  </li>
                  <li>
                    Mengisi Addendum Perjanjian Pembukaan Online Trading (PPOT)
                  </li>
                  <li>
                    Mengisi Surat Kuasa dengan lengkap dan benar serta
                    menandatangani
                  </li>
                  <li>
                    Mengisi Surat pernyataan tidak memiliki NPWP dengan lengkap
                    dan benar serta menandatangani (Optional)
                  </li>
                  <li>Menyerahkan dokumen-dokumen sebagai berikut:
                    <ol style={{ listStyleType: "lower-alpha", marginBottom: "0" }}>
                      <li>Fotocopy KTP/Passport Nasabah dan/atau Kuasanya.</li>
                      <li>Fotocopy NPWP</li>
                      <li>Fotocopy Cover Buku Tabungan</li>
                    </ol>
                  </li>
                  <li>Wajib mengirimkan dokumen asli dalam 5 hari kerja.</li>
                </ol>
              </p>
            </Text>
          </Col>
          <Col md={24} lg={12}>
            <Text className="h2">Nasabah Terdaftar</Text>
            <Text className="body">
              <p>Buka rekening baru hanya dengan beberapa langkah mudah</p>
              <p>
                <ol>
                  <li>Mengisi Addendum Perjanjian Pembukaan Online Trading (PPOT)</li>
                  <li>Wajib mengirimkan dokumen asli dalam 5 hari kerja.
                    <ol style={{ listStyleType: "lower-alpha", marginBottom: "0" }}>
                      <li>Fotocopy KTP/Passport Nasabah dan/atau Kuasanya.</li>
                      <li>Fotocopy NPWP</li>
                      <li>Fotocopy Cover Buku Tabungan</li>
                    </ol>
                  </li>
                </ol>
              </p>
            </Text>
          </Col>
        </Row>
        <Divider className="divider" />
        <Row gutter={[20,20]} justify={"center"} align={"center"} className={"download"}>
          <Col sm={24} md={12} lg={6}>
            <div className="apps">
              <div className="image">
                <img className="img-1" alt="register-2" src={Register2Image} />
                <img className="img-2" alt="register-2" src={Register2Image} />
              </div>
              <div className="link">
                <div className="title">
                  <div>
                    <i className="fab fa-windows"></i>
                    <Text className="h5">Dekstop Version</Text>
                  </div>
                  <a href="https://online.sfsekuritas.co.id/download/SFASTSetup.zip" target="_blank" rel="noreferrer">
                    <i className="fa fa-download"></i>
                  </a>
                </div>
                <a href={DesktopPDF} target="_blank" rel="noreferrer">
                  <Text className="menu-sub-title-2">Download User Guide</Text>
                </a>
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={6}>
            <div className="apps">
              <div className="image custom">
                <img className="img-1" alt="register-3" src={Register3Image} />
                <img className="img-2" alt="register-3" src={Register3Image} />
              </div>
              <div className="link">
                <div className="title">
                  <div>
                    <i className="fab fa-android"></i>
                    <Text className="h5">Android Version</Text>
                  </div>
                  <a href="https://play.google.com/store/apps/details?id=sf.android.sfonline" target="_blank" rel="noreferrer">
                    <i className="fa fa-download"></i>
                  </a>
                </div>
                <Link to="/">
                  <Text className="menu-sub-title-2">Download User Guide</Text>
                </Link>
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={6}>
            <div className="apps">
              <div className="image custom">
                <img className="img-1" alt="register-4" src={Register4Image} />
                <img className="img-2" alt="register-4" src={Register4Image} />
              </div>
              <div className="link">
                <div className="title">
                  <div>
                    <i className="fab fa-apple"></i>
                    <Text className="h5">IOS Version</Text>
                  </div>
                  <a href="https://apps.apple.com/id/app/sfast/id1581067563?l=id" target="_blank" rel="noreferrer">
                    <i className="fa fa-download"></i>
                  </a>
                </div>
                <Link to="/">
                  <Text className="menu-sub-title-2">Download User Guide</Text>
                </Link>
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={6}>
            <div className="apps">
              <div className="image">
                <img className="img-1" alt="register-1" src={Register1Image} />
                <img className="img-2" alt="register-1" src={Register1Image} />
              </div>
              <div className="link">
                <div className="title">
                  <div>
                    <i className="fa fa-globe"></i>
                    <Text className="h5">Website Version</Text>
                  </div>
                  <Link to="/">
                    <i className="fa fa-download"></i>
                  </Link>
                </div>
                <a href={WebPDF} target="_blank" rel="noreferrer">
                  <Text className="menu-sub-title-2">Download User Guide</Text>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </LayoutPage>
  );
}

export default Register;
