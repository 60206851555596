import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const { status } = err.response;
    if (status === 401) {
      localStorage.clear();
      window.location = "/";
    }
    return Promise.reject(err);
  }
);

function buildResponse(response) {
  return response;
}

const ApiWithoutAuth = {
  getData: async (url, parameter) => {
    return API.get(`${url}`, {
      params: parameter,
    })
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  postData: async (url, parameter) => {
    return API.post(`${url}`, parameter)
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
};

export default ApiWithoutAuth;
