import { Button, Card, Col, Image, Row, Space, Typography } from "antd";
import React from "react";
import Section1Image from "../../Assets/Images/LandingPage/section4-1.png";
import Section2Image from "../../Assets/Images/LandingPage/section4-2.png";
import Logo from "../../Assets/logo-sfast.png";
import AppStore from "../../Assets/Images/appstore.png";
import GooglePlay from "../../Assets/Images/googleplay.png";
import "../../Assets/Css/Section4.css";
import { Link } from "react-router-dom";

const { Text } = Typography;

const Section4 = () => {
  return (
    <div className="section4">
      <Space className="wrapper">
        <div className="text-wrapper">
          <Image src={Logo} preview={false} />
          <Space size={0} direction="vertical">
            <Text className="h1">Smart App untuk Investasi</Text>
            <Text className="h5">Solusi investasi termudah untuk pemula</Text>
          </Space>
          <a
            href={"https://registration.sfsekuritas.co.id/"}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="button-header">Buka Rekening</Button>
          </a>
          <Space size={"middle"}>
            <a
              href="https://apps.apple.com/id/app/sfast/id1581067563?l=id"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={AppStore} preview={false} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=sf.android.sfonline"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={GooglePlay} preview={false} />
            </a>
          </Space>
        </div>
      </Space>
      <div className="image-animation">
        <img alt="background" className="background" src={Section2Image} />
        <img alt="apps" className="apps" src={Section1Image} />
      </div>
    </div>
  );
};

export default Section4;
