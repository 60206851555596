import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import Profile from "../../Component/About/Profile";
import History from "../../Component/About/History";
import Team from "../../Component/About/Team";
import WhyUs from "../../Component/About/WhyUs";
import Governance from "../../Component/About/Governance";

function About() {
  let { path } = useParams();
  const [data, setData] = useState("");
  const [pahtUrl, setPathUrl] = useState("");

  const menu = [
    {
      label: "Profil Perusahaan",
      link: "/profil/profil-perusahaan",
    },
    {
      label: "Kenapa Pilih Kami",
      link: "/profil/kenapa-kami",
    },
    {
      label: "Sejarah Perusahaan",
      link: "/profil/sejarah-perusahaan",
    },
    {
      label: "Tata Kelola Perusahaan",
      link: "/profil/tata-kelola-perusahaan",
    },
    {
      label: "Tim Kami",
      link: "/profil/tim-kami",
    },
  ];

  useEffect(() => {
    setPathUrl(path);
    if (path==="profil-perusahaan") {
      setData(<Profile />);
    } else if (path==="kenapa-kami") {
      setData(<WhyUs />);
    } else if (path==="sejarah-perusahaan") {
      setData(<History />);
    } else if (path==="tata-kelola-perusahaan") {
      setData(<Governance />);
    } else if (path==="tim-kami") {
      setData(<Team />);
    } else {
      setData(<Profile />);
      setPathUrl("profil-perusahaan");
    }
  }, [path])
  
  return (
    <LayoutPage>
      <PageHeader menu={menu} title="Tentang Kami" path={`/profil/${pahtUrl}`} />
      <div className="layout-single-page">
        {data}
      </div>
    </LayoutPage>
  );
}

export default About;