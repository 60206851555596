import React, { useRef } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../Assets/Css/ClientSlider.css";
import { CLIENTS } from "./const";

const SwiperConfig = {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  slidesPerView: "2.3",
  spaceBetween: 16,
  grabCursor: true,
  modules: [Autoplay],
  allowTouchMove: true,
  centeredSlides: true,
  centeredSlidesBounds: true,
  breakpoints: {
    576: { slidesPerView: "3" },
    992: { slidesPerView: "5" },
    1200: { slidesPerView: "7" },
  },
};

const ClientSlider = () => {
  const swiperRef = useRef();
  const onInit = (Swiper) => {
    swiperRef.current = Swiper;
  };

  return (
    <div className="client-slider">
      <Swiper {...SwiperConfig} onInit={onInit}>
        {CLIENTS.map(({ name, path }) => {
          return (
            <SwiperSlide key={`image-${name}`}>
              <div className="client-image-wrapper">
                <img src={path} alt={name} preview={false} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ClientSlider;
