import React from 'react'
import styles from './index.module.css';

export default function Badge(props) {
  let color = props.color ? props.color : 'danger';

  let className = `${styles['base-badge']} ${styles[`base-badge-${color}`]}`;

  return (
    <span style={props.style} className={className}>{props.title}</span>
  )
}
