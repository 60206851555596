import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import { useParams } from "react-router-dom";
import ServiceContext from "../../Services";
import parse from "html-react-parser";
import moment from "moment";
import "moment/locale/id";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import DocViewer from "@cyntler/react-doc-viewer";
moment.locale("id");

const { Text } = Typography;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function DetailResearch() {
  const { getService } = useContext(ServiceContext);
  const [dataBerita, setDataBerita] = useState([]);
  const { id } = useParams();

  const getBerita = async () => {
    const data = await getService("risets", id, { populate: "*" });
    if (data) {
      setDataBerita(data?.data);
    }
  };

  useEffect(() => {
    getBerita();
  }, []);

  return (
    <LayoutPage>
      <div className="layout-single-page news-detail">
        <img
          alt="news"
          src={
            process.env.REACT_APP_FILE_URL +
            dataBerita?.attributes?.gambar?.data?.attributes?.url
          }
        />
        <Text className="h2">{dataBerita?.attributes?.judul}</Text>
        <Text className="body date">
          {moment(dataBerita?.attributes?.tanggal).format("dddd, DD MMMM YYYY")}
        </Text>
        {dataBerita?.attributes?.file ? (
          <DocViewer
            documents={[
              {
                uri: `${
                  process.env.REACT_APP_FILE_URL +
                  dataBerita?.attributes?.file?.data?.attributes?.url
                }`,
              },
            ]}
          ></DocViewer>
        ) : (
          parse("" + dataBerita?.attributes?.isi + "")
        )}
      </div>

      <div className="layout-single-page">
        <hr style={{ margin: 0, border: "1px solid #F1F4F7" }} />
      </div>
    </LayoutPage>
  );
}

export default DetailResearch;
