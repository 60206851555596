import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import PerdaganganEfek from "../../Component/Product/PerdaganganEfek";
import InvestmentBanking from "../../Component/Product/InvestmentBanking";
import ReksaDana from "../../Component/Product/ReksaDana";
import FixedIncome from "../../Component/Product/FixedIncome";
import SfastApp from "../../Component/Product/SfastApp";
import ResearchRecommendation from "../../Component/Product/ResearchRecommendation";
import InvestorIncubator from "../../Component/Product/InvestorIncubator";

function Product() {
  let { path } = useParams();
  const [data, setData] = useState("");
  const [pahtUrl, setPathUrl] = useState("");

  const menu = [
    {
      label: "Perdagangan Saham",
      link: "/produk-layanan/perdagangan-efek",
    },
    {
      label: "Investment Banking",
      link: "/produk-layanan/invesment-banking",
    },
    {
      label: "Reksa Dana",
      link: "/produk-layanan/reksa-dana",
    },
    {
      label: "Fixed Income",
      link: "/produk-layanan/fixed-income",
    },
    {
      label: "SFAST App",
      link: "/produk-layanan/sfast-app",
    },
    {
      label: "Riset & Rekomendasi",
      link: "/produk-layanan/research-recommendation",
    },
    {
      label: "Investor Incubator",
      link: "/produk-layanan/investor-incubator",
    },
  ];

  useEffect(() => {
    setPathUrl(path);
    if (path === "perdagangan-efek") {
      setData(<PerdaganganEfek />);
    } else if (path === "invesment-banking") {
      setData(<InvestmentBanking />);
    } else if (path === "reksa-dana") {
      setData(<ReksaDana />);
    } else if (path === "fixed-income") {
      setData(<FixedIncome />);
    } else if (path === "sfast-app") {
      setData(<SfastApp />);
    } else if (path === "research-recommendation") {
      setData(<ResearchRecommendation />);
    } else if (path === "investor-incubator") {
      setData(<InvestorIncubator />);
    } else {
      setData(<PerdaganganEfek />);
      setPathUrl("perdagangan-efek");
    }
  }, [path]);

  return (
    <LayoutPage>
      <PageHeader
        menu={menu}
        title="Produk & Layanan"
        path={`/produk-layanan/${pahtUrl}`}
      />
      <div className="layout-single-page">{data}</div>
    </LayoutPage>
  );
}

export default Product;
