import { Card, Col, Image, Row, Typography } from "antd";
import React, { useState } from "react";
import Section1Image from "../../Assets/Images/LandingPage/section3-1.png";
import Section2Image from "../../Assets/Images/LandingPage/section3-2.png";
import Section3Image from "../../Assets/Images/LandingPage/section3-3.png";
import "../../Assets/Css/Section3.css";

const { Text } = Typography;

const Section3 = () => {
  const [data, setData] = useState([
    {
      label: "Aman",
      description:
        "Menjaga dan menjamin tingkat keamanan data investor dalam berinvestasi",
      image: Section1Image,
    },
    {
      label: "Terpercaya",
      description:
        "Perusahaan yang memiliki pengalaman dalam dunia keuangan yang dipercaya oleh berbagai Institusi bisnis",
      image: Section2Image,
    },
    {
      label: "Professional",
      description:
        "Merupakan perusahaan sekuritas professional yang memiliki kapabilitas dan kualitas yang ahli serta diakui di bisnisnya",
      image: Section3Image,
    },
  ]);

  return (
    <div className="section3">
      <Row gutter={20} style={{ justifyContent: "center" }}>
        <Col span={24}>
          <Text className="h1">
            Bersama kami wujudkan tujuan keuangan masa depan
          </Text>
          <Text className="h5">
            Kami merupakan bagian dari PT Surya Fajar Capital Tbk. kumpulan
            profesional yang dapat membantu anda dalam mewujudkan rencana
            keuangan masa depan melalui investasi
          </Text>
        </Col>
        <Col span={24}>
          <Row className="item-container" gutter={20} justify={"space-between"} align={"middle"}>
            {data &&
              data.map((item, index) => (
                <Col key={index} span={24} lg={8}>
                  <Card className="product">
                    <div>
                      <Text className="h3" style={{ textAlign: "center" }}>
                        {item.label}
                      </Text>
                      <Text className="body">{item.description}</Text>
                      <Image preview={false} src={item.image} />
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Section3;
