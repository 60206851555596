import React from "react";

const FloatingButton = () => {
  return (
    <a href="https://wa.me/6281317755508" target="_blank" rel="noreferrer" className="float-button">
      <i className="fa fa-question-circle"></i> {" "}
      <span>Butuh Bantuan?</span>
    </a>
  );
};

export default FloatingButton;
