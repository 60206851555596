import { Image, Typography } from "antd";
import React, { useRef, useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../Assets/Css/FeatureSlider.css";
import "../../../Assets/Css/PaginationBullet.css";
import { FEATURES } from "./const";

const { Title, Text } = Typography;
// const pagination = {
//   clickable: true,
//   renderBullet: function (index, className) {
//     return `<span class="custom-line-bullet ${className}"></span>`;
//   },
// };

const SwiperConfig = {
  // pagination,
  // modules: [Pagination],
  speed: 1000,
  grabCursor: false,
  allowTouchMove: false,
};

const FeatureSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef();

  const onInit = (Swiper) => {
    swiperRef.current = Swiper;
  };

  const slideChangeHandler = (Swiper) => {
    setActiveIndex(Swiper?.activeIndex);
  };

  return (
    <div className="features-slider">
      <div
        className="slider-image-section"
        style={{ backgroundImage: `url(${FEATURES[activeIndex]?.background})` }}
      >
        <Image
          className="slider-image"
          src={FEATURES[activeIndex]?.image}
          alt={FEATURES[activeIndex]?.name}
          preview={false}
        />
      </div>
      <div className="slider-content-section">
        <Image
          className="slider-icon"
          src={FEATURES[activeIndex]?.icon}
          alt={FEATURES[activeIndex]?.name}
          preview={false}
        />

        <Title className="slider-title">{FEATURES[activeIndex]?.name}</Title>
        <Swiper
          {...SwiperConfig}
          onInit={onInit}
          onChange={slideChangeHandler}
          onSlideChange={slideChangeHandler}
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          {FEATURES.map(({ detail, name }) => (
            <SwiperSlide key={`slider-${name}`}>
              <Text className="slider-text">{detail}</Text>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default FeatureSlider;
