import React, { useState } from "react";
import { Button, Image, Layout, Space } from "antd";
import Logo from "../Assets/logo.png";
import HamburgerMenuIcon from "../Component/Layout/HamburgerMenuIcon";
import { Link } from "react-router-dom";
import "../Assets/Css/Layout.css";
import Menu from "../Component/Layout/Menu";
import FooterLayout from "../Component/Layout/FooterLayout";
import MenuMobile from "../Component/Layout/MenuMobile";
import FloatingButton from "../Component/Layout/FloatingButton";

const { Header, Content } = Layout;

function LayoutPage(props) {
  const [active, setActive] = useState("");
  return (
    <Layout>
      <Header className="sfs-layout">
        <Space className="sfs-header">
          <Link to="/">
            <Image src={Logo} preview={false} />
          </Link>
          <Space style={{ alignItems: "center", display: "flex" }}>
            {window.innerWidth > 768 && (
              <a href={"https://sfast.id/"} target="_blank" rel="noreferrer">
                <Button className="button-header">SFAST APP</Button>
              </a>
            )}
            <HamburgerMenuIcon active={active} setActive={setActive} />
          </Space>
        </Space>
        {window.innerWidth < 768 ? (
          <MenuMobile active={active} setActive={setActive} />
        ) : (
          <Menu active={active} setActive={setActive} />
        )}
      </Header>
      <Content className="site-layout" style={{ marginTop: 80 }}>
        <div className="site-layout-background" style={{ minHeight: "700px" }}>
          {props.children}
        </div>
      </Content>
      <FooterLayout />
      <FloatingButton />
    </Layout>
  );
}

export default LayoutPage;
