import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import LandingPage from "./Pages/LandingPage";
import News from "./Pages/News";
import DetailNews from "./Pages/News/detail";
import Product from "./Pages/Product";
import Register from "./Pages/Register";
import ResearchProspectus from "./Pages/ResearchProspectus";
import SFQuotes from "./Pages/SFQuotes";
import ServiceState from "./Services/state";
import Faq from "./Pages/Faq";
import Activities from "./Pages/Activities";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

import DetailActivities from "./Pages/Activities/detail";
import DetailResearch from "./Pages/ResearchProspectus/detail";

function App() {
  return (
    <ServiceState>
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/profil/:path" element={<About />} />
          <Route exact path="/produk-layanan/:path" element={<Product />} />
          <Route exact path="/riset/:path" element={<ResearchProspectus />} />
          <Route exact path="/risets/detail/:id" element={<DetailResearch />} />
          <Route exact path="/daftar" element={<Register />} />
          <Route exact path="/berita" element={<News />} />
          <Route exact path="/kegiatan" element={<Activities />} />
          <Route exact path="/kegiatan/detail/:id" element={<DetailActivities />} />
          <Route exact path="/berita/detail/:id" element={<DetailNews />} />
          <Route exact path="/hubungi-kami" element={<Contact />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/sfast-quotes" element={<SFQuotes />} />
          <Route exact path='/faq' element={<Faq />} />
        </Routes>
      </Router>
    </ServiceState>
  );
}

export default App;
