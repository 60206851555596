import React from "react";

import { Typography } from "antd";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

function Contact() {
  return (
    <LayoutPage>
      <PageHeader title="Privacy Policy" path={`/contact`} />
      <div className="layout-single-page contact">
        <div className="top">
          <Text className="h3">KEBIJAKAN PRIVASI</Text>
          <div
            style={{
              fontSize: "18px",

              color: " #757575",
              textAlign: "justify",
            }}
          >
            <p>
              Kebijakan Privasi ini merupakan bentuk komitmen dari SFAST dalam
              melindungi data Pengguna serta menjelaskan bagaimana Kami (PT
              Surya Fajar Sekuritas) mengumpulkan, menyimpan, menggunakan,
              mengolah, menganalisa, menampilkan, dan/atau segala bentuk
              pengelolaan yang terkait dengan data atau informasi yang Pengguna
              berikan kepada SFAST atau yang SFAST kumpulkan dari Pengguna,
              termasuk data pribadi Pengguna baik pada saat Pengguna melakukan
              pendaftaran, mengakses, maupun menggunakan SFAST dan Layanan
              SFAST.
            </p>
            <p>
              Mohon untuk membaca Kebijakan Privasi ini dengan seksama untuk
              memastikan bahwa Anda memahami bagaimana proses pengolahan data
              Kami. Dengan mengakses dan/atau menggunakan Layanan SFAST,
              Pengguna menyatakan bahwa setiap data Pengguna merupakan data yang
              benar dan sah , serta Pengguna memberikan persetujuan kepada SFAST
              untuk memperoleh, mengumpulkan, menyimpan, mengelola dan
              mempergunakan data tersebut sebagaimana tercantum dalam Kebijakan
              Privasi maupun Syarat dan Ketentuan Pengguna SFAST.
            </p>
            <ol type="A">
              <li>
                <div>PENGUMPULAN DATA PENGGUNA</div>
                <ol>
                  <li>
                    SFAST mengumpulkan data Pengguna dengan tujuan untuk
                    memproses transaksi Pengguna, mengelola dan memperlancar
                    proses penggunaan Layanan SFAST serta tujuan-tujuan lainnya
                    selama diizinkan oleh peraturan perundang-undangan yang
                    berlaku.
                  </li>
                  <li>
                    Data atau informasi yang Kami kumpulkan dari Pengguna secara
                    langsung, adalah sebagai berikut :
                  </li>
                  <ol type="I">
                    <li>
                      Membuat Akun SFAST, termasuk diantaranya nama Pengguna
                      (username), alamat email, nomor ponsel (handphone), PIN;
                    </li>
                    <li>
                      Upgrade sebagai pengguna registered (Premium), termasuk
                      diantaranya nama lengkap Pengguna, nomor kartu identitas,
                      Foto KTP, swafoto KTP, alamat, tempat dan tanggal lahir,
                      pekerjaan, dan lain sebagainya;
                    </li>
                    <li>
                      Menggunakan fitur yang membutuhkan izin akses terhadap
                      perangkat Pengguna;
                    </li>
                    <li>
                      Menggunakan Layanan SFAST, termasuk data transaksi yang
                      detil, diantaranya jenis transaksi, jumlah pembayaran,
                      tanggal dan waktu transaksi, serta detil transaksi
                      lainnya;
                    </li>
                    <li>
                      Menghubungi SFAST, termasuk melalui Layanan Customer
                      Service;
                    </li>
                    <li>Mengisi survei yang dikirimkan oleh SFAST;</li>
                  </ol>
                  <li>
                    Data atau informasi yang Kami kumpulkan setiap kali Pengguna
                    menggunakan Aplikasi atau mengunjungi Website Kami, adalah
                    sebagai berikut:
                  </li>
                  <ol type="I">
                    <li>
                      Data lokasi riil atau perkiraannya seperti alamat IP,
                      lokasi Wi-Fi, geo-location, dan sebagainya;
                    </li>
                    <li>
                      Data kontak pengguna untuk mendukung produk dan layanan
                      SFAST, seperti memudahkan pengguna untuk memilih nomor
                      kontak untuk melakukan transaksi, dan untuk data kontak
                      pengguna tidak akan disimpan ke dalam server SFAST;
                    </li>
                    <li>
                      Data berupa waktu dari setiap aktivitas Pengguna, termasuk
                      aktivitas pendaftaran, login, bertransaksi dan lain
                      sebagainya;
                    </li>
                    <li>
                      Data perangkat, diantaranya jenis perangkat yang digunakan
                      untuk mengakses Aplikasi atau Website, termasuk model
                      perangkat keras, sistem operasi dan versinya, perangkat
                      lunak, nama file dan versinya, pilihan bahasa, pengenal
                      perangkat unik, pengenal iklan, nomor seri, informasi
                      gerakan perangkat, dan/atau informasi jaringan seluler;
                    </li>
                    <li>
                      Data catatan (log), diantaranya catatan pada server yang
                      menerima data seperti alamat IP perangkat, tanggal dan
                      waktu akses, fitur aplikasi atau laman yang dilihat,
                      proses kerja aplikasi dan aktivitas sistem lainnya, jenis
                      peramban, dan/atau situs atau layanan pihak ketiga yang
                      Pengguna gunakan sebelum berinteraksi dengan SFAST;
                    </li>
                  </ol>
                  <li>
                    Data atau informasi yang Kami peroleh dari sumber lain,
                    termasuk:
                  </li>
                  <ol type="I">
                    <li>
                      Rekan Usaha SFAST yang turut membantu SFAST dalam
                      mengembangkan dan menyajikan layanan-layanan dalam
                      Aplikasi SFAST kepada Pengguna;
                    </li>
                    <li>
                      Rekan Usaha SFAST tempat Pengguna membuat atau mengakses
                      Akun SFAST, seperti layanan media sosial, atau
                      situs/aplikasi yang menggunakan API SFAST atau yang
                      digunakan SFAST;
                    </li>
                    <li>Sumber yang tersedia secara umum;</li>
                  </ol>
                  <li>
                    SFAST dapat menggabungkan data yang diperoleh dari sumber
                    tersebut dengan data lain yang dimilikinya.
                  </li>
                </ol>
              </li>
              <li>
                <div>PENGGUNAAN DATA PENGUNA</div>
                <ol>
                  <li>
                    SFAST dapat menggunakan keseluruhan atau sebagian data yang
                    diperoleh dan dikumpulkan dari Pengguna sebagaimana
                    disebutkan dalam bagian “A” mengenai “Pengumpulan Data
                    Pengguna” untuk hal-hal sebagai berikut:
                  </li>
                  <ol type="I">
                    <li>
                      Mengidentifikasi dan mendaftarkan Pengguna, dan untuk
                      mengadministrasikan, menverikasi, menonaktifkan, atau
                      mengelola Akun Pengguna;
                    </li>
                    <li>
                      Memfasilitasi atau memungkinkan verifikasi apapun yang
                      menurut pertimbangan Kami diperlukan sebelum memberikan
                      layanan kepada Pengguna atau sebelum Kami mendaftarkan
                      Pengguna, termasuk proses Mengenal Pelanggan (Know Your
                      Customer) dimana data Pengguna yang verifikasi akun ke
                      Premium akan dikirim ke penyedia layanan yang telah Kami
                      tunjuk sebagai penyedia verfikasi data;
                    </li>
                    <li>
                      Penyediaan fitur-fitur untuk memberikan, mewujudkan,
                      memelihara dan memperbaiki produk dan Layanan SFAST,
                      termasuk melakukan kegiatan internal yang diperlukan untuk
                      menyediakan Layanan pada Aplikasi SFAST seperti pemecahan
                      masalah software, bug, permasalahan operasional,
                      meningkatkan keamanan, melakukan analisa data, pengujian
                      dan penelitian, dan atau memantau dan menganalisis
                      kecenderungan penggunaan dan aktivitas;
                    </li>
                    <li>
                      Memberitahu Pengguna mengenai segala pembaruan pada
                      Aplikasi atau perubahan pada layanan yang disediakan;
                    </li>
                    <li>
                      Membantu Pengguna pada saat berkomunikasi dengan Layanan
                      Customer Service SFAST untuk memeriksa dan mengatasi
                      permasalahan Pengguna, serta mengawasi dan memperbaiki
                      tanggapan Layanan Customer Service;
                    </li>
                    <li>
                      Melakukan monitoring ataupun investigasi terhadap
                      transaksi-transaksi mencurigakan atau transaksi yang
                      terindikasi mengandung unsur kecurangan, ilegal, atau
                      pelanggaran terhadap Syarat dan Ketentuan atau ketentuan
                      hukum yang berlaku termasuk namun tidak terbatas pada
                      penipuan (fraud), penggelapan, pencurian, money
                      laundering, serta melakukan tindakan-tindakan yang
                      diperlukan sebagai tindak lanjut dari hasil monitoring
                      atau investigasi transaksi tersebut;
                    </li>
                    <li>
                      Dalam keadaan tertentu, SFAST mungkin perlu untuk
                      menggunakan ataupun mengungkapkan data Pengguna untuk
                      tujuan penegakan hukum atau untuk pemenuhan persyaratan
                      hukum dan peraturan yang berlaku, termasuk dalam hal
                      terjadinya sengketa atau proses hukum antara Pengguna dan
                      SFAST.
                    </li>
                  </ol>
                </ol>
                <li>
                  <div>PENGUNGKAPAN DATA PENGUNA</div>
                  <ol>
                    <li>
                      SFAST menjamin tidak ada penjualan, pengalihan, distribusi
                      atau meminjamkan Data Pengguna kepada pihak ketiga lain,
                      tanpa terdapat izin dari Pengguna, kecuali dalam hal-hal
                      sebagai berikut:
                    </li>
                    <ol type="I">
                      <li>
                        Dibutuhkan adanya pengungkapan data Pengguna kepada
                        mitra atau pihak ketiga lain yang membantu SFAST dalam
                        menyajikan layanan pada Aplikasi dan memproses segala
                        bentuk aktivitas Pengguna dalam Aplikasi, termasuk untuk
                        mencegah penipuan, memproses transaksi, verifikasi
                        pembayaran, promosi dan lain sebagainya;
                      </li>
                      <li>
                        Pengguna menghubungi SFAST melalui media publik seperti
                        blog, media sosial, dan fitur tertentu pada Aplikasi
                        sehingga komunikasi antara Pengguna dan SFAST mungkin
                        dapat dilihat secara publik;
                      </li>
                      <li>
                        SFAST mengungkapkan data Pengguna dalam upaya mematuhi
                        kewajiban hukum dan/atau adanya permintaan yang sah dari
                        aparat penegak hukum;
                      </li>
                      <li>
                        SFAST dapat membagikan informasi Pengguna kepada grup
                        usaha dan afiliasinya untuk membantu memberikan layanan
                        atau melakukan pengolahan data untuk dan atas nama
                        SFAST;
                      </li>
                      <li>
                        SFAST dapat membagikan data Pengguna dengan perusahaan
                        lain sehubungan dengan, penggabungan, penjualan aset
                        perusahaan, konsolidasi atau restrukturisasi, pembiayaan
                        atau akuisisi semua atau sebagian dari bisnis Kami oleh
                        atau ke perusahaan lain.
                      </li>
                    </ol>
                  </ol>
                  <li>
                    <div>TEMPAT PENYIMPANAN DATA PENGGUNA</div>
                    <ol>
                      <li>
                        Data Pengguna yang Kami kumpulkan dapat disimpan,
                        ditransfer, atau diolah oleh penyedia layanan pihak
                        ketiga. Kami akan menggunakan semua upaya yang wajar
                        untuk memastikan bahwa semua penyedia layanan pihak
                        ketiga tersebut memberikan tingkat perlindungan yang
                        sebanding dengan komitmen Kami berdasarkan Kebijakan
                        Privasi ini.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div>PENYIMPANAN DAN PENGHAPUSAN DATA PENGGUNA</div>
                    <ol>
                      <li>
                        SFAST akan menyimpan data Pengguna selama Akun Pengguna
                        tetap aktif dan dapat melakukan penghapusan sesuai
                        dengan ketentuan peraturan hukum yang berlaku.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div>KEAMANAN DATA PENGGUNA</div>
                    <ol>
                      <li>
                        Kerahasiaan Data Pengguna adalah hal yang terpenting
                        bagi Kami. Kami akan memberlakukan upaya terbaik untuk
                        melindungi dan mengamankan data Pengguna dari akses,
                        pengumpulan, penggunaan atau pengungkapan oleh
                        orang-orang yang tidak berwenang dan dari pengolahan
                        yang bertentangan dengan hukum, kehilangan yang tidak
                        disengaja, pemusnahan dan kerusakan atau risiko serupa.
                        Namun, pengiriman data melalui internet tidak sepenuhnya
                        aman. Walau Kami berusaha sebaik mungkin untuk
                        melindungi data Pengguna, anda sebagai Pengguna mengakui
                        bahwa Kami tidak dapat menjamin keutuhan dan keakuratan
                        data Pengguna apa pun yang Pengguna kirimkan melalui
                        Internet, atau menjamin bahwa data tersebut tidak akan
                        dicegat, diakses, diungkapkan, diubah atau dihancurkan
                        oleh pihak ketiga yang tidak berwenang, karena
                        faktor-faktor di luar kendali Kami. Anda sebagai
                        Pengguna bertanggung jawab untuk menjaga kerahasiaan
                        detail Akun Anda, termasuk kata sandi (PIN) dan One Time
                        Password (OTP) dengan siapapun dan harus selalu menjaga
                        dan bertanggung jawab atas keamanan perangkat yang Anda
                        gunakan.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div>PEMBARUAN KEBIJAKAN PRIVASI</div>
                    <ol>
                      <li>
                        SFAST dapat sewaktu-waktu melakukan perubahan atau
                        pembaruan terhadap Kebijakan Privasi ini. SFAST
                        menyarankan agar Pengguna membaca secara seksama dan
                        memeriksa halaman Kebijakan Privasi ini dari waktu ke
                        waktu untuk mengetahui perubahan apapun. Dengan tetap
                        mengakses dan menggunakan Layanan SFAST, maka Pengguna
                        dianggap menyetujui perubahan-perubahan dalam Kebijakan
                        Privasi.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <div>HUBUNGI KAMI</div>
                    <ol>
                      <li>
                        SFAST dapat sewaktu-waktu melakukan perubahan atau
                        pembaruan terhadap Kebijakan Privasi ini. SFAST
                        menyarankan agar Pengguna membaca secara seksama dan
                        memeriksa halaman Kebijakan Privasi ini dari waktu ke
                        waktu untuk mengetahui perubahan apapun. Dengan tetap
                        mengakses dan menggunakan Layanan SFAST, maka Pengguna
                        dianggap menyetujui perubahan-perubahan dalam Kebijakan
                        Privasi.
                      </li>
                      <ol type="I">
                        <li>
                          Telp. 021-2788-3989 (Senin – Jumat, 08.00 – 17.00 WIB)
                        </li>
                        <li>
                          Email cs@sfsekuritas.co.id (Senin – Jumat, 08.00 –
                          17.00 WIB)
                        </li>
                        <li>
                          Whatsapp Chat 0813-1775-5509 (Senin – Jumat, 08.00 –
                          17.00 WIB)
                        </li>
                      </ol>
                    </ol>
                  </li>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </LayoutPage>
  );
}

export default Contact;
