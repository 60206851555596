import { Col, Row, Table, Typography } from "antd";
import moment from "moment";
moment.locale("id");

const { Text } = Typography;

function ProspectusCard({ dataSource, tableParams, loading, setTableParams }) {
  const columns = [
    {
      title: "Nama Dokumen",
      dataIndex: "attributes",
      key: "namaDokumen",
      render: (data) => data?.namaDokumen,
    },
    {
      title: "Tanggal",
      dataIndex: "attributes",
      key: "tanggal",
      render: (data) => moment(data?.tanggal).format("dddd, DD MMMM YYYY"),
    },
    {
      title: "Unduh",
      dataIndex: "attributes",
      key: "unduh",
      render: (data) => (
        <a
          href={
            process.env.REACT_APP_FILE_URL + data?.file?.data?.attributes?.url
          }
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-download"></i> {data?.file?.data?.attributes?.ext}
        </a>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <Table
      className="table-prospectus"
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={dataSource}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
  );
}

export default ProspectusCard;
