import { Card, Col, Row, Typography } from "antd";
import React from "react";
import Section1Image from "../../Assets/Images/LandingPage/section6-1.png";
import Section2Image from "../../Assets/Images/LandingPage/section6-2.png";
import Section3Image from "../../Assets/Images/LandingPage/section6-3.png";
import Section4Image from "../../Assets/Images/LandingPage/section6-4.png";
import "../../Assets/Css/Section6.css";
import { Link, Navigate, useNavigate } from "react-router-dom";

const { Text } = Typography;

const Section6 = () => {
  const navigate = useNavigate();

  return (
    <div className="section6">
      <Card className="product">
        <Row gutter={20}>
          <Col span={24} lg={12}>
            <div className="left">
              <Text className="h1">Riset & Rekomendasi</Text>
              <Text className="h5">
                Dapatkan update pasar terkini dan rekomendasi saham dari tim
                riset kami
              </Text>
              <div>
                <Link to="/riset/riset?type=riset-harian">
                  <Text className="menu-sub-title-2">Lebih Lanjut</Text>
                </Link>
              </div>
            </div>
          </Col>
          <Col span={24} lg={12}>
            <div className="image-animation">
              <img
                alt="riset-harian"
                className="riset-harian"
                src={Section1Image}
                onClick={() => navigate("/riset/riset?type=riset-harian")}
              />
              <img
                alt="riset-perusahaan"
                className="riset-perusahaan"
                src={Section2Image}
                onClick={() => navigate("/riset/riset?type=riset-perusahaan")}
              />
              <img
                alt="prospektus"
                className="prospektus"
                src={Section3Image}
                onClick={() => navigate("/riset/prospektus?type=prospektus-umum")}
              />
              <img
                alt="ulasan-pasar"
                className="ulasan-pasar"
                src={Section4Image}
                onClick={() => navigate("/riset/riset?type=ulasan-pasar")}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Section6;
