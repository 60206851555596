import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Product4Image from "../../Assets/Images/Product/product-4.png";
import ProductMobile4Image from "../../Assets/Images/Product/product-mobile-4.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const FixedIncome = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProductMobile4Image : Product4Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">Obligasi</Text>
        <Text className="body">
          <p>
            Obligasi adalah salah satu instrumen investasi di pasar modal berupa
            surat utang yang diterbitkan oleh Negara dan Perseroan.
          </p>
          <p style={{ fontWeight: "bold" }}>
            PT Surya Fajar Sekuritas aktif melakukan transaksi sejak Januari
            2022 yang terdiri dari:
          </p>
          <p>
            <ul>
              <li>Surat Berharga Negara (SBN)</li>
              <li>Sukuk Negara (SBNS)</li>
              <li>Obligasi Korporasi</li>
            </ul>
          </p>
          <p>
            Kedepannya, PT Surya Fajar Sekuritas akan melakukan penawaran
            obligasi pada pasar perdana sebagai Penjamin Emisi Utama (Lead
            Underwriter) dan berpartisipasi sebagai Sindikasi (CO-Underwriter).
          </p>
          <p>
            Kami memiliki{" "}
            <b>izin Fixed Income dari BEI : No. STDP-172/BEI.ANG/07-2021</b>
          </p>
          <p>
            Untuk informasi dan pertanyaan lebih lanjut dapat menghubungi kami
            melalui email{" "}
            <a href="mailto:fixed.income@sfsekuritas.co.id">
              <b>fixed.income@sfsekuritas.co.id</b>
            </a>
          </p>
        </Text>
      </Col>
    </Row>
  );
};

export default FixedIncome;
