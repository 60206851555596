import { Button, Image, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../Assets/Css/PaginationBullet.css";
import "../../../Assets/Css/ReksaDanaSlider.css";
import { REKSADANA_PRODUCTS } from "./const";

const { Title, Text } = Typography;

const pagination = {
  enabled: true,
  clickable: true,
  renderBullet: function (index, className) {
    return `<span class="custom-line-bullet ${className}"></span>`;
  },
};

const SwiperConfig = {
  className: "swiper-reksadana-slider",
  grabCursor: true,
  allowTouchMove: true,
  spaceBetween: 32,
  slidesPerView: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  navigation: {
    enabled: false,
  },
  modules: [Pagination, Autoplay, Navigation],
  pagination,
  breakpoints: {
    992: {
      navigation: {
        enabled: true,
      },
      pagination: {
        enabled: false,
      },
    },
  },
};

const ReksaDanaCard = ({ image, title, icon, text }) => {
  return (
    <div className="reksadana-card">
      <div className="rd-section image-section">
        <div className="img-wrapper">
          <Image src={image} alt={`img-of-${title}`} preview={false} />
        </div>
      </div>
      <div className="rd-section detail-section">
        <div className="title-wrapper">
          <span className="icon">
            <Image src={icon} alt={title} preview={false} />
          </span>
          <Title className="title">{title}</Title>
        </div>
        <Text className="text">{text}</Text>
      </div>
    </div>
  );
};

const ReksadanaSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperRef = useRef();
  const buttonContainerRef = useRef();

  const initSwiper = (Swiper) => {
    swiperRef.current = Swiper;
  };

  const slideChangeHandler = (Swiper) => {
    const { activeIndex } = Swiper;
    setActiveSlide(activeIndex);
  };

  const buttonClickHandler = (targetIndex) => {
    if (!swiperRef.current) return false;
    swiperRef.current.slideTo(targetIndex);
  };

  const nextBtnClickHandler = () => {
    if (!swiperRef.current) return false;

    swiperRef.current.slideNext();
  };

  const prevBtnClickHandler = () => {
    if (!swiperRef.current) return false;

    swiperRef.current.slidePrev();
  };

  useEffect(() => {
    if (buttonContainerRef.current) {
      const target = buttonContainerRef.current.children[activeSlide];
      const { offsetLeft } = target;

      buttonContainerRef.current.scrollLeft = offsetLeft;
    }
  }, [activeSlide, buttonContainerRef]);

  return (
    <div className="rd-container">
      <div className="slider-button-container" ref={buttonContainerRef}>
        {REKSADANA_PRODUCTS.map((item, index) => {
          const additionalClass =
            activeSlide === index ? "slider-button--active" : "";

          return (
            <Button
              key={`key-button-${index + 0}`}
              className={`slider-button ${additionalClass}`}
              onClick={() => buttonClickHandler(index)}
            >
              {item.name}
            </Button>
          );
        })}
      </div>
      <div className="reksadana-slider">
        <Swiper
          onSlideChange={slideChangeHandler}
          onInit={initSwiper}
          onSwiper={initSwiper}
          {...SwiperConfig}
        >
          {REKSADANA_PRODUCTS.map(({ image, icon, name, detail }) => (
            <SwiperSlide key={`slider-${name}`}>
              <ReksaDanaCard
                title={name}
                icon={icon}
                text={detail}
                image={image}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Button
          className="custom-navigation-button custom-navigation-button--prev"
          onClick={prevBtnClickHandler}
        >
          <span />
        </Button>
        <Button
          className="custom-navigation-button custom-navigation-button--next"
          onClick={nextBtnClickHandler}
        >
          <span />
        </Button>
      </div>
    </div>
  );
};

export default ReksadanaSlider;
