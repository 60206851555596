import React from "react";
import {
  Button,
  Col,
  Image,
  Layout,
  Row,
  Space,
  Typography,
  Divider,
} from "antd";
import "../../Assets/Css/FooterLayout.css";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import SocialMedia from "../Global/SocialMedia";
import FooterImage1 from "../../Assets/Images/Footer/footer-logo-1.png";
import FooterImage2 from "../../Assets/Images/Footer/footer-logo-2.png";
import FooterImage3 from "../../Assets/Images/Footer/footer-logo-3.png";
import FooterImage4 from "../../Assets/Images/Footer/footer-logo-4.png";
import FooterImage5 from "../../Assets/Images/Footer/footer-logo-5.png";
import FooterImage6 from "../../Assets/Images/Footer/footer-logo-6.png";
import FooterImage7 from "../../Assets/Images/Footer/footer-logo-7.png";
import Contact3Image from "../../Assets/Images/Contact/contact-3.png";
import Contact4Image from "../../Assets/Images/Contact/contact-4.png";
import MenuPDF from "../../Assets/Pdf/Userguide-Menu.pdf";

const { Text } = Typography;
const { Footer } = Layout;

const FooterLayout = (props) => {
  return (
    <Footer>
      <Row
        gutter={[
          { md: 0, lg: 50 },
          { xs: 10, md: 0, lg: 0 },
        ]}
        align="top"
        className="footer-container"
      >
        <Col order={1} span={24} lg={{ span: 6, order: 1 }}>
          <Link to="/">
            <Image src={Logo} preview={false} />
          </Link>
          <Text className="h5">PT. Surya Fajar Sekuritas</Text>
          <Text className="body">
            Satrio Tower Building Lt. 9 Unit B, Jalan Prof. Dr. Satrio Blok
            C4/5, Kuningan, DKI Jakarta 12950, Indonesia
          </Text>
          <Row
            gutter={[
              { md: 0, lg: 50 },
              { md: 0, lg: 20 },
            ]}
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
          >
            <Col order={1} span={12} lg={{ span: 24, order: 1 }}>
              <Space>
                <Image preview={false} src={Contact3Image} width={"20px"} />
                <a href="mailto:cs@sfsekuritas.co.id">
                  <Text className="body">cs@sfsekuritas.co.id</Text>
                </a>
              </Space>
            </Col>
            <Col order={2} span={12} lg={{ span: 24, order: 2 }}>
              <Space>
                <Image preview={false} src={Contact4Image} width={"20px"} />
                <a href="tel:02127883989">
                  <Text className="body">021-2788-3989</Text>
                </a>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col
          order={2}
          className="footer-menu"
          span={12}
          lg={{ span: 6, order: 2 }}
        >
          <div>
            <Link to="/">
              <b>Sekilas SF Sekuritas</b>
            </Link>
          </div>
          <div>
            <Link to="/profil/profil-perusahaan">Profile Perusahaan</Link>
          </div>
          <div>
            <Link to="/profil/kenapa-kami">Kenapa Pilih Kami</Link>
          </div>
          <div>
            <Link to="/profil/sejarah-perusahaan">Sejarah Perusahaan</Link>
          </div>
          <div>
            <Link to="/profil/tata-kelola-perusahaan">
              Tata Kelola Perusahaan
            </Link>
          </div>
          <div>
            <Link to="/profil/tim-kami">Tim Kami</Link>
          </div>
          <div>
            <Link to="/hubungi-kami">Hubungi Kami</Link>
          </div>
          <div>
            <Link to="/faq">FAQ</Link>
          </div>
          <div>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </Col>
        <Col
          order={2}
          className="footer-menu"
          span={12}
          lg={{ span: 6, order: 3 }}
        >
          <div>
            <Link to="/produk-layanan/perdagangan-efek">
              <b>Produk & Layanan</b>
            </Link>
          </div>
          <div>
            <Link to="/produk-layanan/perdagangan-efek">Perdagangan Efek</Link>
          </div>
          <div>
            <Link to="/produk-layanan/invesment-banking">
              Investment Banking
            </Link>
          </div>
          <div>
            <Link to="/produk-layanan/reksa-dana">Reksa Dana (APERD)</Link>
          </div>
          <div>
            <Link to="/produk-layanan/fixed-income">Fixed Income</Link>
          </div>
          <div>
            <Link to="/produk-layanan/sfast-app">SFAST App</Link>
          </div>
          <div>
            <Link to="/produk-layanan/research-recommendation">
              Capital Riset
            </Link>
          </div>
          <div>
            <Link to="/produk-layanan/investor-incubator">
              Investor Incubator
            </Link>
          </div>
          <div>
            <a
              href="https://lms.sfsekuritas.co.id/"
              target="_blank"
              rel="noreferrer"
            >
              SF LMS
            </a>
          </div>
        </Col>
        <Col
          order={2}
          className="footer-menu"
          span={24}
          lg={{ span: 6, order: 4 }}
        >
          <Row
            gutter={[
              { md: 0, lg: 0 },
              { xs: 10, md: 0, lg: 10 },
            ]}
            align="top"
          >
            <Col order={1} span={12} lg={{ span: 24, order: 1 }}>
              <div>
                <Link to="/riset/riset?type=riset-harian">
                  <b>Riset & Rekomendasi</b>
                </Link>
              </div>
              <div>
                <Link to="/riset/riset?type=riset-harian">Riset</Link>
              </div>
              <div>
                <Link to="/riset/prospektus?type=prospektus-umum">
                  Prospektus
                </Link>
              </div>
            </Col>
            <Col order={2} span={12} lg={{ span: 24, order: 2 }}>
              <div>
                <Link to="/berita">
                  <b>Berita & Pengumuman</b>
                </Link>
              </div>
              <div>
                <Link to="/berita">Berita</Link>
              </div>
              <div>
                <Link to="/kegiatan">Kegiatan</Link>
              </div>
            </Col>
            <Col order={3} span={12} lg={{ span: 24, order: 3 }}>
              <div>
                <a
                  href={MenuPDF}
                  onClick={() => props.setActive("")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Kenali SFAST App </b>
                </a>
              </div>
              <div>
                <a
                  href={MenuPDF}
                  onClick={() => props.setActive("")}
                  target="_blank"
                  rel="noreferrer"
                >
                  User Guide SFAST Mobile App
                </a>
              </div>
              <div>
                <Link to="/">SFAST Desktop App</Link>
              </div>
            </Col>
          </Row>
        </Col>
        {/* <Col order={4} span={24} lg={{ span: 12, order: 2 }}> */}
        {/* <SocialMedia align={"right"} /> */}
        {/* </Col> */}
        <Col order={5} span={24} lg={{ span: 24, order: 5 }}>
          <div
            className="footer-partner-logo"
            style={{ marginTop: "3rem", marginBottom: "3rem" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ojk.go.id/id/Default.aspx"
            >
              <Image src={FooterImage1} preview={false} width={108} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.idx.co.id/">
              <Image src={FooterImage2} preview={false} width={108} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.ksei.co.id/">
              <Image src={FooterImage3} preview={false} width={108} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.ksei.co.id/">
              <Image src={FooterImage4} preview={false} width={108} />
            </a>
            {/* <a target="_blank" rel="noreferrer" href="https://www.ksei.co.id/"> */}
            <Image src={FooterImage5} preview={false} width={108} />
            {/* </a> */}
            {/* <a
              target="_blank"
              rel="noreferrer"
              href="https://www.idclear.co.id/id"
            > */}
            <Image src={FooterImage6} preview={false} width={108} />
            {/* </a> */}
            {/* <a target="_blank" rel="noreferrer" href="https://www.ksei.co.id/"> */}
            <Image src={FooterImage7} preview={false} width={108} />
            {/* </a> */}
          </div>
          <div className="layout-single-page line">
            <hr style={{ margin: 0, border: "1px solid #000" }} />
          </div>
        </Col>

        <Col
          order={6}
          span={24}
          lg={{ span: 12, order: 6 }}
          style={{ justifySelf: "flex" }}
        >
          <SocialMedia align={"right"} />
        </Col>
        <Col
          order={6}
          span={24}
          lg={{ span: 12, order: 5 }}
          style={{ justifySelf: "flex" }}
        >
          <Text className="body">
            Copyright © 2024 PT. Surya Fajar Sekuritas All rights reserved.
          </Text>
        </Col>
      </Row>
    </Footer>
  );
};

export default FooterLayout;
