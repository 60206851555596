import React, { useState } from 'react'
import LayoutPage from '../../Layout'
import PageHeader from '../../Component/Global/PageHeader'
import { Card, Col, Image, Row, Typography, Radio, Button, Collapse } from 'antd'
import CategoryOneImage from "../../Assets/Images/Faq/Category 1.png"
import CategoryTwoImage from "../../Assets/Images/Faq/Category 2.png"
import IconFolder from "../../Assets/Icons/folder.svg";
import IconFolderOpen from "../../Assets/Icons/folder_open.svg";
import IconEmail from "../../Assets/Icons/mail.svg";
import IconPhone from "../../Assets/Icons/call.svg";
import IconMessage from "../../Assets/Icons/chat.svg";
import IconPlus from "../../Assets/Icons/plus.svg";
import IconMinus from "../../Assets/Icons/dash.svg";

import "../../Assets/Css/SinglePage.css";
import '../../Assets/Css/Faq.css';

const { Text } = Typography;
const { Panel } = Collapse;

function Faq() {
  const [category, setCategory] = useState(0);
  const [subCategory, setSubCategory] = useState(0);
  
  const headerMenu = [
    {
      link: "/profil/profil-perusahaan",
      label: 'Profil Perusahaan'
    },
    {
      link: "/profil/kenapa-kami",
      label: 'Kenapa Pilih Kami'
    },
    {
      link: "/profil/sejarah-perusahaan",
      label: 'Sejarah Perusahaan'
    },
    {
      link: "/profil/tata-kelola-perusahaan",
      label: 'Tata Kelola Perusahaan'
    },
    {
      link: "/profil/tim-kami",
      label: 'Tim Kami'
    },
    {
      link: '/faq',
      label: 'FAQ',
    }
  ];

  const categoryContent = [
    [
      {
        id: 'Akun',
        content: [
          {
            key: '1',
            label: 'Apakah saya bisa memindahkan saham dari sekuritas lain ke SFAST?',
            children: <p>Tentu saja bisa, kamu dapat melakukan perpindahan dengan cara mengajukan ke pihak sekuritas asal dan mengisi formulir kuasa perpindahan. Sekuritas asal akan mengenakan biaya sesuai kebijakan masing-masing.</p>,
          },
          {
            key: '2',
            label: 'Bagaimana jika akun saya dinonaktifkan?',
            children: <p>Tentu saja bisa, kamu dapat melakukan perpindahan dengan cara mengajukan ke pihak sekuritas asal dan mengisi formulir kuasa perpindahan. Sekuritas asal akan mengenakan biaya sesuai kebijakan masing-masing.</p>,
          },
          {
            key: '3',
            label: 'Bagaimana jika saya lupa PIN?',
            children: <p>Hubungi customer service SFAST segera untuk proses pembaruan PIN. Harap diingat bahwa kamu tidak bisa bertransaksi saham tanpa PIN.</p>
          },
          {
            key: '4',
            label: 'Kenapa pembukaan akun saham saya gagal?',
            children: (
              <>
                <p>Beberapa penyebab gagalnya pembukaan akun saham:</p>
                <p style={{ fontWeight: 700, marginBottom: 0 }}>Dormant</p>
                <p>Kondisi ini dikarenakan Anda pernah atau masih menjadi nasabah Permata Bank dan status rekening Anda saat ini tidak aktif atau pasif. Untuk dapat melanjutkan proses pembukaan RDN nasabah dapat langsung ke Bank Permata untuk menghapus status Dormant.</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>KTP Buram</p>
                <p>Kondisi ini dikarenakan fisik KTP anda tidak terbaca akibat rusak atau mengalami pemudaran, dan dapat diperbaharui terlebih dahulu ke Kantor Kelurahan Anda.</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>Data DUKCAPIL Berbeda</p>
                <p>Kondisi ini dikarenakan data fisik KTP Anda berbeda dengan Pusat Data DUKCAPIL dan perlu penyesuaian langsung ke pihak DUKCAPIL.</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>NIK tidak terbaca</p>
                <p>Kondisi ini dikarenakan NIK yang tercantum pada fisik KTP anda tidak terdaftar di Pusat Data DUKCAPIL dan perlu penyesuaian langsung ke pihak DUKCAPIL.</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>ETB status</p>
                <p>Kondisi ini dikarenakan Anda pernah memiliki rekening atau produk Bank Permata, atau pernah melakukan registrasi yang terkait dengan Bank Permata namun data tidak lengkap sehingga saat proses pembukaan rekening RDN secara online pada aplikasi terjadi kegagalan dan pembukaan RDN harus dilakukan secara manual.</p>
              </>
            )
          }
        ]
      },
      {
        id: 'Biaya',
        content: [
          {
            key: '1',
            label: 'Adakah biaya jika berinvestasi saham di SFAST?',
            children: (
              <>
                <p>Ada. Setiap transaksi beli dan jual mengandung biaya yang dikenakan ke investor. Biaya ini terdiri dari empat komponen, yaitu:</p>
                <p style={{ fontWeight: 700, marginBottom: 0 }}>Biaya broker</p>
                <p>Komisi untuk SFAST sebagai perantara yang menyampaikan order transaksi jual-beli saham ke dalam sistem perdagangan elektronik BEI.</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>Biaya transaksi (levy)</p>
                <p>Biaya jasa setiap kali investor menggunakan fasilitas Bursa Efek Indonesia untuk jual-beli saham sebesar 0,0433%.</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>Pajak Pertambahan Nilai (PPN)</p>
                <p>Pajak untuk setiap transaksi barang atau jasa. Dalam transaksi saham, jumlahnya 11% dari total biaya broker dan biaya transaksi (levy).</p>

                <p style={{ fontWeight: 700, marginBottom: 0 }}>Pajak Penghasilan (PPh)</p>
                <p>Pajak yang hanya dikenakan untuk transaksi jual saham, yaitu PPh Pasal 4 Ayat 2 yang bersifat final. Besarannya 0,1% dari nilai bruto (kotor) transaksi.</p>

                <p><span style={{ fontWeight: 700, marginBottom: 0 }}>Di SFAST</span>, semua biaya ini telah digabung menjadi total biaya tambahan di atas harga saham yang ingin kamu transaksikan. Makin besar nilai transaksimu, makin kecil biayanya. Adapun perinciannya adalah sebagai berikut:</p>
                <ul style={{ paddingLeft: 25 }}>
                  <li>Untuk nilai transaksi &lt; Rp150 juta, biaya untuk beli adalah 0,1513% dari nilai transaksi, biaya jual 0,2513%.</li>
                  <li>Untuk nilai transaksi Rp150 juta sampai Rp1,5 miliar, biaya beli 0,1412%, biaya jual 0,2412%.</li>
                  <li>Untuk nilai transaksi &gt; Rp1,5 miliar, biaya beli 0,1311%, biaya jual 0,2311%.</li>
                </ul>
              </>
            )
          },
          {
            key: '2',
            label: 'Adakah biaya jika berinvestasi reksa dana di SFAST?',
            children: (
              <p><span style={{ fontWeight: 700, marginBottom: 0 }}>SFAST</span> tidak mengambil komisi sepeserpun (Rp0) untuk setiap proses setor dan tarik reksa dana yang kamu lakukan. Kendati demikian, setiap manajer investasi memiliki kebijakan biaya yang berbeda-beda. Kamu juga tetap dikenakan biaya transfer antarbank ketika rekening sumber danamu berbeda dengan bank kustodian.
                <br /><br />
                Harap membaca prospektus dan fund fact sheet sebelum berinvestasi untuk mengurangi kesalahpahaman.
              </p>
            )
          }
        ]
      },
      {
        id: 'Panduan',
        content: [
          {
            key: '1',
            label: 'Apa itu SFAST?',
            children: <p><span style={{ fontWeight: 700, marginBottom: 0 }}>SFAST</span> adalah aplikasi investasi yang memudahkanmu untuk berinvestasi di saham dan reksa dana secara online. SFAST adalah platform trading saham secara real-time dan memberikan pilihan reksa dana terbaik. Selain itu, SFAST juga berbagai pelajaran terkait investasi untuk pemula sampai berpengalaman. Sehingga semua orang, selain memiliki akses terhadap produk investasi yang aman, juga memiliki pengetahuan yang menunjang.</p>
          },
          {
            key: '2',
            label: 'Apakah aman berinvestasi di SFAST?',
            children: (
              <p>
                Semua transaksi saham dan reksa dana difasilitasi oleh PT Surya Fajar Sekuritas, dengan pialang yang terdaftar di OJK dan IDX, serta APERD yang terdaftar di OJK.
                <br /><br />
                <span style={{ fontWeight: 700, marginBottom: 0 }}>Untuk saham</span>, dana harus ditransfer (top-up) ke RDN (Rekening Dana Nasabah), dan hanya bisa dicairkan ke rekening terdaftar. Selain itu, setiap transaksi baru akan dijalankan setelah mendapatkan autentikasi dari kamu selaku investor.
                <br /><br />
                <span style={{ fontWeight: 700, marginBottom: 0 }}>Untuk reksadana</span>, semua dana investasi kamu akan dikelola langsung oleh pihak Manajer Investasi yang telah ditunjuk. Selain itu, kamu juga akan mendapatkan email kepemilikan reksa dana dari bank kustodian. Kamu pun bisa mengetahui status investasimu, bahkan di luar aplikasi SFAST.
              </p>
            )
          },
          {
            key: '3',
            label: 'Bagaimana cara memulai berinvestasi saham di SFAST?',
            children: <p>Investor cukup mengisi data sesuai dengan regulasi yang berlaku, dan yang tersedia di aplikasi SFAST. Aktivasi akun akan dilakukan secara online, kamu tidak perlu bertemu dengan agen. Setelahnya, kamu bisa mengisi RDN untuk mulai transaksi jual-beli saham di SFAST.</p>
          },
          {
            key: '4',
            label: 'Bagaimana cara memulai berinvestasi reksa dana di SFAST?',
            children: (
              <p>
                Lengkapi data diri kamu di aplikasi kami. Data ini akan digunakan untuk verifikasi wajib dari OJK demi keamanan dan kenyamanan kamu. Proses verifikasi ini biasa memakan waktu 3 jam (apabila data kamu lengkap dan tidak terkendala).
                <br /><br />
                Selagi menunggu proses verifikasi berlangsung, kamu sudah dapat mempelajari produk-produk reksa dana dan prospektus yang tersedia di aplikasi sebelum melakukan transaksi.
              </p>
            )
          },
          {
            key: '5',
            label: 'Mengapa saya perlu berinvestasi?',
            children: <p>Kita perlu berinvestasi karena adanya inflasi atau penurunan nilai mata uang, selama ada kenaikan harga maka nilai mata uang kita pun akan terus merosot. Ditambah kebutuhan masa depan yang juga akan semakin meningkat, seperti menikah, kebutuhan anak sekolah, dan cadangan dana untuk hari tua. Jika ingin masa depanmu lebih terjamin, berinvestasilah sejak dini.</p>
          }
        ]
      }
    ],
    [
      {
        id: 'Pemula',
        content: [
          {
            key: '1',
            label: 'Dari mana keuntungan yang saya peroleh ketika berinvestasi saham?',
            children: <p>
              Ada 2 jenis keuntungan yang bisa kamu dapatkan dengan berinvestasi saham, yaitu capital gain dan dividen. Capital Gain merupakan selisih antara harga jual yang lebih tinggi dari harga beli. Sedangkan dividen, adalah pembagian hasil keuntungan dari kinerja perusahaan tersebut. Biasanya, dividen dibagikan setahun sekali. Harap diingat bahwa pembagian dividen tergantung pada apakah perusahaan untung atau tidak, dan apakah perusahaan memutuskan untuk membagikan dividennya. Karena itu, nominal dividen yang dibagikan bisa saja berbeda setiap tahun.
              <br/><br/>
              Selain itu, dengan menjadi pemegang saham, kamu berhak mengikuti Rapat Umum Pemegang Saham (RUPS) dan dapat turut serta mengambil keputusan bagi perusahaan, sesuai dengan proporsi kepemilikanmu.
            </p>
          },
          {
            key: '2',
            label: 'Kapan saja jam perdagangan bursa efek?',
            children: <p>
              Jam transaksi bursa saham (pasar reguler) dimulai dari 9.00-11.30 WIB, dilanjutkan pukul 13.30-14.49 WIB.
              <br/><br/>
              Pada Sabtu, Minggu, dan Libur Nasional, tidak ada transaksi yang dijalankan di Bursa.
              <br/><br/>
              Bila ada perubahan, kamu bisa melihatnya langsung di: <a href='https://www.idx.co.id/investor/jam-perdagangan/' target='_blank'>https://www.idx.co.id/investor/jam-perdagangan</a>
            </p>
          },
          {
            key: '3',
            label: 'Mengapa produk efek bisa memberikan untung di atas bunga tabungan?',
            children: <p>Keuntungan dari investasi bisa diperoleh dari Capital Gain, yakni nilai investasi yang lebih besar daripada nilai modal, maupun melalui dividen yang dibagikan oleh saham dan manajer investasi. Karena itulah, potensi keuntungannya lebih besar ketimbang tabungan bank, meskipun risikonya juga lebih besar.</p>
          },
          {
            key: '4',
            label: 'Saham mana dulu yang sebaiknya saya beli?',
            children: <p>Untuk pemula, saham indeks (LQ45, KOMPAS100, IDX30, dll.) bisa menjadi pilihan, karena sudah teruji performanya selama bertahun-tahun. Selain itu, kamu juga perlu mengetahui tujuan investasi sahammu, termasuk memperhitungkan apakah kamu mau berinvestasi jangka pendek maupun panjang.</p>
          },
        ]
      },
      {
        id: 'RDN',
        content: [
          {
            key: '1',
            label: 'Apa itu RDN?',
            children: <p>Rekening Dana Nasabah (RDN) adalah rekening dana atas nama Nasabah (kamu), yang dibuka oleh Perantara Pedagang Efek (misalnya SFAST). Sesuai peraturan perundang-undangan yang berlaku, RDN digunakan untuk bertransaksi di pasar modal. Layaknya dompet digital, kamu perlu setor (top-up) RDN untuk mulai berinvestasi. Pasalnya, semua transaksi jual dan beli akan menggunakan RDN sebagai sumber dana. Nantinya, jika kamu mau menarik keuntungan investasi, kamu harus menarik (withdraw) dari RDN ke rekening asal kamu.</p>
          },
          {
            key: '2',
            label: 'Bagaimana proses penarikan dari RDN?',
            children: <p>
              Cukup buka laman profilmu, dan temukan pilihan untuk Tarik/Withdraw pada saldo RDN. Ikuti instruksi yang tertera.
              <br/><br/>
              Harap diingat bahwa dana yang bisa ditarik merupakan dana dua hari kemudian (T+2). Pasalnya, semua transaksi jual-beli efek akan dianggap selesai dan memengaruhi saldo RDN setelah 2 hari kerja bursa.
            </p>
          }
        ]
      }
    ]
  ];

  let subCategoryText = '';
  switch (category) {
    case 0:
      subCategoryText = 'SFAST';
      break;
    case 1:
      subCategoryText = 'Saham';
      break;
  
    default:
      break;
  }

  return (
    <LayoutPage>
      <PageHeader 
        title="Tentang Kami"
        menu={headerMenu}
        path={`/faq`} 
      />
      
      <div className='layout-single-page faq'>
        <div className='top'>
          <section className='sub-heading'>
            <Text className="h3">Ada yang Ingin Ditanyakan?</Text>
            <p>Kami siap membantu dengan pertanyaan atau Anda. Silakan hubungi kami atau kunjungi cabang terdekat kami.</p>
          </section>

          <section className="faq-category">
            <h3>Pilih Kategori Pertanyaan</h3>

            <Row style={{ marginTop: 24 }} gutter={[24, 24]}>
              <Col md={12}>
                <Card className='category-card'>
                  <Row gutter={[12, 0]}>
                    <Col lg={12}>
                      <Image
                        preview={false}
                        src={CategoryOneImage}
                      />
                    </Col>
                    <Col lg={12}>
                      <div 
                        className='radio-button-wrapper'
                        onClick={() => {
                          setCategory(0);
                          setSubCategory(0);
                        }}
                      >
                        <Radio
                          name='category'
                          value='0'
                          checked={category === 0}
                          onChange={(e) => {
                            setCategory(parseInt(e.target.value));
                            setSubCategory(0);
                          }}
                          children={<span style={{ fontSize: 16 }}>SFAST</span>}
                          style={{ 
                            fontWeight: 600,
                            width: '100%',
                            height: '100%',
                          }} 
                        />
                      </div>
                      <p>Temukan Jawaban untuk Pertanyaan yang Sering Diajukan tentang SFAST</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12}>
                <Card
                  className='category-card'
                  style={{
                    backgroundColor: '#F1F4F7',
                    borderRadius: 16
                  }}
                >
                  <Row gutter={[12, 0]}>
                    <Col lg={12}>
                      <Image
                        className="img-rounded"
                        preview={false}
                        src={CategoryTwoImage}
                      />
                    </Col>
                    <Col lg={12}>
                      <div 
                        className='radio-button-wrapper'
                        onClick={() => {
                          setCategory(1);
                          setSubCategory(0);
                        }}
                      >
                        <Radio
                          name='category'
                          value='1'
                          checked={category === 1}
                          onChange={(e) => {
                            setCategory(parseInt(e.target.value));
                            setSubCategory(0);
                          }}
                          children={<span style={{ fontSize: 16 }}>Saham</span>}
                          style={{ 
                            fontWeight: 600,
                            width: '100%',
                            height: '100%',
                          }} 
                        />
                      </div>
                      <p>Temukan Jawaban untuk Pertanyaan yang Sering Diajukan tentang Surya Fajar Sekuritas</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </section>
          
          <section className="faq-subcategory">
            <Card>
              <Row>
                <Col span={24} lg={9}>
                  <h3>Pilih Sub Kategori Pertanyaan tentang {subCategoryText}</h3>

                  {categoryContent[category].map((e, index) => (
                    <>
                      <button
                        key={index}
                        className={`btn-blue ${subCategory == index ? '' : 'active'}`}
                        onClick={() => setSubCategory(index)}
                        style={{ 
                          minWidth: 'fit-content', 
                          display: 'block', 
                          fontSize: 24,
                          padding: '0 24px',
                          minHeight: 50,
                          marginBottom: 24
                        }}
                      >
                        {subCategory == index ? (
                          <img style={{ marginRight: 18 }} src={IconFolderOpen} />
                          ) : (
                          <img style={{ marginRight: 18 }} src={IconFolder} />
                        )}
                        {e.id}
                      </button>
                    </>
                  ))}
                </Col>
                <Col span={24} lg={15}>
                  <Collapse
                    expandIconPosition='end'
                    className="site-collapse-custom-collapse"
                    bordered={false}
                    expandIcon={({ isActive }) => isActive ? <img src={IconMinus} /> : <img src={IconPlus} />}
                  >
                    {categoryContent[category][subCategory].content.map((e) => (
                      <Panel header={e.label} key={e.key} className="site-collapse-custom-panel">
                        {e.children}
                      </Panel>
                    ))}
                  </Collapse>
                </Col>
              </Row>
            </Card>
          </section>

          <section className='faq-contact'>
            <Card>
              <h3>Belum Menemukan yang Anda Cari?</h3>
              <p>Chat dengan Kami atau Kirim Email kepada Kami</p>
              <Row gutter={[26, 0]}>
                <Col span={24} md={24} lg={8}>
                  <button><img style={{ marginRight: 10 }} src={IconEmail} /> <span>cs@sfsekuritas.co.id</span></button>
                </Col>
                <Col span={24} md={24} lg={8}>
                  <button><img style={{ marginRight: 10 }} src={IconPhone} /> <span>021-2788-3989</span></button>
                </Col>
                <Col span={24} md={24} lg={8}>
                  <button><img style={{ marginRight: 10 }} src={IconMessage} /> <span>081317755508</span></button>
                </Col>
              </Row>
            </Card>
          </section>
        </div>
      </div>
    </LayoutPage>
  )
}

export default Faq