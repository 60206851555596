export const INITIAL_PAGINATION_STATE = {
  page: 1,
  pageCount: null,
  pageSize: 10,
  total: null,
};

export const PAGE_TYPES = [
  {
    type: "prospektuses",
    name: "Prospektus Umum",
  },
  {
    type: "risets",
    name: "Riset Harian",
  },
];
