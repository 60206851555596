import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Profile3Image from "../../Assets/Images/Profile/profile-3.png";
import ProfileMobile3Image from "../../Assets/Images/Profile/profile-mobile-3.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const WhyUs = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProfileMobile3Image : Profile3Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">Terpercaya dan Aman</Text>
        <Text className="body">
          <p>
            SF Sekuritas terdaftar dan diawasi oleh Otoritas Jasa Keuangan
            (“OJK“) dan Bursa Efek Indonesia, serta merupakan bagian dari group
            usaha PT SURYA FAJAR CAPITAL Tbk (SF Capital) sebuah perusahaan
            terbuka yang bergerak di bidang investasi dan konsultasi manajemen
            dan tercatat juga pada Bursa Efek Indonesia. Setiap aksi korporasi
            dari SF Capital maupun anak usahanya dapat dipantau dan wajib
            mengikuti aturan yang berlaku di pasar modal.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Integritas</Text>
        <Text className="body">
          <p>
            Hubungan kami dengan nasabah tidak hanya dibangun berdasarkan
            kepercayaan, namun juga didasarkan oleh integritas dari SF
            Sekuritas, dimana Perusahaan akan selalu berkonsisten dalam
            bertindak sesuai dengan peraturan dipasar modal.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Komitmen</Text>
        <Text className="body">
          <p>
            Komitmen adalah salah satu pelayanan utama kami terhadap para
            nasabah, komitmen ini tertanam dalam kerja tim kami yang profesional
            dan di dalam kultur usaha kami, terlihat pada Strategi Perusahaan
            dalam Jangka Panjang diantaranya :
          </p>
          <ul>
            <li>
              <Text className="h4">Mengedepankan “excellent service“</Text>
              <p>
                Kultur yang dibangun pada SF Sekuritas adalah pelayanan ke
                nasabah secara <i>excellent</i>, dengan tetap memperhatikan
                prinsip-prinsip kehati-hatian yang berlandaskan asas tata kelola
                perusahaan yang baik (<i>Good Corporate Governance</i>).
              </p>
            </li>
            <li>
              <Text className="h4">
                Menerapkan sistem Smart Risk Management
              </Text>
              <p>
                Sistem Smart Risk Management merupakan suatu sistem yang
                dikembangkan oleh SF Sekuritas yang mampu mengaplikasikan
                prinsip-prinsip manajemen risiko secara komprehensif namun tidak
                menghambat bisnis proses dan ekspansi SF Sekuritas.
              </p>
            </li>
            <li>
              <Text className="h4">
                Menangkap potensi retail dari golongan professional, pegawai dan
                pengusaha
              </Text>
              <p>
                Peluang retail dari golongan masyarakat perkantoran di Indonesia
                seperti para professional, pegawai dan bahkan para pengusaha
                merupakan sasaran utama SF Sekuritas dalam mengembangkan nasabah
                retail nya.
              </p>
            </li>
          </ul>
        </Text>
      </Col>
    </Row>
  );
};

export default WhyUs;
