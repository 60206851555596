import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Image,
  Input,
  Pagination,
  Radio,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Product7Image from "../../Assets/Images/Product/product-8.png";
import ProductMobile7Image from "../../Assets/Images/Product/product-8.png";
import CardMembershipIcon from "../../Assets/Icons/card_membership.svg";
import SchoolIcon from "../../Assets/Icons/school.svg";
import TodayIcon from "../../Assets/Icons/today.svg";
import "../../Assets/Css/SinglePage.css";
import ServiceContext from "../../Services";
import moment from "moment";
import { Link } from "react-router-dom";

const { Text } = Typography;

const InvestorIncubator = () => {
  const { getAllService } = useContext(ServiceContext);
  const [jenisSelected, setJenisSelected] = useState("Terbaru");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [plainOptions, setPlainOptions] = useState([
    "Terbaru",
    "Terakhir",
    "Nama A-Z",
  ]);
  const [tampilanSelected, setTampilanSelected] = useState("View Icon");

  const columns = [
    {
      title: "No",
      key: "index",
      width: 5,
      render: (text, record, index) =>
        (pagination["pagination[page]"] - 1) *
          pagination["pagination[pageSize]"] +
        index +
        1,
    },
    {
      title: "Nama Lengkap",
      dataIndex: "attributes",
      key: "namaLengkap",
      width: 30,
      render: (key) => key?.namaLengkap,
    },
    {
      title: "Tipe Institusi",
      dataIndex: "attributes",
      key: "tipeInstitusi",
      width: 10,
      render: (key) => key?.tipeInstitusi,
    },
    {
      title: "Institusi",
      dataIndex: "attributes",
      key: "institusi",
      width: 15,
      render: (key) => key?.institusi,
    },
    {
      title: "Kelas",
      dataIndex: "attributes",
      key: "kelas",
      width: 30,
      render: (key) => {
        let array = key?.kelas.split("\n");
        const data = array?.map((item, index) => (
          <Tag
            color="processing"
            key={index}
            style={{ borderRadius: "10px", marginBottom: "10px" }}
          >
            {item}
          </Tag>
        ));
        return <div>{data}</div>;
      },
    },
    {
      title: "Tanggal Kelulusan",
      dataIndex: "attributes",
      key: "tanggalKelulusan",
      width: 5,
      render: (key) =>
        key.tanggalKelulusan
          ? moment(key.tanggalKelulusan).format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Nomor Sertifikat",
      dataIndex: "attributes",
      key: "nomorSertifikat",
      width: 5,
      render: (key) => key?.nomorSertifikat,
    },
  ];

  const [pagination, setPagination] = useState({
    populate: "*",
    sort: ["id:desc"],
    "pagination[page]": 1,
    "pagination[pageSize]": 10,
    "filters[$or][0][namaLengkap][$contains]": "",
    "filters[$or][1][tipeInstitusi][$contains]": "",
    "filters[$or][2][institusi][$contains]": "",
    "filters[$or][3][nomorSertifikat][$contains]": "",
    "filters[$or][4][tanggalKelulusan][$contains]": "",
  });

  const handleChange = (paginations) => {
    let pageSize = paginations.pageSize;
    if (paginations.pageSize === "All") {
      pageSize = 999999;
    }
    setPagination({
      ...pagination,
      "pagination[page]": paginations.current,
      "pagination[pageSize]": pageSize,
    });
  };

  const handleChangePagination = (pages, pageSizes) => {
    let pageSize = pageSizes;
    if (pageSize === "All") {
      pageSize = 999999;
    }
    setPagination({
      ...pagination,
      "pagination[page]": pages,
      "pagination[pageSize]": pageSize,
    });
  };

  const reset = () => {
    setJenisSelected("Terbaru");
    setSearch("");
    setPagination({
      ...pagination,
      sort: "id:desc",
      "filters[$or][0][namaLengkap][$contains]": "",
      "filters[$or][1][tipeInstitusi][$contains]": "",
      "filters[$or][2][institusi][$contains]": "",
      "filters[$or][3][nomorSertifikat][$contains]": "",
      "filters[$or][4][tanggalKelulusan][$contains]": "",
      "pagination[page]": 1,
      "pagination[pageSize]": 10,
    });
  };

  const getBerita = async () => {
    const data = await getAllService("investor-incubators", pagination);
    if (data) {
      setData(data);
    }
  };

  useEffect(() => {
    getBerita();
  }, [pagination]);

  return (
    <div className="reksa-dana">
      <Row gutter={20} justify={"space-between"}>
        <Col span={24}>
         <div className="img-section">
        
         <a href="https://lms.sfsekuritas.co.id/" >
            <Image
          
              className="img-rounded"
              preview={false}
              src={
                window.innerWidth < 768 ? ProductMobile7Image : Product7Image
              }
            />
            </a>
           
          </div>
         
        </Col>
        <Col span={24}>
          <Text className="h2">Data Peserta Investor Incubator</Text>
          <Text className="body">
            <p>Temukan nama kamu pada daftar yang tersedia dihalaman ini</p>
          </Text>
          <div className="pencarian">
            <Row className="group-pencarian" align="middle">
              <Col sm={24} md={24} lg={8} xl={8} className="group-label">
                <div className="label-group">
                  <div>Filter Pencarian</div>
                  <div className="reset" onClick={reset}>
                    Reset
                  </div>
                </div>
                <div className="label-checkbox">
                  <Radio.Group
                    options={plainOptions}
                    onChange={(evt) => {
                      setJenisSelected(evt?.target?.value);

                      let newEvt = "id:desc";

                      if (evt?.target?.value === "Terbaru") {
                        newEvt = "id:desc";
                      } else if (evt?.target?.value === "Terakhir") {
                        newEvt = "id:asc";
                      } else if (evt?.target?.value === "Nama A-Z") {
                        newEvt = "namaLengkap:asc";
                      }

                      setPagination({
                        ...pagination,
                        sort: newEvt,
                      });

                      console.log(evt?.target?.value);
                    }}
                    value={jenisSelected}
                  />
                </div>
              </Col>
              <Col sm={24} md={24} lg={6} xl={6} className="group-label">
                <div className="label-group">
                  <div>Ubah Tampilan</div>
                </div>
                <div className="label-checkbox">
                  <Radio.Group
                    options={["View Icon", "View Grid"]}
                    onChange={(evt) => {
                      setTampilanSelected(evt?.target?.value);
                      reset();
                    }}
                    value={tampilanSelected}
                  />
                </div>
              </Col>
              <Col sm={24} md={24} lg={10} xl={10} className="group-search">
                <Input
                  size="large"
                  placeholder="Cari Data Peserta"
                  prefix={<SearchOutlined />}
                  onChange={(evt) => {
                    setSearch(evt?.target?.value);
                    setPagination({
                      ...pagination,
                      "filters[$or][0][namaLengkap][$contains]":
                        evt?.target?.value,
                      "filters[$or][1][tipeInstitusi][$contains]":
                        evt?.target?.value,
                      "filters[$or][2][institusi][$contains]":
                        evt?.target?.value,
                      "filters[$or][3][nomorSertifikat][$contains]":
                        evt?.target?.value,
                      "filters[$or][4][tanggalKelulusan][$contains]":
                        evt?.target?.value,
                    });
                  }}
                  value={search}
                />
              </Col>
            </Row>
          </div>
          {tampilanSelected === "View Grid" ? (
            <div className="table">
              <Table
                className="reksadana-table"
                bordered
                rowKey={"id"}
                onChange={handleChange}
                pagination={{
                  total: data?.meta?.pagination?.total,
                  pageSize: data?.meta?.pagination?.pageSize,
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} dari ${total} item`,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "10",
                    "20",
                    "50",
                    "100",
                    data?.meta?.pagination?.total,
                  ],
                }}
                columns={columns}
                dataSource={data?.data}
              />
            </div>
          ) : (
            <Row gutter={[20, 20]}>
              {data?.data?.map((item, index) => (
                <Col sm={24} md={24} lg={24} xl={12}>
                  <div key={index} className="icon-card">
                    <div className="img-information">
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_FILE_URL +
                          item?.attributes?.foto?.data?.attributes?.url
                        }
                      />
                    </div>
                    <div className="text-information">
                      <Text className="h4">
                        {item?.attributes?.namaLengkap}
                      </Text>
                      <Text className="body">
                        {item?.attributes?.institusi}
                      </Text>
                      <div className="tag-icon">
                        <div className="label-group">
                          <Image src={TodayIcon} preview={false} />
                          <Text className="body">Tanggal Kelulusan</Text>
                        </div>
                        <Text className="body">
                          {item?.attributes?.tanggalKelulusan}
                        </Text>
                      </div>
                      <div className="tag-icon">
                        <div className="label-group">
                          <Image src={CardMembershipIcon} preview={false} />
                          <Text className="body">Nomor Sertifikat</Text>
                        </div>
                        <Text className="body">
                          {item?.attributes?.nomorSertifikat}
                        </Text>
                      </div>
                      <div>
                        <div className="label-group">
                          <Image src={SchoolIcon} preview={false} />
                          <Text className="body">Kelas</Text>
                        </div>
                        {item?.attributes?.kelas
                          .split("\n")
                          ?.map((item2, index2) => (
                            <Tag
                              color="processing"
                              key={index2}
                              style={{
                                borderRadius: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {item2}
                            </Tag>
                          ))}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              {data?.meta?.pagination && (
                <Col
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ textAlign: "right" }}
                >
                  <Pagination
                    defaultCurrent={1}
                    total={data?.meta?.pagination?.total}
                    pageSize={data?.meta?.pagination?.pageSize}
                    showTotal={(total, range) =>
                      `Menampilkan ${range[0]}-${range[1]} dari ${total} item`
                    }
                    showSizeChanger={true}
                    pageSizeOptions={[
                      "10",
                      "20",
                      "50",
                      "100",
                      data?.meta?.pagination?.total,
                    ]}
                    onChange={handleChangePagination}
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InvestorIncubator;
