import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Product6Image from "../../Assets/Images/Product/product-6.png";
import ProductMobile6Image from "../../Assets/Images/Product/product-mobile-6.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const ResearchRecommendation = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProductMobile6Image : Product6Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">Riset & Rekomendasi</Text>
        <Text className="body">
          <p>
            PT Surya Fajar Sekuritas memiliki tim ahli dan profesional yang siap
            melayani Anda untuk mendapatkan:
          </p>
          <p>
            <ul>
              <li>Research Report</li>
              <li>Kondisi terbaru pasar saham</li>
              <li>Rekomendasi saham harian</li>
            </ul>
          </p>
          <p>
            Selain itu, kami mengadakan briefing pagi melalui Instagram{" "}
            <a
              href="https://instagram.com/sfast.official"
              target="_blank"
              rel="noreferrer"
            >
              <b>sfast.official</b>
            </a>{" "} 
            dan Tiktok  <a
              href="https://www.tiktok.com/@sfast.crew"
              target="_blank"
              rel="noreferrer"
            >
              <b>sfast.crew</b>
            </a>{" "} 
            setiap hari <b>Senin-Jumat mulai dari jam 08.15 WIB</b>.
          </p>
          <p>
            Untuk informasi dan pertanyaan lebih lanjut dapat menghubungi kami
            melalui email{" "}
            <a href="mailto:research@sfsekuritas.co.id">
              <b>research@sfsekuritas.co.id</b>
            </a>
          </p>
        </Text>
      </Col>
    </Row>
  );
};

export default ResearchRecommendation;
