import React, { useCallback, useContext, useEffect, useState } from 'react'
import ServiceContext from '../../Services'
import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Button from '../Base/Button';

const { Text } = Typography;
const PUBLIC_URL = process.env.REACT_APP_FILE_URL;

const defaultQuery = {
  populate: "*",
  sort: ["tanggal:desc"],
  "pagination[page]": 1,
  "pagination[pageSize]": 3,
}

export default function OtherNews() {
  const { getAllService } = useContext(ServiceContext);
  const [data, setData] = useState([]);

  const getAPI = useCallback(async () => {
    const response = await getAllService('beritas', defaultQuery);
    if (response) setData(response?.data);
  })

  useEffect(() => {
    getAPI();
  }, []);

  return (
    <>
      <div className="layout-single-page news">
        <Row style={{ marginBottom: 24 }}>
          <Col span={24}>
            <Text className="h3">Berita & Kegiatan Terbaru Lainnya</Text>
          </Col>
        </Row>

        <Row gutter={[24, 46]}>
          {data?.map((e) => (
            <Col span={24} xl={8} key={e.id}>
              <Link to={`/berita/detail/${e.id}`}>
                <Card className='product'>
                  <img 
                    src={`${PUBLIC_URL}${e?.attributes?.gambar?.data?.attributes?.url}`} 
                    alt=""
                    loading='lazy'
                  />
                  <Text className="h5 judul">{e?.attributes?.judul}</Text>
                  <Text className="body">{moment(e?.attributes?.tanggal).format("dddd, DD MMMM YYYY")}</Text>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>

        <Row align='end' style={{ marginTop: 30 }}>
          <Col>
            <Button
              style={{ paddingRight: 60, paddingLeft: 60 }}
              title="Lihat Semua Berita"
              onClick={() => {}}
              color='primary'
              rounded
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
