import React from 'react';
import styles from './index.module.css';

export default function Button(props) {
  const theme = props.color ? props.color : 'primary';
  let className = `${styles['base-button']} ${styles[`base-button-${theme}`]}`;

  if (props.rounded) className += ` ${styles['base-button-rounded']}`;

  const onClick = () => {
    if (props.onClick) props.onClick();
  }

  return (
    <button 
      className={className}
      style={props.style}
      onClick={onClick}
    >
      {props.title}
    </button>
  )
}
