import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import ServiceContext from "../../Services";
import {
  Card,
  Checkbox,
  Col,
  Image,
  Pagination,
  Radio,
  Row,
  Skeleton,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import "moment/locale/id";
import DemoImage from "../../Assets/Images/News/news-3.png";
import ProspectusCard from "./Components/Prospectus";
import { INITIAL_PAGINATION_STATE } from "./config";
moment.locale("id");

function Research() {
  let { path } = useParams();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { getAllService } = useContext(ServiceContext);
  const [pahtUrl, setPathUrl] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const paramType = searchParams.get("type");
  const paramPage = searchParams.get("page");

  const [pagination, setPagination] = useState({
    ...INITIAL_PAGINATION_STATE,
    page: paramPage || 1,
  });

  const [type, setType] = useState(
    path === "riset" ? "riset-harian" : "prospektus-umum",
  );

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 0,
      pageSize: 10,
    },
  });

  const description = {
    "riset-harian": "Temukan Hasil Analisa Terbaru Kami di Sini",
    "riset-perusahaan":
      "Temukan Informasi Terkini tentang Perusahaan dan Analisis Mendalam",
    "ulasan-pasar": "Dapatkan Pemahaman Mendalam tentang Pergerakan Pasar",
    "prospektus-umum": "Menyelami Informasi Mendalam tentang Peluang Investasi",
    "prospektus-internal":
      "Informasi Rinci tentang Investasi dan Pengembangan Perusahaan",
  };

  const menu = [
    {
      label: "Riset",
      link: "/riset/riset?type=riset-harian",
    },
    {
      label: "Prospektus",
      link: "/riset/prospektus?type=prospektus-umum",
    },
  ];

  useEffect(() => {
    setPathUrl(path);
    if (path !== "riset" && path !== "prospektus") {
      setPathUrl("riset/riset");
    } else {
      setType(searchParams.get("type"));
    }
  }, [path]);

  const getRiset = async () => {
    setLoading(true);
    const data = await getAllService(
      pahtUrl === "riset" ? "risets" : "prospektuses",
      getRandomuserParams(tableParams),
    );

    if (data) {
      const { data: result, meta } = data;
      const { pagination: paging } = meta;

      setDataSource(result);
      setPagination((prev) => {
        return {
          ...prev,
          pageSize: paging.pageSize,
          pageCount: paging.pageCount,
          total: paging.total,
        };
      });
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data?.meta?.pagination?.total,
        },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pahtUrl) {
      setLoading(true);

      const toId = setTimeout(() => {
        getRiset();
        setType(paramType);
      }, 700);

      return () => clearTimeout(toId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableParams?.pagination?.current,
    tableParams?.pagination?.pageSize,
    pahtUrl,
    paramType,
  ]);

  const getRandomuserParams = (params) => ({
    "filters[tipe][$eqi]": searchParams
      .get("type")
      .replace("-", " ")
      .toLowerCase()
      .replace(/^\w|\s\w/g, function (letter) {
        return letter.toUpperCase();
      }),
    "pagination[page]": params.pagination?.current,
    "pagination[pageSize]": params.pagination?.pageSize,
    "sort[]": pahtUrl === "riset" ? "createdAt:desc" : "tanggal:desc",
    populate: "*",
  });

  const onChangeRadio = (evt) => {
    if (evt?.target?.value === "riset") {
      navigate("/riset/riset?type=riset-harian");
    } else {
      navigate("/riset/prospektus?type=prospektus-umum");
    }
  };

  const reset = () => {
    navigate("/riset/riset?type=riset-harian");
  };

  useEffect(() => {
    if (pagination.page) {
      const toId = setTimeout(() => {
        setTableParams((prev) => {
          return {
            ...prev,
            pagination: {
              ...prev.pagination,
              current: pagination.page,
            },
          };
        });
      }, 300);

      return () => clearTimeout(toId);
    }
  }, [pagination.page]);

  useEffect(() => {
    setPagination((prev) => {
      return {
        ...prev,
        page: paramPage || 1,
      };
    });
  }, [paramPage]);

  return (
    <LayoutPage>
      <PageHeader
        menu={menu}
        title="Riset & Rekomendasi Harian Terbaru"
        path={`/riset/${pahtUrl}`}
      />
      <div className="layout-single-page research">
        <Row gutter={20}>
          <Col sm={24} md={8} lg={6} xl={6} className="left-section">
            <Row className="sub-header" justify="space-between">
              <Col sm={12} md={12} lg={12} xl={12} className="filter">
                Filter
              </Col>
              <Col
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="reset"
                onClick={reset}
              >
                Reset
              </Col>
            </Row>
            <Card className="card-filter">
              <div className="card-container">
                <div className="title">Pilih Data</div>
                <div className="radio">
                  <div>
                    <Radio
                      onChange={onChangeRadio}
                      value="riset"
                      checked={pahtUrl === "riset"}
                    >
                      Riset
                    </Radio>
                  </div>
                  <div>
                    <Radio
                      onChange={onChangeRadio}
                      value="prospektus"
                      checked={pahtUrl === "prospektus"}
                    >
                      Prospektus
                    </Radio>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="card-container">
                <div className="title">Kategori</div>
              </div>
              <div className="list">
                {pahtUrl === "riset" ? (
                  <>
                    <Link
                      to="/riset/riset?type=riset-harian"
                      className={type === "riset-harian" && "active"}
                    >
                      Riset Harian
                    </Link>
                    <Link
                      to="/riset/riset?type=riset-perusahaan"
                      className={type === "riset-perusahaan" && "active"}
                    >
                      Riset Perusahaan
                    </Link>
                    <Link
                      to="/riset/riset?type=ulasan-pasar"
                      className={type === "ulasan-pasar" && "active"}
                    >
                      Ulasan Pasar
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/riset/prospektus?type=prospektus-umum"
                      className={type === "prospektus-umum" && "active"}
                    >
                      Umum
                    </Link>
                    <Link
                      to="/riset/prospektus?type=prospektus-internal"
                      className={type === "prospektus-internal" && "active"}
                    >
                      Internal
                    </Link>
                  </>
                )}
              </div>
            </Card>
          </Col>
          <Col sm={24} md={16} lg={18} xl={18} className="right-section">
            <div className="sub-header">
              <div className="title">{type.replace("-", " ")}</div>
              <div className="description">{description?.[type]}</div>
            </div>
            {pahtUrl === "riset" && (
              <>
                <Row gutter={[20, 20]}>
                  {loading && (
                    <>
                      <Col sm={24} md={8} lg={8} xl={8}>
                        <Skeleton.Node active />
                      </Col>
                      <Col sm={24} md={8} lg={8} xl={8}>
                        <Skeleton.Node active />
                      </Col>
                      <Col sm={24} md={8} lg={8} xl={8}>
                        <Skeleton.Node active />
                      </Col>
                      <Col sm={24}>
                        <div className="pagination-skeleton">
                          <Skeleton.Button active />
                          <Skeleton.Button active />
                          <Skeleton.Button active />
                          <Skeleton.Button active />
                        </div>
                      </Col>
                    </>
                  )}

                  {!loading &&
                    dataSource &&
                    dataSource?.map((item, i) => (
                      <Col sm={24} md={8} lg={8} xl={8}>
                        <Link
                          to={"/risets/detail/" + item?.id}
                          className="card-item"
                        >
                          <Image
                            src={
                              process.env.REACT_APP_FILE_URL +
                              item?.attributes?.gambar?.data?.attributes?.url
                            }
                            preview={false}
                          />
                          <div>
                            <div className="title">
                              {item?.attributes?.judul}
                            </div>
                            <div className="date">
                              {moment(item?.attributes?.createdAt).format(
                                "dddd, DD MMMM YYYY",
                              )}
                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))}
                </Row>

                {!loading && pagination.pageCount && (
                  <Row>
                    <Col span={24}>
                      <Pagination
                        onChange={(page) => {
                          navigate(
                            `/riset/${pahtUrl}/?type=${paramType}&page=${page}`,
                          );
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        disabled={loading}
                        defaultCurrent={pagination.page}
                        total={pagination.total}
                        showSizeChanger={false}
                        style={{ marginTop: "32px" }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}

            {pahtUrl === "prospektus" && (
              <ProspectusCard
                dataSource={dataSource}
                tableParams={tableParams}
                loading={loading}
                setTableParams={setTableParams}
              />
            )}
          </Col>
        </Row>
      </div>
    </LayoutPage>
  );
}

export default Research;
