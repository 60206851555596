import React from "react";
import Hero from "../../Component/LandingPage/Hero";
import LayoutPage from "../../Layout";
import Section1 from "../../Component/LandingPage/Section1";
import Section2 from "../../Component/LandingPage/Section2";
import Section3 from "../../Component/LandingPage/Section3";
import Section4 from "../../Component/LandingPage/Section4";
import Section5 from "../../Component/LandingPage/Section5";
import Section6 from "../../Component/LandingPage/Section6";
import Section7 from "../../Component/LandingPage/Section7";

function LandingPage() {
  return (
    <LayoutPage>
      <Hero />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </LayoutPage>
  );
}

export default LandingPage;