import { Image } from "antd";
import parse from "html-react-parser";
import React, { useCallback, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import TrophyIcon from "../../Assets/trophy.svg";
import FlagIcon from "../../Assets/flag.svg";

const pagination = {
  clickable: true,
  renderBullet: function (index, className) {
    return `<span class="custom-line-bullet ${className}"></span>`;
  },
};

const HistoryCard = ({ data, year }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { month, type } = data[activeIndex];
  const image = type === "flag" ? FlagIcon : TrophyIcon;

  const handleChangeSlide = useCallback((swiper) => {
    setActiveIndex(swiper.activeIndex);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: 16,
        borderRadius: 16,
        marginLeft: 0,
        display: "flex",
        flexFlow: "row wrap",
        flexDirection: "column",
        position: "relative",
        gap: 8,
        maxWidth: "343px",
        transformStyle: "preserve-3d",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: 21,
          height: 21,
          left: 16,
          transform: "translateY(-50%) translateZ(-2px)",
          backgroundColor: "white",
          borderRadius: 5,
          rotate: "45deg",
          transformOrigin: "center",
        }}
      />
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          gap: 8,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "fit-content",
            borderRadius: 46,
            background: type === "flag" ? "#BCDFFF" : "#1979f5",
            padding: "0 8px",
            height: 30,
            alignItems: "center",
            gap: 4,
          }}
        >
          <Image
            src={image}
            preview={false}
            style={{ width: 12, height: 12, margin: 3 }}
          />
          <span
            style={{
              color: type === "flag" ? "#047CFF" : "#fff",
              textTransform: "capitalize",
              verticalAlign: "middle",
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "30px",
            }}
          >
            {month} {year}
          </span>
        </div>
        <Swiper
          style={{
            width: "100%",
            position: "relative",
          }}
          autoplay={true}
          spaceBetween={0}
          slidesPerView={1}
          modules={[Autoplay, Pagination]}
          pagination={pagination}
          onSlideChange={handleChangeSlide}
        >
          {data.map((desc, index) => (
            <SwiperSlide style={{ width: "100%" }} key={`description-${index}`}>
              <p style={{ margin: 0 }}>{parse(desc.label)}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HistoryCard;
