import { Col, Image, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Section1Image from "../../Assets/Images/LandingPage/section1-1.png";
import "../../Assets/Css/Section1.css";

const { Text } = Typography;

const Section1 = () => {
  return (
    <div className="section1">
      <Row gutter={20} justify={"space-between"}>
        <Col md={24} lg={19}>
          <Text className="h1">Sekilas SF Sekuritas</Text>
          <Text className="body">
            PT Surya Fajar Sekuritas (SF Sekuritas) merupakan sekuritas
            terpercaya dan profesional di Indonesia yang memposisikan diri
            sebagai mitra investasi pasar modal bagi ritel maupun korporasi.
            Dengan fokus melayani serta mempermudah investor sebagai mitra
            investasi pasar modal, dan emiten sebagai penjamin emisi.
          </Text>
          <Text className="body" style={{ marginTop: "5px" }}>
            SF Sekuritas telah aktif sebagai anggota Bursa Efek Indonesia (BEI)
            sejak 2018. Dengan nomor <b>SPAB-255/JATS/BEI.ANG/12-2018</b> dan
            memiliki izin usaha sebagai perantara perdagangan efek dari Otoritas
            Jasa Keuangan (OJK) pada tanggal 08 Oktober 2018 dengan Nomor Izin:
            <b>KEP-64/D.04/2018</b> dan mendapatkan Izin Usaha sebagai Penjamin
            Emisi Efek dari OJK pada tanggal 28 Juni 2019 dengan Nomor Izin:
            <b>KEP-42/D.04/2019</b>.
          </Text>
          <Link to="/profil/profil-perusahaan">
            <Text className="menu-sub-title-2">Lebih Lanjut</Text>
          </Link>
        </Col>
        <Col md={24} lg={5} style={{ textAlign: "right", width: "100%" }}>
          <div className="img-section" style={{ marginLeft: "auto" }}>
            <Image
              className="img-rounded"
              preview={false}
              src={Section1Image}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Section1;
