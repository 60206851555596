import React, { useEffect, useState } from "react";
import { Col, Divider, Image, Row, Typography } from "antd";
import Profile1Image from "../../Assets/Images/Profile/profile-1.png";
import ProfileMobile1Image from "../../Assets/Images/Profile/profile-mobile-1.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const Profile = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProfileMobile1Image : Profile1Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">Sekilas SF Sekuritas</Text>
        <Text className="body">
          <p>
            PT Surya Fajar Sekuritas sebagai salah satu perusahaan penjamin
            emisi yang hadir untuk menjawab kebutuhan investasi bagi para
            investor baik perorangan, perusahaan swasta maupun lembaga
            pemerintahan.
          </p>
          <p>
            <b>Kami memiliki produk unggulan, diantaranya:</b>
            <ol>
              <li>Saham</li>
              <li>Investment Banking</li>
              <li>Reksa dana</li>
              <li>Obligasi</li>
            </ol>
          </p>
          <p>
            Kami memiliki tim profesional yang selalu siap memberikan pelayanan
            terbaik untuk nasabah.
          </p>
          <p>
            Berdasarkan ketentuan Pasal 3 Anggaran Dasar SF Sekuritas, maksud
            dan tujuan SF Sekuritas ialah berusaha dalam bidang Perantara
            Pedagang Efek dan Penjamin Emisi Efek.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Mendapatkan Izin Usaha sebagai Perusahaan Efek (Perantara Pedagang
            Efek) dari Otoritas Jasa Keuangan pada tanggal : 08 Oktober 2018
            dengan Nomor Izin : KEP-64/D.04/2018
          </p>
          <p style={{ fontWeight: "bold" }}>
            Menjadi Anggota Bursa Efek Indonesia pada tanggal : 03 Desember 2018
            dengan Nomor Keanggotaan : SPAB-255/JATS/BEI.ANG/12-2018
          </p>
        </Text>
        <Divider className="divider" />
        <Row gutter={20} justify={"space-between"}>
          <Col span={24} xl={12}>
            <Text className="h2">Visi Kami</Text>
            <Text className="body">
              <p>
                Menjadi perusahaan sekuritas dan penyedia transaksi pasar modal
                terpercaya di Indonesia dan regional Asia
              </p>
            </Text>
          </Col>
          <Col span={24} xl={12}>
            <Text className="h2">Misi Kami</Text>
            <Text className="body">
              <ul>
                <li>
                  Menjadi perusahaan sekuritas yang mampu membangun strategi dan
                  melayani Anda dari waktu ke waktu
                </li>
                <li>
                  Menjadi pelopor perusahaan sekuritas di Indonesia untuk ritel
                  dan korporasi
                </li>
                <li>
                  Memperkuat keberadaan di lingkup lokal maupun regional Asia
                </li>
              </ul>
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Profile;
