import { Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../../Assets/Css/SocialMedia.css";

const SocialMedia = (props) => {
  return (
    <Space
      className="social-media"
      style={{ justifyContent: props?.align }}
      size={"large"}
    >
      <a
        href="https://www.instagram.com/sfast.official/"
        target="_blank"
        rel="noreferrer"
      >
        <span className="fa-stack">
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className="fab fa-instagram fa-stack-1x fa-inverse"></i>
        </span>
        <span className="menu-title">sfast.official</span>
      </a>
      <a
        href="https://www.tiktok.com/@sfast.crew"
        target="_blank"
        rel="noreferrer"
      >
        <span className="fa-stack">
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className="fab fa-tiktok fa-stack-1x fa-inverse"></i>
        </span>
        <span className="menu-title">sfastgenk</span>
      </a>
      <a
        href="https://www.youtube.com/@sfsekuritas"
        target="_blank"
        rel="noreferrer"
      >
        <span className="fa-stack">
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className="fab fa-youtube fa-stack-1x fa-inverse"></i>
        </span>
        <span className="menu-title">SFAST</span>
      </a>
    </Space>
  );
};

export default SocialMedia;
