import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Product1Image from "../../Assets/Images/Product/product-1.png";
import ProductMobile1Image from "../../Assets/Images/Product/product-mobile-1.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const PerdaganganEfek = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProductMobile1Image : Product1Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">Perdagangan Saham</Text>
        <Text className="body">
          <p>
            Saham adalah sebuah surat berharga sebagai bukti dari sebuah
            kepemilikan atas perusahaan yang merupakan bagian dari instrumen
            pasar modal dan paling banyak diperdagangkan oleh para pelaku pasar.
            Saham dapat dijadikan sebagai salah satu cara bagi investor untuk
            memiliki berbagai perusahaan ternama yang sudah tercatat dalam pasar
            modal.
          </p>
          <p style={{ fontWeight: "bold" }}>
            PT Surya Fajar Sekuritas memiliki layanan terbaik yang bisa anda
            nikmati, yaitu:
          </p>
          <p>
            <ol>
              <li>Transaksi saham melalui platform konvensional.</li>
              <li>
                Transaksi saham melalui platform online trading menggunakan
                aplikasi SFAST.
              </li>
            </ol>
          </p>
          <p>
            Untuk informasi dan pertanyaan lebih lanjut dapat menghubungi kami
            melalui email{" "}
            <a href="mailto:cs@sfsekuritas.co.id">
              <b>cs@sfsekuritas.co.id</b>
            </a>
          </p>
        </Text>
      </Col>
    </Row>
  );
};

export default PerdaganganEfek;
