import { Typography } from "antd";
import Client0 from "../../../Assets/Images/Product/Clients/Valbury.png";
import Client1 from "../../../Assets/Images/Product/Clients/Victoria.png";
import Client2 from "../../../Assets/Images/Product/Clients/Capital.png";
import Client3 from "../../../Assets/Images/Product/Clients/HP.png";
import Client4 from "../../../Assets/Images/Product/Clients/Prospera.png";
import Client5 from "../../../Assets/Images/Product/Clients/Samuel.png";
import Client6 from "../../../Assets/Images/Product/Clients/Simpan.png";
import Client7 from "../../../Assets/Images/Product/Clients/Star.png";
import Client8 from "../../../Assets/Images/Product/Clients/Trimegah.png";
import Client9 from "../../../Assets/Images/Product/Clients/Pinnacle.png";
import Client10 from "../../../Assets/Images/Product/Clients/Sinarmas.png";
import Client11 from "../../../Assets/Images/Product/Clients/Shinhan.png";
import Client12 from "../../../Assets/Images/Product/Clients/Insight.png";
import BasedProfile from "../../../Assets/Images/Product/ReksaDana/BasedProfile.png";
import InvestmentGoal from "../../../Assets/Images/Product/ReksaDana/InvestmentGoal.png";

import SliderReksaDana1 from "../../../Assets/Images/Product/ReksaDana/SliderReksaDana1.png";
import SliderReksaDana2 from "../../../Assets/Images/Product/ReksaDana/SliderReksaDana2.jpg";
import SliderReksaDana3 from "../../../Assets/Images/Product/ReksaDana/SliderReksaDana3.png";
import SliderReksaDana4 from "../../../Assets/Images/Product/ReksaDana/SliderReksaDana4.png";
import SliderReksaDana5 from "../../../Assets/Images/Product/ReksaDana/SliderReksaDana5.png";

import BarChartSvg from "../../../Assets/Icons/ReksaDana/BarChart.svg";
import ListChartSvg from "../../../Assets/Icons/ReksaDana/ListChart.svg";
import LockedHouseSvg from "../../../Assets/Icons/ReksaDana/LockedHouse.svg";
import ShoppingCartSvg from "../../../Assets/Icons/ReksaDana/ShoppingCart.svg";
import LeafsSvg from "../../../Assets/Icons/ReksaDana/Leafs.svg";
import RobotSvg from "../../../Assets/Icons/ReksaDana/Robot.svg";
import FlagsSvg from "../../../Assets/Icons/ReksaDana/Flags.svg";
import BackgroundRadialBlue from "../../../Assets/Images/Product/ReksaDana/BackgroundRadialBlue.png";
import BackgroundRadialGreen from "../../../Assets/Images/Product/ReksaDana/BackgroundRadialGreen.png";

const { Text } = Typography;

export const TABLE_COLUMN_CONFIGS = [
  {
    title: "Reksadana",
    dataIndex: "attributes",
    key: "reksadana",
    render: (key) => key?.reksadana,
  },
  {
    title: "Jenis",
    dataIndex: "attributes",
    key: "jenis",
    render: (key) => key?.jenis,
  },
  {
    title: "1M",
    dataIndex: "attributes",
    key: "oneMonth",
    render: (key) => (
      <Text style={{ color: key?.oneMonth > 0 ? "green" : "red" }}>
        {key?.oneMonth.toFixed(2)}%
      </Text>
    ),
  },
  {
    title: "3M",
    dataIndex: "attributes",
    key: "threeMonth",
    render: (key) => (
      <Text style={{ color: key?.threeMonth > 0 ? "green" : "red" }}>
        {key?.threeMonth.toFixed(2)}%
      </Text>
    ),
  },
  {
    title: "YTD",
    dataIndex: "attributes",
    key: "yearToDate",
    render: (key) => (
      <Text style={{ color: key?.yearToDate > 0 ? "green" : "red" }}>
        {key?.yearToDate.toFixed(2)}%
      </Text>
    ),
  },
  {
    title: "1Y",
    dataIndex: "attributes",
    key: "oneYear",
    render: (key) => (
      <Text style={{ color: key?.oneYear > 0 ? "green" : "red" }}>
        {key?.oneYear.toFixed(2)}%
      </Text>
    ),
  },
  {
    title: "3Y",
    dataIndex: "attributes",
    key: "threeYear",
    render: (key) => (
      <Text style={{ color: key?.threeYear > 0 ? "green" : "red" }}>
        {key?.threeYear.toFixed(2)}%
      </Text>
    ),
  },
  {
    title: "5Y",
    dataIndex: "attributes",
    key: "fiveYear",
    render: (key) => (
      <Text style={{ color: key?.fiveYear > 0 ? "green" : "red" }}>
        {key?.fiveYear.toFixed(2)}%
      </Text>
    ),
  },
  {
    title: "Last NAV",
    dataIndex: "attributes",
    key: "lastNav",
    render: (key) => key?.lastNav.toLocaleString("en-US"),
  },
  // {
  //   title: "Review",
  //   dataIndex: "attributes",
  //   key: "review",
  //   render: (key) => (
  //     <Space align="middle" style={{ marginBottom: 0 }}>
  //       <ReactStars
  //         value={key?.review}
  //         count={5}
  //         size={24}
  //         edit={false}
  //         activeColor="#ffd700"
  //         isHalf={true}
  //       />
  //       <Title level={3} style={{ marginBottom: 0, lineHeight: "38px" }}>
  //         {key?.review}
  //       </Title>
  //     </Space>
  //   ),
  // },
];

export const CLIENTS = [
  { name: "Valbury", path: Client0 },
  { name: "Victoria Manajemen Investasi", path: Client1 },
  { name: "Capital AM", path: Client2 },
  { name: "HP AM", path: Client3 },
  { name: "Prospera AM", path: Client4 },
  { name: "Samuel AM", path: Client5 },
  { name: "Simpan AM", path: Client6 },
  { name: "Star AM", path: Client7 },
  { name: "Trimegah Securities", path: Client8 },
  { name: "Pinnacle Investment", path: Client9 },
  { name: "Sinarmas AM", path: Client10 },
  { name: "Shinhan AM", path: Client11 },
  { name: "Insight IM", path: Client12 },
];

export const REKSADANA_PRODUCTS = [
  {
    icon: BarChartSvg,
    image: SliderReksaDana1,
    name: "Reksa Dana Saham",
    detail:
      "Untuk memperoleh peningkatan nilai investasi yang optimal dalam jangka panjang melalui investasi dalam Efek ekuitas yang tercatat di Bursa Efek Indonesia berupa capital gain dan dividen serta meminimalkan risiko melalui alokasi sesuai peraturan yang berlaku pada efek saham dan pasar uang, dengan melihat perubahan dan kondisi pasar.",
  },
  {
    icon: ListChartSvg,
    image: SliderReksaDana2,
    name: "Reksa Dana Campuran",
    detail:
      "Untuk memperoleh pertumbuhan yang optimal dalam jangka panjang dari keuntungan modal, dividen, dan bunga; serta meminimalkan risiko melalui alokasi sesuai peraturan yang berlaku pada efek saham, obligasi, dan pasar uang, dengan melihat perubahan dan kondisi pasar.",
  },
  {
    icon: LockedHouseSvg,
    image: SliderReksaDana3,
    name: "Reksa Dana Pendapatan Tetap ",
    detail:
      "Untuk mempertahankan nilai pokok investasi awal dan untuk memperoleh pendapatan yang optimal berupa kupon (bunga) dan kenaikan nilai pokok dari Efek bersifat utang.",
  },
  {
    icon: ShoppingCartSvg,
    image: SliderReksaDana4,
    name: "Reksa Dana Pasar Uang",
    detail:
      "Untuk memperoleh tingkat pendapatan yang optimal, relatif stabil dan bersaing dengan tetap mempertahankan nilai pokok investasi serta menjaga kestabilan likuiditas dengan tingkat resiko yang relatif rendah.",
  },
  {
    icon: LeafsSvg,
    image: SliderReksaDana5,
    name: "Reksa Dana Indeks",
    detail:
      "Untuk memperoleh potensi pertumbuhan nilai investasi yang optimal dalam jangka panjang dengan berinvestasi pada Efek bersifat ekuitas yang terdaftar pada suatu Indeks saham di Bursa Efek Indonesia yang menjadi acuan.",
  },
];

export const FEATURES = [
  {
    background: BackgroundRadialBlue,
    image: BasedProfile,
    icon: RobotSvg,
    name: "Based Your Profile",
    detail:
      "Rekomendasi Reksa Dana yang sesuai dengan Profil Risiko Anda Tentukan dana yang ingin diinvestasikan pada produk reksa dana dan dalam berapa tahun tujuan keuangan Anda tsb akan dicapai",
  },
  // {
  //   background: BackgroundRadialGreen,
  //   image: InvestmentGoal,
  //   icon: FlagsSvg,
  //   name: "Investment Goal",
  //   detail:
  //     "Tetapkan berapa banyak uang yang ingin Anda investasikan ke reksadana dan berapa lama Anda ingin mencapai tujuan keuangan Anda.",
  // },
];

export const FAQ_CONTENTS = [
  {
    id: "1",
    label: "Apakah saya bisa memindahkan saham dari sekuritas lain ke SFAST?",
    children: (
      <p>
        Tentu saja bisa, kamu dapat melakukan perpindahan dengan cara mengajukan
        ke pihak sekuritas asal dan mengisi formulir kuasa perpindahan.
        Sekuritas asal akan mengenakan biaya sesuai kebijakan masing-masing.
      </p>
    ),
  },
  {
    id: "2",
    label: "Bagaimana jika akun saya dinonaktifkan?",
    children: (
      <p>
        Tentu saja bisa, kamu dapat melakukan perpindahan dengan cara mengajukan
        ke pihak sekuritas asal dan mengisi formulir kuasa perpindahan.
        Sekuritas asal akan mengenakan biaya sesuai kebijakan masing-masing.
      </p>
    ),
  },
  {
    id: "3",
    label: "Bagaimana jika saya lupa PIN?",
    children: (
      <p>
        Hubungi customer service SFAST segera untuk proses pembaruan PIN. Harap
        diingat bahwa kamu tidak bisa bertransaksi saham tanpa PIN.
      </p>
    ),
  },
  {
    id: "4",
    label: "Kenapa pembukaan akun saham saya gagal?",
    children: (
      <>
        <p>Beberapa penyebab gagalnya pembukaan akun saham:</p>
        <p style={{ fontWeight: 700, marginBottom: 0 }}>Dormant</p>
        <p>
          Kondisi ini dikarenakan Anda pernah atau masih menjadi nasabah Permata
          Bank dan status rekening Anda saat ini tidak aktif atau pasif. Untuk
          dapat melanjutkan proses pembukaan RDN nasabah dapat langsung ke Bank
          Permata untuk menghapus status Dormant.
        </p>

        <p style={{ fontWeight: 700, marginBottom: 0 }}>KTP Buram</p>
        <p>
          Kondisi ini dikarenakan fisik KTP anda tidak terbaca akibat rusak atau
          mengalami pemudaran, dan dapat diperbaharui terlebih dahulu ke Kantor
          Kelurahan Anda.
        </p>

        <p style={{ fontWeight: 700, marginBottom: 0 }}>
          Data DUKCAPIL Berbeda
        </p>
        <p>
          Kondisi ini dikarenakan data fisik KTP Anda berbeda dengan Pusat Data
          DUKCAPIL dan perlu penyesuaian langsung ke pihak DUKCAPIL.
        </p>

        <p style={{ fontWeight: 700, marginBottom: 0 }}>NIK tidak terbaca</p>
        <p>
          Kondisi ini dikarenakan NIK yang tercantum pada fisik KTP anda tidak
          terdaftar di Pusat Data DUKCAPIL dan perlu penyesuaian langsung ke
          pihak DUKCAPIL.
        </p>

        <p style={{ fontWeight: 700, marginBottom: 0 }}>ETB status</p>
        <p>
          Kondisi ini dikarenakan Anda pernah memiliki rekening atau produk Bank
          Permata, atau pernah melakukan registrasi yang terkait dengan Bank
          Permata namun data tidak lengkap sehingga saat proses pembukaan
          rekening RDN secara online pada aplikasi terjadi kegagalan dan
          pembukaan RDN harus dilakukan secara manual.
        </p>
      </>
    ),
  },
];
