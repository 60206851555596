import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Product5Image from "../../Assets/Images/Product/product-5.png";
import ProductMobile5Image from "../../Assets/Images/Product/product-mobile-5.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const SfastApp = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProductMobile5Image : Product5Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">SFAST App</Text>
        <Text className="body">
          <p>
            SFAST App adalah Smart App untuk investasi dan salah satu produk
            aplikasi dari PT Surya Fajar Sekuritas. Dilengkapi dengan berbagai
            fitur ciamik, investor akan dengan mudah melakukan transaksi
            jual-beli saham dan reksa dana hanya dengan satu genggaman. Dapatkan
            aplikasi SFAST melalui Apps Store dan GooglePlay atau langsung
            kunjungi situs website{" "}
            <a href="https://sfast.id/" target="_blank" rel="noreferrer">
              https://sfast.id/
            </a>{" "}
            .
          </p>
          <p>
            Untuk informasi dan pertanyaan lebih lanjut dapat menghubungi kami
            melalui email{" "}
            <a href="mailto:cs@sfsekuritas.co.id">
              <b>cs@sfsekuritas.co.id</b>
            </a>
          </p>
        </Text>
      </Col>
    </Row>
  );
};

export default SfastApp;
