import { Button, Carousel, Image, Space, Typography } from "antd";
import React, { createRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider1 from "../../Assets/Images/Slider/slide-1.png";
import Slider2 from "../../Assets/Images/Slider/slide-2.png";
import Slider3 from "../../Assets/Images/Slider/slide-3.png";
import Slider4 from "../../Assets/Images/Slider/slide-4.png";
import Slider5 from "../../Assets/Images/Slider/slide-6.png";
import Slider7 from "../../Assets/Images/Slider/slide-7.jpg";
import Slider8 from "../../Assets/Images/Slider/slide-8.jpg";
import Slider9 from "../../Assets/Images/Slider/slide-9.jpg";
import "../../Assets/Css/Hero.css";
import SocialMedia from "../Global/SocialMedia";

const { Text } = Typography;

const Hero = () => {
  const slider = createRef();

  const gotoNext = () => {
    slider.current.next();
  };

  const gotoPrev = () => {
    slider.current.prev();
  };

  return (
    <div className="slider">
      <Carousel ref={slider} className="hero" dotPosition={"top"} autoplay={"true"}>
      < div className="hero-container">
          <Image preview={false} src={Slider9} />
          <div className="hero-overlay">
            <div>
              {/* <Text>Meriahkan Hari Sumpah  Pemuda, SFAST Adakan <br /> Give Away</Text> */}
            </div>
            {/* <a
              href={"https://www.sfsekuritas.co.id/kegiatan/detail/16"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Lihat Detil</Button>
            </a> */}
          </div>
        </div>
        <div className="hero-container">
          <Image preview={false} src={Slider8} />
          <div className="hero-overlay">
            <div>
              {/* <Text>Meriahkan Hari Sumpah  Pemuda, SFAST Adakan <br /> Give Away</Text> */}
            </div>
            {/* <a
              href={"https://www.sfsekuritas.co.id/kegiatan/detail/16"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Lihat Detil</Button>
            </a> */}
          </div>
        </div>
        <div className="hero-container">
          <Image preview={false} src={Slider5} />
          <div className="hero-overlay">
            <div>
              <Text>Melahirkan Investor Handal Di Pasar Modal</Text>
            </div>
            <a
              href={"https://www.sfsekuritas.co.id/produk-layanan/investor-incubator"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Lihat Detil</Button>
            </a>
          </div>
        </div>
        <div className="hero-container">
          <Image preview={false} src={Slider1} />
          <div className="hero-overlay">
            <div>
              <Text>Professional and Trusted investment Associate</Text>
            </div>
            <a
              href={"https://registration.sfsekuritas.co.id/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Buka Rekening</Button>
            </a>
          </div>
        </div>
        <div className="hero-container">
          <Image preview={false} src={Slider2} />
          <div className="hero-overlay">
            <div>
              <Text>Professional and Trusted investment Associate</Text>
            </div>
            <a
              href={"https://registration.sfsekuritas.co.id/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Buka Rekening</Button>
            </a>
          </div>
        </div>
        <div className="hero-container">
          <Image preview={false} src={Slider3} />
          <div className="hero-overlay">
            <div>
              <Text>Professional and Trusted investment Associate</Text>
            </div>
            <a
              href={"https://registration.sfsekuritas.co.id/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Buka Rekening</Button>
            </a>
          </div>
        </div>
        <div className="hero-container">
          <Image preview={false} src={Slider4} />
          <div className="hero-overlay">
            <div>
              <Text>Smart App Untuk Investasi (SFAST)</Text>
            </div>
            <a
              href={"https://registration.sfsekuritas.co.id/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="button-header">Buka Rekening</Button>
            </a>
          </div>
        </div>

        {/* <div className="hero-container">
          <Image preview={false} src={Slider5} />
          <div className="hero-overlay">
            <div>
              <Text>DIRGARHAYU <br/>REPUBLIK INDONESIA 🇮🇩</Text>
            </div>
          </div>
        </div> */}
      </Carousel>
      <div className="buttonAction">
        <i className="fa fa-arrow-left-long" onClick={() => gotoPrev()}></i>
        <i className="fa fa-arrow-right-long" onClick={() => gotoNext()}></i>
      </div>
      <SocialMedia />
    </div>
  );
};

export default Hero;
