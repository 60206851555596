import { Card, Col, Image, Row, Typography } from "antd";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import { Controller } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../Assets/Css/Inter.css";
import "../../Assets/Css/PaginationBullet.css";
import "../../Assets/Css/SinglePage.css";
import Profile2Image from "../../Assets/Images/Profile/profile-2.png";
import ProfileMobile2Image from "../../Assets/Images/Profile/profile-mobile-2.png";
import HistoryCard from "./HistoryCard";

const { Text } = Typography;
const data = [
  {
    year: "2018",
    description: [
      {
        month: "Januari",
        label:
          "SF Sekuritas didirikan sebagai perusahaan yang bergerak dalam bidang <b>Perantara Perdagangan Efek dan Penjamin Emisi</b>.",
      },
      {
        month: "Februari",
        label:
          "<b>Mendapatkan izin</b> Perantara Perdagangan Efek <b>(KEP-64/D.04/2018)</b> dari OJK.",
      },
      {
        month: "Desember",
        label:
          "Resmi menjadi anggota <b>(SPAB-255/JATS/BEI.ANG/12-2018)</b> dari BEI serta pertama kali melakukan <b>Perdagangan Saham</b> di BEI.",
      },
    ],
  },
  {
    year: "2019",
    description: [
      {
        month: "Mei",
        label:
          "<b>Mendapatkan izin</b> sebagai Partisipan Perdagangan Margin <b>(No. S-02583/BEI.ANG/05-2019)</b> dari BEI.",
      },
      {
        month: "Juni",
        label:
          "Mendapatkan izin <b>Penjamin Emisi (KEP-42/D.04/2019)</b> dari OJK.",
      },
      {
        month: "November",
        type: "flag",
        label:
          "Pertama kali bertindak sebagai <b>Penjamin Pelaksana Emisi Efek dalam proses Penawaran Umum Saham Perdana (IPO)</b> PT Dana Brata Luhur Tbk. <b>(TEBE)</b>.",
      },
      {
        month: "Desember",
        label:
          "Mendapatkan Persetujuan Implementasi Pemesanan secara langsung <b>(Online Trading)</b>. <b>(S-01105/BEI.ANG/02-2020) dari BEI</b> .",
      },
    ],
  },
  {
    year: "2020",
    description: [
      {
        month: "Januari",
        type: "flag",
        label:
          "Berhasil melakukan <b>IPO PT Tourindo Guide Indonesia Tbk. (PGJO)</b> dan dinobatkan sebagai Perusahaan Pertama yang <b>terdaftar di Papan Akselerasi</b>.",
      },
      {
        month: "Maret - April",
        type: "flag",
        label:
          "Berhasil melakukan <b>IPO PT Saraswanti Anugerah Makmur Tbk. (SAMF)</b> dan <b>PT Sejahtera Bintang Abadi Textile Tbk. (SBAT)</b>.",
      },
    ],
  },
  {
    year: "2021",
    description: [
      {
        month: "Maret",
        type: "flag",
        label:
          "Berhasil melakukan <b>IPO PT Ulima Nitra Tbk. (UNIQ)</b> dan menjadi perusahaan pertama yang tercatat dalam <b>Penawaran Umum Saham Secara Elektronik Berbasis Web (e-IPO)</b>.",
      },
      {
        month: "Juli",
        type: "flag",
        label:
          "Berhasil melakukan <b>IPO PT Trimegah Karya Utama Tbk. (UVCR)</b> serta mendapatkan izin sebagai Partisipan Sistem Penerima Laporan Transaksi Efek (PLTE) <b>(STDP-172/BEI.ANG/07-2021)</b> dari BEI dan membentuk divisi <i>Fixed Income</i>.",
      },
      {
        month: "Agustus",
        label:
          "Mendapatkan izin sebagai Agen Penjual Efek Reksa Dana <b>(APERD) (No. S-1086/PM.211/2021)</b> dari OJK serta mendapatkan persetujuan <i>live</i> dari BEI untuk <i>Mobile Platform</i> <b>SFAST (S-05838/BEI.ANG/08-2021)</b>.",
      },
      {
        month: "September - Desember",
        type: "flag",
        label:
          "Berhasil melakukan <b>IPO PT Geoprima Solusi Tbk. (GPSO) dan PT Widodo Makmur Perkasa Tbk. (WMPP)</b>.",
      },
    ],
  },
  {
    year: "2022",
    description: [
      {
        month: "Februari - September",
        type: "flag",
        label:
          "Berhasil melakukan <b>IPO PT Nusatama Berkah Tbk. (NTBK), PT Indo Boga Sukses Tbk. (IBOS) dan PT Black Diamond Resources Tbk. (COAL)</b>.",
      },
      {
        month: "December",
        type: "flag",
        label:
          "Berhasil melakukan <b> IPO PT Venteny Fortuna International Tbk. (VNTY),</b> Launching <b>SFAST dan Program SOLUSI.</b>",
      },
    ],
  },
  {
    year: "2023",
    description: [
      {
        month: "Januari - Februari",
        type: "flag",
        label:
          "Berhasil melakukan <b> IPO PT Hassana Boga Sejahtera Tbk. (NAYZ), IPO PT Teknologi Karya Digital Nusa Tbk. (TRON) & IPO PT Arsy Buana Travelindo Tbk. (HAJJ)</b>",
      },
      {
        month: "Maret",
        label:
          "Berhasil melakukan Program Solusi di <b> STIE YKPN & UPH </b> berserta Seminar <b> GO PUBLIC & COACHING CLINIC. </b>",
      },
    ],
  },
  {
    year: "2024",
    description: [
      {
        month: "Januari - Maret",
        type: "flag",
        label: "Berhasil melakukan <b>IPO PT Satu Visi Putra Tbk. (VISI)</b>",
      },
      {
        month: "Januari - Maret",
        label:
          "Resmi membuka <b>Galeri Investasi BEI di Universitas Singaperbangsa Karawang (Unsika)</b>",
      },
    ],
  },
];

const History = () => {
  const [active, setActive] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const handleOnMobileSlideChange = useCallback(
    (swiper) => {
      if (!isMobile) return;
      setActive(swiper.activeIndex);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isMobile],
  );

  useEffect(() => {
    if (!active) return;
    if (controlledSwiper) {
      controlledSwiper.slideTo(active);
    }
  }, [controlledSwiper, active]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProfileMobile2Image : Profile2Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">Sejarah</Text>
        <Text className="body">
          <p>
            PT Surya Fajar Sekuritas ("SF Sekuritas"), berkedudukan di Kota
            Jakarta, didirikan berdasarkan Akta Pendirian No. 47, tanggal 18
            Januari 2018 ("Akta Pendirian SF Sekuritas"), dibuat di hadapan
            Humberg Lie, S.H., S,E., M.Kn., Notaris di Jakarta.
          </p>
          <p>
            Akta Pendirian SF Sekuritas telah memperoleh pengesahan Menteri
            Hukum dan Hak Asasi Manusia ("Menkumham") dalam Surat Keputusan No.
            AHU-0003543.AH.01.01.
          </p>
          <p>
            Tahun 2018 tanggal 24 Januari 2018 dan telah dicatat dalam Daftar
            Perseroan No. AHU-0010075.AH.01.11.Tahun 2018 tanggal 24 Januari
            2018.
          </p>
          <p>
            Berdasarkan ketentuan Pasal 3 Anggaran Dasar SF Sekuritas, maksud
            dan tujuan SF Sekuritas ialah berusaha dalam bidang Perantara
            Pedagang Efek dan Penjamin Emisi Efek.
          </p>
        </Text>
        <Card className="card card-jejak-digital">
          <Row>
            <Text className="h2">Jejak Digital SF Sekuritas </Text>
          </Row>
          <Row>
            <Swiper
              style={{
                width: "100%",
                position: "relative",
              }}
              allowTouchMove={isMobile}
              spaceBetween={0}
              freeMode={true}
              slidesPerView={3.2}
              centeredSlides
              centeredSlidesBounds
              onSlideChange={(swiper) => handleOnMobileSlideChange(swiper)}
              onSwiper={setControlledSwiper}
              modules={[Controller]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 3,
                  initialSlide: 0,
                },
                1200: {
                  slidesPerView: 3,
                  initialSlide: 0,
                },
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 5,
                  backgroundColor: "#e8edf1",
                  top: 56,
                }}
              ></div>
              {data.map((item, index) => (
                <SwiperSlide key={`data-${index}`}>
                  <CustomCard
                    data={item}
                    isActive={index === active}
                    handleBulletClick={() => {
                      setActive(index);
                    }}
                    isProgressFull={active >= index}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const CustomCard = ({ data, isActive, handleBulletClick, isProgressFull }) => {
  const { year, description } = data;

  return (
    <div
      style={{
        position: "relative",
        minWidth: 0,
        display: "flex",
        flexFlow: "row wrap",
        flexDirection: "column",
        minHeight: 400,
        paddingTop: 82,
      }}
    >
      <motion.div
        id="progressBar"
        initial={{ width: 0 }}
        animate={isProgressFull ? { width: "100%" } : { width: 0 }}
        transition={{ duration: 1 }}
        style={{
          position: "absolute",
          height: 5,
          background: "#276ef1",
          top: 56,
          left: 25,
          transformOrigin: "right",
        }}
      />
      <div
        id="Bullet"
        onClick={handleBulletClick}
        style={{
          position: "absolute",
          top: 46,
          backgroundColor: "#fff",
          border: "6px solid #276ef1",
          width: 25,
          height: 25,
          borderRadius: 99,
          cursor: "pointer",
        }}
      />
      <time
        onClick={handleBulletClick}
        style={{
          position: "absolute",
          top: 0,
          fontWeight: "700",
          fontSize: "28px",
          fontFamily: "Inter",
          cursor: "pointer",
          lineHeight: "36px",
          letterSpacing: "-2px",
          marginBottom: 16,
        }}
      >
        {year}
      </time>

      {isActive && <HistoryCard data={description} year={year} />}
    </div>
  );
};

export default History;
