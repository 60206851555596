import { Button, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Assets/Css/PageHeader.css";
import Section2Image from "../../Assets/Images/LandingPage/section4-2.png";
import News1Image from "../../Assets/Images/News/news-1.png";

const { Text } = Typography;

function PageHeader(props) {
  const navigate = useNavigate();

  useEffect(() => {
    setActive(props.path);
  }, [props.path]);

  const [active, setActive] = useState("");

  return (
    <div className="page-header">
      <Text className="h1">{props.title}</Text>
      <Space size={"small"} className={"container"}>
        {props?.menu &&
          props?.menu.map((item, index) => (
            <Button
              key={index}
              className={`btn-primary ${active === (item.link?.split("?")[0]) && "active"}`}
              onClick={() => {
                navigate(item.link);
              }}
            >
              {item.label}
            </Button>
          ))}
      </Space>
      <div className="image-animation">
        <img alt="background" className="background" src={Section2Image} />
        {props.type === "news" && (
          <img alt="news" className="news" src={News1Image} />
        )}
      </div>
    </div>
  );
}

export default PageHeader;
