import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import "../../Assets/Css/Inter.css";
import "../../Assets/Css/SinglePage.css";
import Product3Image from "../../Assets/Images/Product/product-3.png";
import ProductMobile3Image from "../../Assets/Images/Product/product-mobile-3.png";
import ServiceContext from "../../Services";
import { TABLE_COLUMN_CONFIGS } from "./ReksaDana/const";
import ReksadanaDevicesImage from "../../Assets/Images/Product/reksadana-devices.png";
import ClientSlider from "./ReksaDana/ClientSlider";
import ReksadanaSlider from "./ReksaDana/ReksadanaSlider";
import BgRadialBlue from "../../Assets/Images/Product/ReksaDana/BackgroundRadialBlue.png";
import BgRadialGreen from "../../Assets/Images/Product/ReksaDana/BackgroundRadialGreen.png";
import ManWomenPhoto from "../../Assets/Images/Product/ReksaDana/ManWomenPhoto.png";
import BrightnessAlertIcon from "../../Assets/Icons/ReksaDana/BrightnessAlert.svg";
import BalanceWalletIcon from "../../Assets/Icons/ReksaDana/BalanceWallet.svg";
import PaymentsIcon from "../../Assets/Icons/ReksaDana/Payments.svg";
import FeatureSlider from "./ReksaDana/FeatureSlider";
import FAQSection from "./ReksaDana/FAQSection";

const { Text, Title } = Typography;

const ReksaDana = () => {
  const { getAllService } = useContext(ServiceContext);
  const [path, setPath] = useState("");
  const [jenisSelected, setJenisSelected] = useState([null]);
  const [orderSelected, setOrderSelected] = useState("id");
  const [periodSelected, setPeriodSelected] = useState("");
  const [returnSelected, setReturnSelected] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const plainOption1 = [
    {
      label: "Semua",
      value: null,
    },
    {
      label: "Pasar Uang",
      value: "Pasar Uang",
    },
    {
      label: "Pendapatan Tetap",
      value: "Pendapatan Tetap",
    },
  ];

  const plainOption2 = [
    {
      label: "Saham",
      value: "Saham",
    },
    {
      label: "Campuran",
      value: "Campuran",
    },
    {
      label: "Indeks",
      value: "Indeks",
    },
  ];

  const [pagination, setPagination] = useState({
    sort: ["id:asc"],
    "pagination[page]": 1,
    "pagination[pageSize]": 10,
    "filters[reksadana][$contains]": "",
    "filters[jenis][$in]": [],
  });

  const handleChange = (paginations) => {
    let pageSize = paginations.pageSize;

    if (paginations.pageSize === "All") {
      pageSize = 999999;
    }

    setPagination({
      ...pagination,
      "pagination[page]": paginations.current,
      "pagination[pageSize]": pageSize,
    });
  };

  const getBerita = async (page) => {
    const data = await getAllService("reksa-danas", page);
    if (data) {
      setData(data);
    }
  };

  const changeFilter = () => {
    const newPage = pagination;
    if (periodSelected !== "") {
      const name = "filters[$and][0][" + periodSelected + "][$gte]";
      setPagination({
        sort: newPage["sort"],
        "pagination[page]": newPage["pagination[page]"],
        "pagination[pageSize]": newPage["pagination[pageSize]"],
        "filters[reksadana][$contains]":
          newPage["filters[reksadana][$contains]"],
        "filters[jenis][$in]": newPage["filters[jenis][$in]"],
        [name]: returnSelected,
      });
    } else {
      setPagination({
        sort: newPage["sort"],
        "pagination[page]": newPage["pagination[page]"],
        "pagination[pageSize]": newPage["pagination[pageSize]"],
        "filters[reksadana][$contains]":
          newPage["filters[reksadana][$contains]"],
        "filters[jenis][$in]": newPage["filters[jenis][$in]"],
        "filters[$or][0][oneMonth][$gte]": returnSelected,
        "filters[$or][1][threeMonth][$gte]": returnSelected,
        "filters[$or][2][yearToDate][$gte]": returnSelected,
        "filters[$or][3][oneYear][$gte]": returnSelected,
        "filters[$or][4][threeYear][$gte]": returnSelected,
        "filters[$or][5][fiveYear][$gte]": returnSelected,
      });
    }
  };

  const onChangeJenis = (evt) => {
    let oldJenis = jenisSelected;
    if (evt?.target?.checked) {
      oldJenis.push(evt?.target?.value);
    } else {
      var index = oldJenis.indexOf(evt?.target?.value);
      if (index !== -1) {
        oldJenis.splice(index, 1);
      }
    }
    if (evt?.target?.value) {
      var index2 = oldJenis.indexOf(null);
      if (index2 !== -1) {
        oldJenis.splice(index2, 1);
      }
    } else {
      oldJenis = [null];
    }
    setJenisSelected(oldJenis);
    setPagination({
      ...pagination,
      "filters[jenis][$in]": oldJenis,
    });
  };

  useEffect(() => {
    getBerita(pagination);
  }, [pagination]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [path]);

  useEffect(() => {
    changeFilter();
  }, [returnSelected, periodSelected]);

  return (
    <div className="reksa-dana">
      <Space className="reksadana-content-selector">
        <Button className={path === "" && "active"} onClick={() => setPath("")}>
          Sekilas Reksa Dana
        </Button>
        <Button
          className={path === "produk" && "active"}
          onClick={() => setPath("produk")}
        >
          Daftar Produk Reksa Dana
        </Button>
      </Space>

      {path === "" ? (
        <Row className="reksa-dana-layout" justify={"space-between"}>
          <Col span={24}>
            <div className="img-section">
              <Image
                className="img-rounded"
                preview={false}
                src={
                  window.innerWidth < 768 ? ProductMobile3Image : Product3Image
                }
              />
            </div>
          </Col>

          <Col span={24}>
            <section className="cta-section">
              <div className="copywriting">
                <Title className="cta-title">
                  Mulai Investasi Anda dengan Reksa Dana
                </Title>
                <Text className="cta-text">
                  Reksa Dana adalah Pilihan Bijak untuk Memulai Investasi dengan{" "}
                  <strong>
                    keterbatasan pengetahuan investasi, dana dan juga waktu.
                  </strong>
                </Text>
              </div>
              <div
                className="image-wrapper"
                style={{ backgroundImage: `url(${BgRadialBlue})` }}
              >
                <Image
                  src={ReksadanaDevicesImage}
                  alt="reksadana-app"
                  preview={false}
                />
              </div>
            </section>
          </Col>

          <Col span={24}>
            <section
              className="reksadana-section"
              style={{ backgroundImage: `url(${BgRadialBlue})` }}
            >
              <div className="products">
                <div className="typography">
                  <Title
                    className="h2 medium-text-align-center"
                    style={{
                      margin: 0,
                      lineHeight: "1.4",
                    }}
                  >
                    Satu Akun, Banyak Pilihan Aset
                  </Title>
                  <Text
                    className="medium-text-align-center"
                    style={{
                      color: "#4F5E71",
                      fontSize: 20,
                      lineHeight: "1.5",
                    }}
                  >
                    Diversifikasi Investasi Anda dalam Satu Aplikasi yang Mudah
                  </Text>
                </div>
                <ReksadanaSlider />
              </div>
              <div className="clients">
                <ClientSlider />
              </div>
            </section>
          </Col>

          <Col span={24}>
            <section className="cta-section">
              <div
                className="image-wrapper image-wrapper--green"
                style={{ backgroundImage: `url(${BgRadialGreen})` }}
              >
                <Image
                  src={ManWomenPhoto}
                  alt="man-and-women-reksadana"
                  preview={false}
                />
              </div>
              <div className="copywriting">
                <Title className="h2" style={{ margin: 0, lineHeight: 1.5 }}>
                  Memulai Investasi dengan Reksa Dana.
                </Title>
                <Text className="cta-text">
                  SF Sekuritas adalah Agen Penjual Efek Reksa Dana (APERD)
                  berlisensi OJK (No. S-1086/PM.211/2021)
                </Text>
              </div>
            </section>
          </Col>

          <Col span={24}>
            <section className="benefits-section" align="center">
              <div className="typography">
                <Title
                  className="h2 text-align-center"
                  style={{ margin: 0, lineHeight: 1.5 }}
                >
                  Keunggulan Investasi Reksa Dana di SF Sekuritas
                </Title>
                <Text
                  className="cta-text cta-text--full-width text-align-center"
                  style={{ display: "block", width: "100%" }}
                >
                  Temukan Kemudahan Memulai Investasi Tanpa Batas dengan SF
                  Sekuritas
                </Text>
              </div>
              <div className="container">
                <div className="benefit-card">
                  <Image
                    src={BrightnessAlertIcon}
                    preview={false}
                    alt="Bebas Biaya Pembelian Unit Penyertaan!"
                  />
                  <Title className="h3 text-align-center">
                    Bebas Biaya Pembelian Unit Penyertaan!
                  </Title>
                  <Text className="cta-text" align="center">
                    Bebas Biaya Penjualan Kembali Unit Penyertaan
                  </Text>
                </div>

                <div className="benefit-card">
                  <Image
                    src={BalanceWalletIcon}
                    preview={false}
                    alt="Fleksibel Cairkan Dana Pada Hari Kerja"
                  />
                  <Title className="h3 text-align-center">
                    Fleksibel Cairkan Dana Pada Hari Kerja
                  </Title>
                  <Text className="cta-text" align="center">
                    Cairkan dana investasi kapan saja sesuai kebutuhanmu, tanpa
                    biaya penjualan kembali.
                  </Text>
                </div>

                <div className="benefit-card">
                  <Image
                    src={PaymentsIcon}
                    preview={false}
                    alt="Investasi Mulai Rp100.000"
                  />
                  <Title className="h3 text-align-center">
                    Investasi Mulai Rp100.000
                  </Title>
                  <Text className="cta-text" align="center">
                    Mulai dari Rp 100.000 (Seratus ribu) saja
                  </Text>
                </div>
              </div>
            </section>
          </Col>

          <Col span={24}>
            <section className="benefits-section" align="center">
              <div className="typography">
                <Title
                  className="h2 text-align-center"
                  style={{ margin: 0, lineHeight: 1.5 }}
                >
                  Mulai Investasi Tanpa Batas dengan SF Sekuritas
                </Title>
                <Text
                  className="cta-text cta-text--full-width text-align-center"
                  style={{ display: "block", width: "100%" }}
                >
                  SFAST memudahkan Investor Pemula Berinvestasi
                </Text>
              </div>

              <FeatureSlider />
            </section>
          </Col>

          <Col span={24}>
            <section
              className="data-section"
              style={{ backgroundImage: `url(${BgRadialGreen})` }}
            >
              <Title
                className="h2 text-align-center"
                style={{ margin: 0, lineHeight: "1.4" }}
              >
                Eksplorasi Dunia Reksa Dana Bersama Kami
              </Title>
              <Text
                className="cta-text cta-text--full-width text-align-center"
                style={{
                  display: "block",
                  width: "100%",
                  fontSize: 18,
                  marginTop: -24,
                }}
              >
                Temukan Reksa Dana Terbaik untuk Mencapai Tujuan Keuangan Anda
              </Text>

              <div className="table-wrapper">
                <div className="table">
                  <Table
                    className="reksadana-table"
                    bordered
                    rowKey={"id"}
                    columns={TABLE_COLUMN_CONFIGS}
                    dataSource={data?.data?.filter((item, i) => i < 5)}
                    pagination={false}
                  />
                </div>
              </div>

              <Button
                className="reksa-dana-btn"
                onClick={() => setPath("produk")}
              >
                Lihat Semua Reksa Dana
              </Button>
            </section>
          </Col>
          <Col span={24}>
            <FAQSection />
          </Col>
        </Row>
      ) : (
        <>
          <Text className="h2">Daftar Produk Reksa Dana</Text>
          <Text className="body">
            <p>
              Temukan Reksa Dana terbaik untuk memenuhi tujuan keuangan Anda.
            </p>
          </Text>
          <div className="pencarian">
            <Row
              className="group-pencarian h-100"
              justify="center"
              align="middle"
            >
              <Col sm={24} md={12} lg={8} xl={8} className="group-label">
                <div style={{ minHeight: "90px" }}>
                  <div className="label-group">
                    <div>Pilih Jenis Reksa Dana</div>
                  </div>
                  <Row className="" justify="start">
                    {plainOption1?.map((item, index) => (
                      <Col
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        className="label-checkbox"
                      >
                        <Checkbox
                          onChange={(evt) => onChangeJenis(evt)}
                          value={item?.value}
                          checked={jenisSelected?.includes(item?.value)}
                        >
                          {item?.label}
                        </Checkbox>
                      </Col>
                    ))}
                    {plainOption2?.map((item, index) => (
                      <Col
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        className="label-checkbox"
                      >
                        <Checkbox
                          onChange={(evt) => onChangeJenis(evt)}
                          value={item?.value}
                          checked={jenisSelected?.includes(item?.value)}
                        >
                          {item?.label}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col sm={24} md={12} lg={6} xl={6} className="group-label w-100">
                <Row
                  className="h-100"
                  justify="center"
                  align="middle"
                  gutter={[5, 5]}
                  style={{ minHeight: "90px" }}
                >
                  <Col sm={12} md={24} lg={24} xl={12}>
                    <div className="label-group">
                      <div>Periode</div>
                    </div>
                    <Select
                      labelInValue
                      defaultValue={{ value: "", label: "Semua" }}
                      style={{ width: "100%" }}
                      onChange={(evt) => {
                        setPeriodSelected(evt?.value);
                      }}
                      options={[
                        {
                          value: "",
                          label: "Semua",
                        },
                        {
                          value: "oneMonth",
                          label: "1 Month",
                        },
                        {
                          value: "threeMonth",
                          label: "3 Month",
                        },
                        {
                          value: "yearToDate",
                          label: "YTD",
                        },
                        {
                          value: "oneYear",
                          label: "1 Year",
                        },
                        {
                          value: "threeYear",
                          label: "3 Year",
                        },
                        {
                          value: "fiveYear",
                          label: "5 Year",
                        },
                      ]}
                    />
                  </Col>
                  <Col sm={12} md={24} lg={24} xl={12}>
                    <div className="label-group">
                      <div>Return</div>
                    </div>
                    <Select
                      labelInValue
                      defaultValue={{ value: "", label: "Semua" }}
                      style={{ width: "100%" }}
                      onChange={(evt) => {
                        setReturnSelected(evt?.value);
                      }}
                      options={[
                        {
                          value: "",
                          label: "Semua",
                        },
                        {
                          value: "0",
                          label: ">0%",
                        },
                        {
                          value: "5",
                          label: ">5%",
                        },
                        {
                          value: "10",
                          label: ">10%",
                        },
                        {
                          value: "15",
                          label: ">15%",
                        },
                        {
                          value: "20",
                          label: ">20%",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={24} md={12} lg={5} xl={5} className="group-label">
                <div style={{ minHeight: "90px" }}>
                  <div className="label-group">
                    <div>Urutkan Berdasarkan</div>
                  </div>
                  <Radio.Group
                    onChange={(evt) => {
                      setOrderSelected(evt?.target?.value);
                      setPagination({
                        ...pagination,
                        sort: [evt?.target?.value + ":asc"],
                      });
                    }}
                    value={orderSelected}
                  >
                    <Space direction="vertical">
                      <Radio value={"id"}>Tidak diurutkan </Radio>
                      <Radio value={"reksadana"}>Berdasarkan Nama A-Z</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </Col>
              <Col sm={24} md={12} lg={5} xl={5} className="group-search">
                <Input
                  size="large"
                  placeholder="Cari Reksa Dana"
                  prefix={<SearchOutlined />}
                  onChange={(evt) => {
                    setSearch(evt?.target?.value);
                    setPagination({
                      ...pagination,
                      "filters[reksadana][$contains]": evt?.target?.value,
                    });
                  }}
                  value={search}
                />
              </Col>
            </Row>
          </div>
          <div className="table">
            <Table
              className="reksadana-table"
              bordered
              rowKey={"id"}
              onChange={handleChange}
              pagination={{
                total: data?.meta?.pagination?.total,
                pageSize: data?.meta?.pagination?.pageSize,
                showTotal: (total, range) =>
                  `Menampilkan ${range[0]}-${range[1]} dari ${total} item`,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "50",
                  "100",
                  data?.meta?.pagination?.total,
                ],
              }}
              columns={TABLE_COLUMN_CONFIGS}
              dataSource={data?.data}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ReksaDana;
