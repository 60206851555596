import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Product2Image from "../../Assets/Images/Product/product-2.png";
import ProductMobile2Image from "../../Assets/Images/Product/product-mobile-2.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const InvestmentBanking = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProductMobile2Image : Product2Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Row gutter={20} justify={"space-between"}>
          <Col span={24} xl={12}>
            <Text className="h2">Penerbitan Efek (Ekuitas dan Utang)</Text>
            <Text className="body">
              <p>
                Kami dapat membantu perusahaan sebagai penjamin emisi dalam
                mencari pendanaan serta melakukan <b>analisa yang mendalam</b>{" "}
                terhadap seluruh klien kami dalam hal{" "}
                <b>mitigasi risiko keuangan, operasional</b> serta pasar yang
                sesuai untuk mengetahui strategi yang tepat. Kami mengeksekusi
                penempatan publik dan swasta atas berbagai efek - ekuitas, utang
                dan produk terkait.
              </p>
              <p style={{ fontWeight: "bold" }}>
                PT Surya Fajar Sekuritas memberikan layanan berupa:
              </p>
              <p>
                <ol>
                  <li>Penawaran Umum Perdana Saham (IPO)</li>
                  <li>Penawaran Umum Efek Bersifat Utang atau Sukuk</li>
                  <li>
                    Penawaran Terbatas Efek Bersifat Utang atau Sukuk (MTN)
                  </li>
                  <li>Penerbitan HMETD atau Non HMETD</li>
                  <li>Aksi korporasi lainnya</li>
                </ol>
              </p>
            </Text>
          </Col>
          <Col span={24} xl={12}>
            <Text className="h2">Penasihat Keuangan</Text>
            <Text className="body">
              <p>
                Kami dapat membantu klien korporat sebagai penasihat keuangan
                dengan <i>memberikan solusi</i> bagi masalah strategis melalui
                pemberian saran yang objektif.
              </p>
              <p style={{ fontWeight: "bold" }}>
                Kami memberikan layanan sebagai penasihat keuangan, diantaranya:
              </p>
              <p>
                <ol>
                  <li>Transaksi Merger atau Akuisisi</li>
                  <li>Usaha Patungan</li>
                  <li>Restrukturisasi Perusahaan</li>
                  <li>Spin-off</li>
                  <li>Leverage buyout</li>
                </ol>
              </p>
            </Text>
          </Col>
          <Col span={24} xl={12}>
            <Text className="body">
              <p style={{ fontWeight: "bold" }}>
                Kami memiliki{" "}
                <b>izin Penjamin Emisi dari OJK : No. KEP-42/D.04/2019</b>
              </p>
              <p>
                Untuk informasi dan pertanyaan lebih lanjut dapat menghubungi
                kami melalui email{" "}
                <a href="mailto:ib@sfsekuritas.co.id">
                  <b>ib@sfsekuritas.co.id</b>
                </a>
              </p>
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InvestmentBanking;
