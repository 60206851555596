import { Button, Col, Collapse, Image, Row, Typography } from "antd";
import React from "react";
import "../../../Assets/Css/FaqReksaDana.css";
import SFastAppImage from "../../../Assets/Images/Faq/Category 1.png";
import { FAQ_CONTENTS } from "./const";
import IconPlus from "../../../Assets/Icons/plus.svg";
import IconMinus from "../../../Assets/Icons/dash.svg";
import { useNavigate } from "react-router-dom";

const FAQSection = () => {
  const navigate = useNavigate();
  const navigateToFaqPage = () => {
    navigate("/faq");
  };
  return (
    <section className="faq-section">
      <div className="faq-action">
        <Image src={SFastAppImage} alt="Sfast App" preview={false} />
        <Typography.Title className="faq-title">
          Punya Pertanyaan? Kami ada disini
        </Typography.Title>
        <Typography.Paragraph className="faq-text">
          Temukan Jawaban untuk Pertanyaan yang Sering Diajukan tentang Layanan
          SFAST atau Surya Fajar Sekuritas
        </Typography.Paragraph>
        <Button className="faq-button" onClick={navigateToFaqPage}>
          Lihat Semua Pertanyaan
        </Button>
      </div>
      <div className="faq-content">
        <Collapse
          expandIconPosition="end"
          className="faq-collapse"
          bordered={false}
          expandIcon={({ isActive }) =>
            isActive ? (
              <Image src={IconMinus} alt="minus-icon" preview={false} />
            ) : (
              <Image src={IconPlus} alt="plus-icon" preview={false} />
            )
          }
        >
          {FAQ_CONTENTS.map((item, i) => (
            <Collapse.Panel
              header={item.label}
              key={item.id}
              className="faq-collapse-panel"
            >
              {item.children}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </section>
  );
};

export default FAQSection;
