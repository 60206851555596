import React, { useEffect, useRef, useState } from "react";
import { Col, Divider, Image, Row, Space, Switch, Typography } from "antd";
import "../../Assets/Css/Menu.css";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import Modal from "antd/lib/modal/Modal";
import Icon23 from "../../Assets/Icons/menu/23.svg";
import Icon24 from "../../Assets/Icons/menu/24.svg";
import Icon25 from "../../Assets/Icons/menu/25.svg";
import Icon26 from "../../Assets/Icons/menu/26.svg";
import Icon27 from "../../Assets/Icons/menu/27.svg";
import Icon28 from "../../Assets/Icons/menu/28.svg";
import Icon29 from "../../Assets/Icons/menu/29.svg";
import Icon30 from "../../Assets/Icons/menu/30.svg";
import Icon31 from "../../Assets/Icons/menu/31.svg";
import Icon32 from "../../Assets/Icons/menu/32.svg";
import Icon33 from "../../Assets/Icons/menu/33.svg";
import Icon34 from "../../Assets/Icons/menu/34.svg";
import Icon35 from "../../Assets/Icons/menu/35.svg";
import Icon36 from "../../Assets/Icons/menu/36.svg";
import Icon37 from "../../Assets/Icons/menu/37.svg";
import Icon38 from "../../Assets/Icons/menu/38.svg";
import Icon39 from "../../Assets/Icons/menu/39.svg";
import Icon40 from "../../Assets/Icons/menu/40.svg";
import Icon41 from "../../Assets/Icons/menu/41.svg";
import Icon42 from "../../Assets/Icons/menu/42.svg";
import Icon43 from "../../Assets/Icons/menu/43.svg";
import Icon44 from "../../Assets/Icons/menu/44.svg";
import SfastImg from "../../Assets/sfast.png";
import SocialMedia from "../Global/SocialMedia";
import MenuPDF from "../../Assets/Pdf/Userguide-Menu.pdf";

const { Text } = Typography;

const Menu = (props) => {
  const [language, setLanguage] = useState("Indonesia");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const youtube = useRef("");
  const opts = {
    height: "718px",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      props.setActive("");
    } else {
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    youtube.current.resetPlayer();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    youtube.current.internalPlayer.stopVideo();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="nav-header">
      <Row
        style={{ alignItems: "start" }}
        gutter={[10, 0]}
        className={`sfs-menu ${props.active}`}
        ref={menuRef}
      >
        <Col span={24} lg={12} className="border-right">
          <Row>
            <Col span={24}>
              <Text className="menu-header-title">Sekilas sf sekuritas</Text>
              <Row gutter={[0, 10]}>
                <Col span={24} md={12} lg={12} className="border-right">
                  <Space
                    className="menu-container"
                    size={5}
                    direction="vertical"
                  >
                    <Link
                      to="/profil/profil-perusahaan"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon23} />
                          <Text className="menu-title">Profil Perusahaan</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Informasi mengenai perusahaan
                        </Text>
                      </Space>
                    </Link>
                    <Link
                      to="/profil/kenapa-kami"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon27} />
                          <Text className="menu-title">Kenapa Pilih Kami</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Jejak langkah perusahaan
                        </Text>
                      </Space>
                    </Link>
                    <Link
                      to="/profil/sejarah-perusahaan"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon31} />
                          <Text className="menu-title">Sejarah Perusahaan</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Jejak langkah perusahaan
                        </Text>
                      </Space>
                    </Link>
                    <Link
                      to="/profil/tata-kelola-perusahaan"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon35} />
                          <Text className="menu-title">
                            Tata Kelola Perusahaan
                          </Text>
                        </div>
                        <Text className="menu-sub-title">
                          Informasi mengenai perusahaan
                        </Text>
                      </Space>
                    </Link>
                  </Space>
                </Col>
                <Col span={24} md={12} lg={12}>
                  <Space
                    className="menu-container"
                    size={5}
                    direction="vertical"
                  >
                    <Link
                      to="/profil/tim-kami"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon24} />
                          <Text className="menu-title">Tim Kami</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Struktur organisasi perusahaan
                        </Text>
                      </Space>
                    </Link>
                    <Link
                      to="/hubungi-kami"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon28} />
                          <Text className="menu-title">Hubungi Kami</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Kantor pusat dan cabang
                        </Text>
                      </Space>
                    </Link>
                    <Link to="/faq" onClick={() => props.setActive("")}>
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon32} />
                          <Text className="menu-title">FAQ</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Pertanyaan yang sering ditanyakan
                        </Text>
                      </Space>
                    </Link>
                    <Link
                      to="/privacy-policy"
                      onClick={() => props.setActive("")}
                    >
                      <Space
                        size={3}
                        direction="vertical"
                        className="title-menu"
                      >
                        <div className="menu-title-group">
                          <Image preview={false} src={Icon36} />
                          <Text className="menu-title">Privacy Policy</Text>
                        </div>
                        <Text className="menu-sub-title">
                          Syarat dan Ketentuan pengguna
                        </Text>
                      </Space>
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Text className="menu-header-title">Produk & Layanan</Text>
          <Row gutter={[0, 10]}>
            <Col span={24} md={12} lg={12} className="border-right">
              <Space className="menu-container" size={5} direction="vertical">
                <Link
                  to="/produk-layanan/perdagangan-efek"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon25} />
                      <Text className="menu-title">Perdagangan Saham</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Layanan equity trading
                    </Text>
                  </Space>
                </Link>
                <Link
                  to="/produk-layanan/invesment-banking"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon29} />
                      <Text className="menu-title">Investment Banking</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Penjaminan emisi efek saham
                    </Text>
                  </Space>
                </Link>
                <Link
                  to="/produk-layanan/reksa-dana"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon33} />
                      <Text className="menu-title">Reksa Dana (APERD)</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Layanan penjualan efek reksadana
                    </Text>
                  </Space>
                </Link>
                <Link
                  to="/produk-layanan/fixed-income"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon37} />
                      <Text className="menu-title">Fixed Income</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Layanan penjualan instrumen pendapatan
                    </Text>
                  </Space>
                </Link>
              </Space>
            </Col>
            <Col span={24} md={12} lg={12}>
              <Space className="menu-container" size={5} direction="vertical">
                <Link
                  to="/produk-layanan/sfast-app"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon26} />
                      <Text className="menu-title">SFAST App</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Smart App untuk investasi
                    </Text>
                  </Space>
                </Link>
                <Link
                  to="/produk-layanan/research-recommendation"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon30} />
                      <Text className="menu-title">Riset & Rekomendasi</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Analisis report seputar efek dll
                    </Text>
                  </Space>
                </Link>
                <Link
                  to="/produk-layanan/investor-incubator"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon34} />
                      <Text className="menu-title">Investor Incubator</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Melahirkan Investor handal
                    </Text>
                  </Space>
                </Link>
                <a
                  href="https://lms.sfsekuritas.co.id/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon38} />
                      <Text className="menu-title">SF LMS</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Belajar Investasi dari nol
                    </Text>
                  </Space>
                </a>
              </Space>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: "10px 0" }} />
        <Col span={24} lg={12} className="border-right">
          <Text className="menu-header-title">
            RISET & REKOMENDASI HARIAN TERBARU
          </Text>
          <Row gutter={[10, 10]}>
            <Col span={24} md={12} lg={12} className="border-right">
              <Space className="menu-container" size={5} direction="vertical">
                <Link
                  to="/riset/riset?type=riset-harian"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon39} />
                      <Text className="menu-title">Riset</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Laporan riset harian, perusahaan & ulasan
                    </Text>
                  </Space>
                </Link>
              </Space>
            </Col>
            <Col span={24} md={12} lg={12}>
              <Space className="menu-container" size={5} direction="vertical">
                <Link
                  to="/riset/prospektus?type=prospektus-umum"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon40} />
                      <Text className="menu-title">Prospektus</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Profil dan laporan keuangan
                    </Text>
                  </Space>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Text className="menu-header-title">BERITA & PENGUMUMAN TERBARU</Text>
          <Row gutter={[10, 10]}>
            <Col span={24} md={12} lg={12} className="border-right">
              <Space className="menu-container" size={5} direction="vertical">
                <Link to="/berita" onClick={() => props.setActive("")}>
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon41} />
                      <Text className="menu-title">Berita & Pengumuman</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Temukan berita & pengumuman terkini
                    </Text>
                  </Space>
                </Link>
              </Space>
            </Col>
            <Col span={24} md={12} lg={12}>
              <Space className="menu-container" size={5} direction="vertical">
                <Link to="/kegiatan" onClick={() => props.setActive("")}>
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon42} />
                      <Text className="menu-title">Kegiatan</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Informasi mengenai kegiatan dan acara
                    </Text>
                  </Space>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: "10px 0" }} />
        <Col span={24} lg={12}>
          <Text className="menu-header-title">Kenali SFAST APP</Text>
          <Row gutter={[10, 10]}>
            <Col span={24} md={12} lg={12} className="border-right">
              <Space className="menu-container" size={5} direction="vertical">
                <a
                  href={MenuPDF}
                  onClick={() => props.setActive("")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon43} />
                      <Text className="menu-title">
                        User Guide SFAST Mobile App{" "}
                      </Text>
                    </div>
                    <Text className="menu-sub-title">
                      Panduan lengkap aplikasi SFAST Mobile
                    </Text>
                  </Space>
                </a>
              </Space>
            </Col>
            <Col span={24} md={12} lg={12}>
              <Space className="menu-container" size={5} direction="vertical">
                <Link
                  to="/"
                  onClick={() => props.setActive("")}
                >
                  <Space size={3} direction="vertical" className="title-menu">
                    <div className="menu-title-group">
                      <Image preview={false} src={Icon44} />
                      <Text className="menu-title">SFAST Desktop App</Text>
                    </div>
                    <Text className="menu-sub-title">
                      Pengalaman Trading Lebih Profesional
                    </Text>
                  </Space>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Image preview={false} src={SfastImg} />
        </Col>
        <Divider style={{ margin: "10px 0" }} />
        <Col span={24} lg={24}>
          <div className="menu-footer">
            <div>
              Copyright © 2022 PT. Surya Fajar Sekuritas All rights reserved.
            </div>
            <SocialMedia align={"right"} />
          </div>
        </Col>
      </Row>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={"100%"}
        className={"modal-youtube"}
        footer={null}
        closeIcon={null}
      >
        <YouTube ref={youtube} videoId="-4Byh-VBPrc" onEnd={true} opts={opts} />
      </Modal>
    </div>
  );
};

export default Menu;
