import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import { Link } from "react-router-dom";
import ServiceContext from "../../Services";
import moment from "moment";
import "moment/locale/id";
import Pagination from "../../Component/Base/Pagination";
moment.locale("id");

const { Text } = Typography;

function News() {
  const { getAllService } = useContext(ServiceContext);
  const [dataBerita, setDataBerita] = useState([]);
  const [pagination, setPagination] = useState({
    populate: "*",
    sort: ["tanggal:desc"],
    "pagination[page]": 1,
    "pagination[pageSize]": 9,
  });

  const getBerita = async () => {
    const data = await getAllService("beritas", pagination);
    if (data) {
      setDataBerita(data);
    }
  };

  useEffect(() => {
    getBerita();
  }, [pagination]);

  const onChangePagination = (num) => {
    const maxPage = dataBerita?.meta?.pagination?.pageCount;

    if (num > maxPage || num < 1) return;

    setPagination((prev) => ({
      ...prev,
      'pagination[page]': num
    }));
  };

  return (
    <LayoutPage>
      <PageHeader
        title="Berita & Kegiatan Terbaru"
        menu={[
          {
            link: "/berita",
            label: 'Berita & Pengumuman '
          },
          {
            link: "/kegiatan",
            label: 'Kegiatan'
          }
        ]}
        path={`/berita`}
      />

      <div className="layout-single-page">
        <div className="top">
          <section className='sub-heading'>
            <Text className="h3">Berita & Pengumuman</Text>
            <p>Temukan Berita Terkini seputar Surya Fajar Sekuritas dan Pasar Keuangan</p>
          </section>
        </div>
      </div>

      <div className="layout-single-page news">
        <Row gutter={[24, 46]}>
          {dataBerita?.data &&
            dataBerita?.data.map((item, key) => (
              <Col span={24} xl={8} key={key}>
                <Link to={"/berita/detail/"+item?.id}>
                  <Card className="product">
                    {/* <div className="img" style={{ 
                      height: 300, 
                      borderRadius: 12,
                      backgroundPosition: 'center',
                      backgroundImage: `url(${process.env.REACT_APP_FILE_URL + item?.attributes?.gambar?.data?.attributes?.formats?.small?.url})` }}
                    /> */}
                    <img
                      alt="news"
                      src={process.env.REACT_APP_FILE_URL + item?.attributes?.gambar?.data?.attributes?.formats?.small?.url}
                      loading="lazy"
                      preview={false}
                    />
                    <Text className="h5 judul">{item?.attributes?.judul}</Text>
                    <Text className="body">
                      {moment(item?.attributes?.tanggal).format(
                        "dddd, DD MMMM YYYY"
                      )}
                    </Text>
                  </Card>
                </Link>
              </Col>
            ))}
          <Col span={24}>
            <Pagination 
              currentPage={pagination['pagination[page]']} 
              totalPages={dataBerita?.meta?.pagination?.pageCount}
              handlePageChange={onChangePagination}
            />
            {/* <div class="custom-pagination">
              <a href="#" class="prev">
                <i className="fa-solid fa-chevron-left fa-sm"></i>
              </a>
              <ul class="pages">
                <li><a href="#">1</a></li>
                <li className="active"><a href="#">2</a></li>
                <li class="dots">...</li>
                <li><a href="#">7</a></li>
                <li><a href="#">8</a></li>
                <li><a href="#">9</a></li>
              </ul>
              <a href="#" class="next">
              <i className="fa-solid fa-chevron-right fa-sm"></i>
              </a>
            </div> */}
            {/* <div className="pagination">
              {dataBerita?.meta?.pagination?.pageCount > 1 && (
                <Button
                  icon={
                    <span className="fa-stack">
                      <i className="fa-regular fa-circle fa-stack-2x"></i>
                      <i className="fa-solid fa-chevron-left fa-stack-1x"></i>
                    </span>
                  }
                  className="prev"
                  onClick={() => onChangePagination("prev")}
                />
              )}
              <div className="dot">{pageBullet()}</div>
              {dataBerita?.meta?.pagination?.pageCount > 1 && (
                <Button
                  icon={
                    <span className="fa-stack">
                      <i className="fa-regular fa-circle fa-stack-2x"></i>
                      <i className="fa-solid fa-chevron-right fa-stack-1x"></i>
                    </span>
                  }
                  className="next"
                  onClick={() => onChangePagination("next")}
                />
              )}
            </div> */}
          </Col>
        </Row>
      </div>
    </LayoutPage>
  );
}

export default News;
