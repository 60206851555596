import React from "react";
import { Col, Image, Row, Typography } from "antd";
import Profile4Image from "../../Assets/Images/Profile/profile-4.png";
import ProfileMobile4Image from "../../Assets/Images/Profile/profile-mobile-4.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const Governance = () => {
  return (
    <Row gutter={20} justify={"space-between"}>
      <Col span={24}>
        <div className="img-section">
          <Image
            className="img-rounded"
            preview={false}
            src={window.innerWidth < 768 ? ProfileMobile4Image : Profile4Image}
          />
        </div>
      </Col>
      <Col span={24}>
        <Text className="h2">
          GCG (<i>Good Corporate Governance</i>)
        </Text>
        <Text className="body">
          <p>
            Tata Kelola Perusahaan yang Baik (
            <i>Good Corporate Governance/GCG</i>) merupakan dasar bagi aktivitas
            usaha yang transparan dan sehat. SF Sekuritas senantiasa berusaha
            untuk mengedepankan dan mengimplementasikan tata kelola perusahaan
            yang baik yang dilaksanakan sesuai peraturan dan pedoman yang
            berlaku.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Rapat Umum Pemegang Saham</Text>
        <Text className="body">
          <p>
            RUPS merupakan wadah bagi Pemegang Saham untuk mengambil keputusan
            penting berkaitan dengan Perusahaan. RUPS memiliki kewenangan antara
            lain:
          </p>
          <ul>
            <li>Memberikan persetujuan atas Laporan Tahunan</li>
            <li>Menetapkan penggunaan laba Perusahaan</li>
            <li>
              Menunjuk Kantor Akuntan Publik yang akan mengaudit Laporan
              Keuangan Perusahaan
            </li>
            <li>
              Mengangkat dan memberhentikan anggota Dewan Komisaris dan Direksi
            </li>
            <li>
              Mengambil keputusan-keputusan penting lainnya sesuai anggaran
              dasar dan peraturan lainnya yang terkait dengan status atau
              kegiatan usaha Perusahaan
            </li>
          </ul>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Dewan Komisaris</Text>
        <Text className="body">
          <p>
            Dewan Komisaris mempunyai peran yang sangat strategis dalam
            mengawasi jalannya bisnis Perusahaan, untuk memenuhi kepentingan
            Pemegang Saham dan Pemangku Kepentingan lainnya. Sesuai Anggaran
            Dasar, Dewan Komisaris ditugaskan untuk mengawasi pengurusan
            Perseroan yang dilakukan oleh Direksi dan memberikan nasihat kepada
            Direksi. Para anggota Dewan Komisaris diangkat dan diberhentikan
            oleh RUPS untuk jangka waktu 5 (lima) tahun dan dapat diangkat
            kembali apabila masa jabatannya telah berakhir.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Direksi</Text>
        <Text className="body">
          <p>
            Sesuai dengan anggaran dasar, para anggota Direksi diangkat dan
            diberhentikan oleh RUPS untuk jangka waktu 3 (tiga) tahun dan dapat
            diangkat kembali apabila masa jabatannya telah berakhir. Dan dalam
            menjalankan tugasnya, Direksi mempunyai peran yang sangat penting
            dalam mengelola jalannya Perusahaan, sehingga diperlukan suatu
            Standar Pedoman Direksi sebagai landasan dasar bagi Direksi dalam
            melaksanakan tugas tanggung jawab dan wewenangnya untuk memenuhi
            kepentingan Pemegang Saham dan Pemangku Kepentingan lainnya.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Penerapan Manajemen Risiko dan Kepatuhan</Text>
        <Text className="body">
          <p>
            Dalam pengembangan usaha SF Sekuritas, manajemen risiko dan
            kepatuhan akan selalu menjadi bagian yang tidak terpisahkan dari
            setiap aktivitas Perusahaan. Oleh karena itu SF Sekuritas menyusun{" "}
            <i>Risk Management and Compliance Framework</i>. Dimana setiap
            fungsi yang ada di dalam perusahaan mempunyai akuntabilitas untuk
            masing-masing risiko yang ada dan hal ini telah jelas tergambar dari
            struktur organisasi maupun pembagian tugas masing-masing fungsi.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">Kode Etik</Text>
        <Text className="body">
          <p>
            Dalam melaksanakan aktivitas usahanya, Perusahaan berupaya untuk
            menerapkan kode etik dalam setiap tingkatan organisasi. Hal ini
            bertujuan agar lingkungan kerja perusahaan menjadi lingkungan kerja
            yang profesional, mengoptimalkan seluruh potensi karyawan, dan
            memberikan kenyamanan kepada seluruh karyawan dan manajemen dalam
            menjalankan usaha untuk mencapai tujuan perusahaan.
          </p>
        </Text>
      </Col>
      <Col span={24}>
        <Text className="h2">
          <i>Whistle-Blowing System</i>
        </Text>
        <Text className="body">
          <p>
            Sejalan dengan komitmen perusahaan dalam melaksanakan GCG,
            Perusahaan telah mengembangkan kebijakan Whistle-Blowing System
            untuk mendeteksi secara dini pelanggaran di perusahaan yang dapat
            menimbulkan kerugian finansial maupun non finansial, termasuk
            hal-hal yang merusak citra perusahaan. Melalui sistem ini, Perseroan
            dapat mencegah terjadinya pelanggaran dengan pola pengawasan yang
            menyeluruh dan melibatkan seluruh pegawai sehingga memberikan rasa
            aman bagi seluruh pihak yang terkait dengan Perseroan.
          </p>
        </Text>
      </Col>
    </Row>
  );
};

export default Governance;
