import React from "react";
import { Col, Divider, Image, Row, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import Team1Image from "../../Assets/Images/Profile/team-1.png";
import Team2Image from "../../Assets/Images/Profile/team-2.png";
import Team3Image from "../../Assets/Images/Profile/team-3.png";
import Team4Image from "../../Assets/Images/Profile/team-4.png";
import TeamAll from "../../Assets/Images/Profile/team-all.png";
import "../../Assets/Css/SinglePage.css";

const { Text } = Typography;

const Team = () => {
  return (
    <Row gutter={[20, 0]} justify={"space-between"} align={"top"}>
      <Col span={24} style={{ textAlign: "center", marginBottom: "20px" }}>
        <Image src={TeamAll} preview={false} style={{ scale: "1.05 1.05" }} />
      </Col>
      <Col span={24} style={{ textAlign: "left", marginBottom: "20px" }}>
        <Text className="h3" style={{ marginBottom: 0, textAlign: "left" }}>
          Tim Kami
        </Text>
        <Text className="h5">Board of Commisioner and Director</Text>
      </Col>
      <Col span={24} lg={24}>
        <Row gutter={10}>
          <Col span={24} lg={8}>
            <Image src={Team1Image} preview={false} className="team-image" />
          </Col>
          <Col span={24} lg={16}>
            <div className="team-group">
              <Text className="h5" style={{ fontWeight: "600" }}>
                Steffen Fang
              </Text>
              <Text className="h5">Direktur Utama</Text>
              <p>
                Warga Negara Indonesia, berdomisili di Jakarta. Direktur Utama
                Perseroan sejak tahun 2018. Beliau sudah memiliki izin
                perorangan dari OJK sebagai Wakil Perantara Pedagang Efek,
                Wakil Penjamin Emisi Efek dan Wakil Manajer Investasi.
              </p>
              <p>
                Belau menyelesaikan pendidikan di Universitas Indonesia Program Magister Akuntansi. Pernah memegang sejumlah posisi strategis beberapa
                perusahaan diantaranya sebagai Eksekutif Direktur PT Danatama
                Makmur Sekuritas, kemudian melanjutkan karir sebagai Direktur
                Keuangan PT Graha Lotus Bening, lalu sebagai Direktur Utama PT
                Surya Fajar Capital Tbk. Saat in beliau menjabat sebagai
                Direktur Utama PT Surya Fajar Sekuritas.

              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Divider />

      <Col span={24} lg={24}>
        <Row gutter={10}>
          <Col span={24} lg={8}>
            <Image src={Team2Image} preview={false} className="team-image" />
          </Col>
          <Col span={24} lg={16}>
            <div className="team-group">
              <Text className="h5" style={{ fontWeight: "600" }}>
                Hevy Yafanny (Fanny)
              </Text>
              <Text className="h5">Direktur</Text>
              <p>
                Warga Negara Indonesia, berdomisili di Jakarta. Memperoleh gelar
                Sarjana Akuntansi di STIE YKPN tahun 1997.
              </p>
              <p>
                Mengawali karir sebagai Internal Audit PT Astra International
                Tbk pada tahun 1998. Beliau pernah menjabat sebagai{" "}
                <i>Accounting of IB/Corporate Finance Division</i> di PT
                Danatama Makmur. Saat ini beliau menjabat sebagai Direktur di PT
                Surya Fajar Sekuritas.
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Divider />

      <Col span={24} lg={24}>
        <Row gutter={10}>
          <Col span={24} lg={8}>
            <Image src={Team4Image} preview={false} className="team-image" />
          </Col>
          <Col span={24} lg={16}>
            <div className="team-group">
              <Text className="h5" style={{ fontWeight: "600" }}>
              Rustan Tober FOH
              </Text>
              <Text className="h5">Direktur</Text>
              <p>
              Warga Negara Indonesia, berdomisili di Jakarta. Beliau menyelesaikan pendidikannya di Unika Atma Jaya Program Magister Manajemen. Mengawali karir di PT Sinarmas Sekuritas sebagai Floor Trader, jabatan terakhir adalah Corporate Finance Manager. Kemudian melanjutkan karir di PT Aldiracita Sekuritas Indonesia. Saat ini beliau menjabat sebagai Direktur PT Surya Fajar Sekuritas.
              </p>
              <p>
              Beliau sudah memiliki izin perorangan dari Otoritas Jasa Keuangan (OJK) sebagai Wakil Perantara Pedagang Efek (WPPE) dan Wakil Penjamin Emisi Efek (WPEE).
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Divider />

      <Col span={24} lg={24}>
        <Row gutter={10}>
          <Col span={24} lg={8}>
            <Image src={Team3Image} preview={false} className="team-image" />
          </Col>
          <Col span={24} lg={16}>
            <div className="team-group">
              <Text className="h5" style={{ fontWeight: "600" }}>
                Drs. Mohammad Raylan, MM
              </Text>
              <Text className="h5">Komisaris Independen</Text>
              <p>
                Warga Negara Indonesia, Lahir di Medan pada tahun 1957, saat ini
                berdomisili di Jakarta Selatan. Memperoleh Gelar Sarjana dari
                Universitas Krisnadwipayana jurusan Ekonomi Manajemen Tahun
                1985, serta gelar Magister Manajemen dari Universitas Satyagama
                Tahun 2006. Berpengalaman lebih dari 38 tahun sebagai pengawas
                di pasar modal. Memulai karir di Otoritas Jasa Keuangan (OJK)
                sebagai Pelaksana pada Bagian Umum, Sekretariat, Badan Pelaksana
                Pasar Modal Tahun 1978, serta pernah menjabat sebagai Kepala
                Bagian Pemeriksaan dan Penyidikan Emiten dan Perusahaan Publik
                Sektor Riil, Biro Pemeriksaan dan Penyidikan Badan Pengawas
                Pasar Modal dan Lembaga Keuangan Tahun 2006 serta sebagai Deputi
                Direktur Pemantauan Perusahaan Sektor Jasa Direktorat Penilaian
                Keuangan Perusahaan Sektor Jasa Departemen Pengawasan Pasar
                Modal 2B Otoritas Jasa Keuangan (OJK) Tahun 2014, dan sebagai
                Direktur Penilaian Keuangan Perusahaan Sektor Jasa, Departemen
                Pengawasan Pasar Modal 2B Otoritas Jasa Keuangan (OJK) Tahun
                2016.
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Team;
