import React, { useState } from 'react';


export default function Pagination({ totalPages, currentPage, handlePageChange }) {
  const [ellipsis, setEllipsis] = useState(true);

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    if (totalPages > 5) {
      const visiblePages = ellipsis ? [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages] : pages;
      return (
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          {visiblePages.map((page, index) => (
            <span
              key={index}
              className={currentPage === page ? 'active' : ''}
              onClick={() => {
                if (page !== '...') {
                  handlePageChange(page);
                } else {
                  setEllipsis(!ellipsis);
                }
              }}
            >
              {page}
            </span>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
      );
    } else {
      return (
        <div className="custom-pagination">
          <a 
            className='prev' 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
          >
            <i class="fa-solid fa-chevron-left fa-sm"></i>
          </a>
          <ul className='pages'>
            {pages.map((page) => (
              <li className={currentPage === page ? 'active' : ''}>
                <a onClick={() => handlePageChange(page)} >{page}</a>
              </li>
            ))}
          </ul>
          <a 
            className='prev' 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
          >
            <i class="fa-solid fa-chevron-right fa-sm"></i>
          </a>
        </div>
      );
    }
  };


  return (
    <>
      {renderPagination()}
    </>
  )
}
