import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Row,
  Space,
  Typography,
} from "antd";
import React, { createRef, useState } from "react";
import { Link } from "react-router-dom";
import Section1Image from "../../Assets/Images/LandingPage/section2-1.png";
import Section2Image from "../../Assets/Images/LandingPage/section2-2.png";
import Section3Image from "../../Assets/Images/LandingPage/section2-3.png";
import Section4Image from "../../Assets/Images/LandingPage/section2-4.png";
import Section5Image from "../../Assets/Images/LandingPage/section2-5.png";
import Section6Image from "../../Assets/Images/LandingPage/section2-6.png";
import "../../Assets/Css/Section2.css";

const { Text } = Typography;

const Section2 = () => {
  const slider = createRef();

  const [data, setData] = useState([
    {
      label: "Perdagangan Saham",
      description:
        "Kami berkomitmen untuk memberikan pelayanan yang terbaik dalam menemukan solusi kebutuhan investasi yang beragam.",
      image: Section1Image,
      link: "/produk-layanan/perdagangan-efek",
    },
    {
      label: "Investment Banking",
      description:
        "Sebagai penjamin emisi dan penasihat keuangan, kami memberikan solusi keuangan serta merancang strategi yang kompeten untuk kebutuhan perkembangan bisnis klien.",
      image: Section2Image,
      link: "/produk-layanan/invesment-banking",
    },
    {
      label: "Reksa dana",
      description:
        "Kami memberikan peluang untuk Anda berinvestasi pada pasar modal.",
      image: Section3Image,
      link: "/produk-layanan/reksa-dana",
    },
    {
      label: "Fixed Income",
      description:
        "Jasa transaksi dan perantara surat utang berharga untuk jangka menengah dan jangka panjang dengan imbal hasil pendapatan tetap secara berkala. ",
      image: Section4Image,
      link: "/produk-layanan/fixed-income",
    },
    {
      label: "SFAST App",
      description:
        "Aplikasi pintar yang membantu Anda untuk berinvestasi dimanapun dan kapanpun. ",
      image: Section5Image,
      link: "/produk-layanan/sfast-app",
    },
    {
      label: "Riset & Rekomendasi",
      description:
        "Tim profesional kami akan memberikan update terkini kondisi pasar modal serta analisis komperhensif yang dapat dijadikan sebagai pertimbangan dalam mengambil keputusan investasi.",
      image: Section6Image,
      link: "/produk-layanan/research-recommendation",
    },
  ]);
  const [active, setActive] = useState(0);

  return (
    <div className="section2">
      <Row gutter={20}>
        <Col span={24}>
          <Text className="h1">Produk & Layanan</Text>
          <Text className="h5">
            Kami menawarkan berbagai ragam produk dan layanan
          </Text>
        </Col>
        <Col className="button-group" span={24}>
          <Space size={"small"}>
            {data &&
              data.map((item, index) => (
                <Button
                  key={index}
                  className={`btn-primary ${active === index && "active"}`}
                  onClick={() => {
                    setActive(index);
                    slider.current.goTo(index);
                  }}
                >
                  {item.label}
                </Button>
              ))}
          </Space>
        </Col>
        <Col span={24}>
          <div className="carousel-product">
            <Card>
              <Carousel
                ref={slider}
                afterChange={(dataSlider) => setActive(dataSlider)}
                autoplay
              >
                {data &&
                  data.map((item, index) => (
                    <Row
                      key={index}
                      gutter={20}
                      justify={"space-between"}
                      align={"middle"}
                    >
                      <Col
                        span={24}
                        order={2}
                        md={{ span: 24, order: 2 }}
                        lg={{ span: 12, order: 1 }}
                        style={{ textAlign: "left" }}
                      >
                        <Text className="h3">{item.label}</Text>
                        <Text className="body">{item.description}</Text>
                        <Link to={item.link}>
                          <Text className="menu-sub-title-2">Lebih Lanjut</Text>
                        </Link>
                      </Col>
                      <Col
                        span={24}
                        order={1}
                        md={{ span: 24, order: 1 }}
                        lg={{ span: 12, order: 2 }}
                      >
                        <div className="img-section">
                          <Image
                            className="img-rounded"
                            preview={false}
                            src={item.image}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
              </Carousel>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Section2;
