import React, { useCallback, useContext, useEffect, useState } from 'react'
import moment from 'moment';
import ServiceContext from "../../Services";
import LayoutPage from '../../Layout'
import PageHeader from '../../Component/Global/PageHeader'
import { Card, Col, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import Badge from '../../Component/Base/Badge'
import Pagination from '../../Component/Base/Pagination'
import Button from '../../Component/Base/Button'

moment.locale("id");

const { Text } = Typography;
const PUBLIC_URL = process.env.REACT_APP_FILE_URL;

const enums = {
  'Sosialisasi & Edukasi': 'success',
  // 'GID RUMAH BUMN': 'warning',
  'GID SIDDHI': 'danger',
  'GI UNSIKA': 'primary',
  'Lainnya': 'purple'
}

const defaultQuery = {
  populate: "*",
  sort: ["tanggal:desc"],
  "pagination[page]": 1,
  "pagination[pageSize]": 9,
}

const types = [
  {
    title: 'Semua',
    baseColor: 'outline-secondary',
    type: 'Semua'
  },
  {
    title: (
      <>
        <span 
          style={{ 
            display: 'inline-block', 
            width: 16, 
            height: 16, 
            borderRadius: 999, 
            background: '#FF6058',
            marginRight: 8
          }} 
        />
        GID SIDDHI
      </>
    ),
    baseColor: 'outline-secondary',
    type: 'GID SIDDHI'
  },
  // {
  //   title: (
  //     <>
  //       <span 
  //         style={{ 
  //           display: 'inline-block', 
  //           width: 16, 
  //           height: 16, 
  //           borderRadius: 999, 
  //           background: '#FFB051',
  //           marginRight: 8
  //         }} 
  //       />
  //       GID RUMAH BUMN
  //     </>
  //   ),
  //   baseColor: 'outline-secondary',
  //   type: 'GID RUMAH BUMN'
  // },
  {
    title: (
      <>
        <span 
          style={{ 
            display: 'inline-block', 
            width: 16, 
            height: 16, 
            borderRadius: 999, 
            background: '#0C0B8D',
            marginRight: 8
          }} 
        />
        GI UNSIKA
      </>
    ),
    baseColor: 'outline-secondary',
    type: 'GI UNSIKA'
  },
  {
    title: (
      <>
        <span 
          style={{ 
            display: 'inline-block', 
            width: 16, 
            height: 16, 
            borderRadius: 999, 
            background: '#00AB6B',
            marginRight: 8
          }} 
        />
        Sosialisasi & Edukasi
      </>
    ),
    baseColor: 'outline-secondary',
    type: 'Sosialisasi & Edukasi'
  },
  {
    title: (
      <>
        <span 
          style={{ 
            display: 'inline-block', 
            width: 16, 
            height: 16, 
            borderRadius: 999, 
            background: '#DB759A',
            marginRight: 8
          }} 
        />
        Lainnya
      </>
    ),
    baseColor: 'outline-secondary',
    type: 'Lainnya'
  }
]

function Activities() {
  const { getAllService } = useContext(ServiceContext);

  const [data, setData] = useState([]);
  const [type, setType] = useState('Semua');
  const [meta, setMeta] = useState({
    'page': 1,
    'pageSize': 9,
    'pageCount': 1,
    'total': 1
  });
  const [pagination, setPagination] = useState({ page: 1, size: 9 });

  const getActivitiesAPI = useCallback(async () => {
    let payload = defaultQuery

    if (type !== 'Semua') {
      payload['filters[Tag]'] = type
    } else {
      payload['filters[Tag]'] = undefined
    }

    const response = await getAllService('kegiatans', payload);

    if (response) {
      setData(response.data);
      setMeta(response?.meta?.pagination)
    }
  });


  const onChangePagination = (num) => {
    const maxPage = meta?.pageCount;

    if (num > maxPage || num < 1) return;

    setPagination((prev) => ({
      ...prev,
      page: num
    }));
  };

  useEffect(() => {
    getActivitiesAPI();
  }, [pagination, type]);

  return (
    <LayoutPage>
      <PageHeader
        title="Berita & Kegiatan Terbaru"
        menu={[
          {
            link: "/berita",
            label: 'Berita & Pengumuman '
          },
          {
            link: "/kegiatan",
            label: 'Kegiatan'
          }
        ]}
        path={`/kegiatan`}
      />

      <div className="layout-single-page">
        <div className="top">
          <section className='sub-heading'>
            <Text className="h3">Kegiatan</Text>
            <p>Menyajikan Informasi Mengenai Berbagai Kegiatan dan Acara dari Surya Fajar Sekuritas</p>
          </section>
        </div>
      </div>

      <div className="layout-single-page news">
        <section className='section-activities'>
          <Row gutter={[8]} className='types'>
            {types.map((e) => (
              <Col>
                <Button 
                  title={e.title}
                  style={{ display: 'flex', alignItems: 'center' }}
                  color={type === e.type ? 'primary' : e.baseColor} 
                  onClick={() => setType(e.type)}
                />
              </Col>
            ))}
          </Row>

          <Row gutter={[24, 46]}>
            {data.map((e) => (
              <Col span={24} xl={8}>
                <Link to={`/kegiatan/detail/${e.id}`}>
                  <Card className='product'>
                    <img src={`${PUBLIC_URL}${e?.attributes?.gambar?.data?.attributes?.url}`} alt="Picture" loading='lazy' />
                    <Badge title={e?.attributes?.tag} color={enums[e?.attributes?.tag]} style={{ marginTop: 20 }} />
                    <Text className="h5 judul" style={{ marginTop: 6 }}>{e?.attributes?.judul}</Text>
                    <Text className="body">{moment(e?.attributes?.tanggal).format('dddd, DD MMMM YYYY')}</Text>
                  </Card>
                </Link>
              </Col>
            ))}

            <Col span={24}>
              <Pagination
                currentPage={pagination?.page} 
                totalPages={meta?.pageCount}
                handlePageChange={onChangePagination}
              />
            </Col>
          </Row>
        </section>
      </div>
    </LayoutPage>
  )
}

export default Activities