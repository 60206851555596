import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import "../../Assets/Css/Section7.css";
import ServiceContext from "../../Services";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const { Text } = Typography;

const Section7 = () => {
  const { getAllService } = useContext(ServiceContext);
  const [dataBerita, setDataBerita] = useState([]);

  const getBerita = async () => {
    const data = await getAllService("beritas", {
      populate: "*",
      sort: ["tanggal:desc"],
      "pagination[page]": 1,
      "pagination[pageSize]": 3,
    });
    if (data) {
      setDataBerita(data?.data);
    }
  };

  useEffect(() => {
    getBerita();
  }, []);

  return (
    <div className="section7">
      <Row gutter={20}>
        <Col span={24}>
          <Text className="h1">Berita & Pengumuman Terbaru</Text>
          <Text className="h5">
            Baca berita dan acara terbaru dikemas khusus untuk Anda
          </Text>
        </Col>
        {dataBerita &&
          dataBerita.map((item, index) => (
            <Col key={index} span={24} lg={8}>
              <Link to={`/berita/detail/${item.id}`}>
                <Card className="product">
                  <img
                    alt="news"
                    src={
                      process.env.REACT_APP_FILE_URL +
                      item?.attributes?.gambar?.data?.attributes?.formats
                        ?.small?.url
                    }
                    preview={false}
                  />
                  <Text className="h5 judul">{item?.attributes?.judul}</Text>
                  <Text className="body">
                    {moment(item?.attributes?.tanggal).format(
                      "dddd, DD MMMM YYYY"
                    )}
                  </Text>
                </Card>
              </Link>
            </Col>
          ))}
        <Col span={24} style={{ textAlign: "right", marginTop: "20px" }}>
          <Link to="/berita">
            <Button className="button-header">Lihat Semua</Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Section7;
