import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Row,
  Space,
  Typography,
} from "antd";
import React, { createRef, useState } from "react";
import { Link } from "react-router-dom";
import Section1Image from "../../Assets/Images/LandingPage/section5-1.png";
import Section2Image from "../../Assets/Images/LandingPage/section5-2.png";
import Section3Image from "../../Assets/Images/LandingPage/section5-3.png";
import "../../Assets/Css/Section5.css";

const { Text } = Typography;

const Section5 = () => {
  const slider = createRef();

  const [data, setData] = useState([
    {
      label: "Daftar Akun",
      description:
        "Anda cukup mendepositkan dana/saham ke dalam rekening anda (rekening efek atau rekening dana nasabah), untuk mengaktifkan akun anda.",
      image: Section1Image,
      link: "https://registration.sfsekuritas.co.id/",
    },
    {
      label: "Deposit",
      description:
        "Anda cukup mendepositkan dana/saham ke dalam rekening anda (rekening efek atau rekening dana nasabah), untuk mengaktifkan akun anda.",
      image: Section2Image,
      link: "",
    },
    {
      label: "Mulai Trading",
      description:
        "Anda dapat mulai bertransaksi secara langsung segera setelah rekening efek anda aktif atau dapat juga menghubungi dealer kami untuk berdiskusi atau meminta rekomendasi ahli.",
      image: Section3Image,
      link: "https://sfast.id/",
    },
  ]);
  const [active, setActive] = useState(0);

  return (
    <div className="section5">
      <Row gutter={20}>
        <Col span={24}>
          <Text className="h1">Cara Bergabung</Text>
          <Text className="h5">
            Tiga langkah mudah untuk memulai investasi bersama SF Sekuritas
          </Text>
        </Col>
        <Col span={24}>
          <Row className="item-container" gutter={20} justify={"space-between"} align={"middle"}>
          {data &&
            data.map((item, index) => (
              <Col key={index} span={24} lg={8}>
                <Card className="product">
                  <a href={item.link} target={"_blank"} rel={"noreferrer"}>
                    <Image preview={false} src={item.image} />
                    <Text className="h3">
                      {item.label}
                    </Text>
                    <Text className="body">{item.description}</Text>
                  </a>
                </Card>
              </Col>
            ))}
            </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Section5;
