import React, { useContext, useState } from "react";

import YouTube from "react-youtube";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  notification,
  Row,
  Typography,
} from "antd";
import ServiceContext from "../../Services";
import PageHeader from "../../Component/Global/PageHeader";
import LayoutPage from "../../Layout";
import "../../Assets/Css/SinglePage.css";
import Contact1Image from "../../Assets/Images/Contact/contact-1.png";
import Contact2Image from "../../Assets/Images/Contact/contact-2.png";
import Contact3Image from "../../Assets/Images/Contact/contact-3.png";
import Contact4Image from "../../Assets/Images/Contact/contact-4.png";
import Contact5Image from "../../Assets/Images/Contact/whatsapp.png";
import Image17 from "../../Assets/Images/Contact/Image-17.png"
import PinMapsIcon from "../../Assets/Icons/pinMaps.svg";
import Frame from "../../Assets/Images/Contact/frame.png"
import { Link } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import GoogleMapReact from "google-maps-react-markers";

const { Text } = Typography;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Contact() {
  const { submitContactUs } = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    const data = await submitContactUs(values);
    if (data) {
      notification.success({
        message: "Sukses",
        description: "Berhasil mengirimkan Pesan!",
      });
      form.resetFields();
    }
    setLoading(false);
  };

  return (
    <LayoutPage>
      <PageHeader title="Hubungi Kami" path={`/contact`} />
      <div className="layout-single-page contact">
        <div className="top">
          <Text className="h3">Kantor Pusat dan Cabang Kami</Text>
          <p
            className="text-center"
            style={{
              fontSize: "16px",
              marginTop: "-20px",
              color: " #757575",
              textAlign: "center",
            }}
          >
            Kami siap membantu dengan pertanyaan atau Anda. Silakan hubungi kami
            atau kunjungi cabang terdekat kami.
          </p>
          <Card>
            <Form form={form} className="form" onFinish={onFinish}>
              <p className="text-left mb-0" style={{ fontSize: "20px" }}>
                <b>Kantor Pusat</b>
              </p>
              <p
                className="text-left"
                style={{ fontSize: "24px", marginTop: "-20px" }}
              >
                <b>PT. Surya Fajar Sekuritas</b>
              </p>
              <p
                className="text-left"
                style={{ fontSize: "16px", marginTop: "-20px" }}
              >
                Satrio Tower Building Lt. 9 Unit B, Jalan Prof. Dr. Satrio Blok
                C4/5, Kuningan, DKI Jakarta 12950, Indonesia
              </p>
              <Row gutter={[20, 10]}>
                <Col span={24} lg={12} className="maps-main">
                  <GoogleMapReact
                    apiKey={process.env.REACT_APP_API_KEY}
                    defaultCenter={{ lat: -6.2253775, lng: 106.8282544 }}
                    defaultZoom={12}
                  >
                    <div lat={-6.2253775} lng={106.8282544}>
                      <Image preview={false} src={PinMapsIcon} />
                    </div>
                  </GoogleMapReact>
                </Col>
                <Col span={24} lg={12}>
                  <Row gutter={[20]}>
                    <Col span={24}>
                      <p
                        className="mr-20"
                        style={{
                          fontSize: "16px",
                          marginBottom: "2px",
                          marginLeft: "6px",
                        }}
                      >
                        Nama Lengkap
                      </p>
                      <Form.Item
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                        name="nama"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                      >
                        <Input placeholder=" Masukkan nama lengkap*" />
                      </Form.Item>
                    </Col>
                    <Col span={24} lg={24}>
                      <p
                        className="mr-20"
                        style={{
                          fontSize: "16px",
                          marginBottom: "2px",
                          marginLeft: "6px",
                        }}
                      >
                        Alamat Email
                      </p>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please input as email format!",
                          },
                        ]}
                      >
                        <Input placeholder=" Masukkan email*" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <p
                        className="mr-20"
                        style={{
                          fontSize: "16px",
                          marginBottom: "2px",
                          marginLeft: "6px",
                        }}
                      >
                        Subjek
                      </p>
                      <Form.Item
                        name="topik"
                        rules={[
                          {
                            required: true,
                            message: "Please input your topic!",
                          },
                        ]}
                      >
                        <Input placeholder=" Masukan Subjek*" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <p
                        className="mr-20"
                        style={{
                          fontSize: "16px",
                          marginBottom: "2px",
                          marginLeft: "6px",
                        }}
                      >
                        Pesan
                      </p>
                      <Form.Item
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                        name="isiPesan"
                        rules={[
                          {
                            required: true,
                            message: "Please input your messages!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Masukan Pesan*"
                          as="textarea"
                          rows={3}
                          style={{
                            fontSize: "16px",
                            borderRadius: "10px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    loading={loading}
                    className="button-header"
                    htmlType="submit"
                  >
                    Kirim
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <div className="bottom">
         

         
          <Card
            className=""
            style={{
              backgroundColor: "#A1C4FD ",
              borderRadius: "16px",
            }}
          >
            <Text
              className="h3 text-center"
              style={{
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              <b>Anda juga dapat menghubungi kami via</b>
            </Text>
            <Row gutter={[20, 20]} justify={"space-between"} align={"middle"}>
              <Col span={24} lg={8}>
                <Card
                  className=""
                  style={{ backgroundColor: "white", borderRadius: "16px" }}
                >
                  <Row
                    gutter={[20, 20]}
                    justify={"space-between"}
                    align={"middle"}
                  >
                    <Col span={24} lg={4}>
                      <Image preview={false} src={Contact3Image} />
                    </Col>
                    <Col span={24} lg={20}>
                      <a href="mailto:cs@sfsekuritas.co.id">
                        <Text className="p" style={{ fontSize: "24px" }}>
                          cs@sfsekuritas.co.id
                        </Text>
                      </a>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24} lg={8}>
                <Card
                  className=""
                  style={{ backgroundColor: "white", borderRadius: "16px" }}
                >
                  <Row
                    gutter={[20, 20]}
                    justify={"space-between"}
                    align={"middle"}
                  >
                    <Col span={24} lg={4}>
                      <Image preview={false} src={Contact4Image} />
                    </Col>
                    <Col span={24} lg={20}>
                      <a href="tel:02127883989">
                        <Text className="p" style={{ fontSize: "24px" }}>
                          021-2788-3989
                        </Text>
                      </a>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24} lg={8}>
                <Card
                  className=""
                  style={{ backgroundColor: "white", borderRadius: "16px" }}
                >
                  <Row
                    gutter={[20, 20]}
                    justify={"space-between"}
                    align={"middle"}
                  >
                    <Col span={24} lg={4}>
                      <Image preview={false} src={Contact5Image} />
                    </Col>
                    <Col span={24} lg={20}>
                      <a
                        target="_blank"
                        rel="noreferrer"  
                        href="https://wa.me/6281317755509"
                      >
                        <Text className="p" style={{ fontSize: "24px" }}>
                            Whatsapp
                        </Text>
                      </a>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>

          <br /><br /><hr />

          <Text
            className="p"
            style={{
              fontSize: "24px",
              
            }}
          >
            <br /><b>Kantor Cabang:</b>
          </Text>

          <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
            <Col span={24} lg={12}>
              <Card
                className=""
                style={{
                  backgroundColor: "#A1C4FD",
                  borderRadius: "16px",
                  // marginBottom: "20px",
                  // marginTop: "20px",
                  height: '100%'
                }}
              >
                <Row
                  gutter={[20, 20]}
                  justify={"space-between"}
                  align={"middle"}
                >
                  <Col span={24} lg={24} className="maps-child" style={{backgroundColor: "white", padding: "8px", borderRadius: "4px"}}>
                    <GoogleMapReact
                      apiKey={process.env.REACT_APP_API_KEY}
                      defaultCenter={{ lat: -8.6673504, lng: 115.238194 }}
                      defaultZoom={12}
                    >
                      <div lat={-8.6673504} lng={115.238194}>
                        <Image preview={false} src={PinMapsIcon} />
                      </div>
                    </GoogleMapReact>
                  </Col>
                  <Col span={24} lg={24}>
                    <Text className="p" style={{ fontSize: "24px" }}>
                      <b>Bali - Denpasar</b>
                      <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "18px" }}>
                      Kantor Perwakilan Bursa Efek Indonesia Denpasar Jl. Cok
                      Agung Tresno No. 163 Panjer, Denpasar, Bali 80239 <br /> <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "16px", backgroundColor: "white", borderRadius: "4px", padding: "4px" }}>
                      <Image
                        preview={false}
                        src={Contact3Image}
                        width={"20px"}
                      />{" "}
                      cs.bali@sfsekuritas.co.id 
                    </Text>
                    <Text></Text>
                    <Text className="p" style={{ fontSize: "16px", backgroundColor: "white", borderRadius: "4px", padding: "4px", margin: "8px" }}>
                      <Image
                        preview={false}
                        src={Contact4Image}
                        width={"20px"}
                      />{" "}
                      +62 877 2788 3989 
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={24} lg={12}>
              <Card
                className=""
                style={{
                  backgroundColor: "#A1C4FD",
                  borderRadius: "16px",
                  // marginBottom: "20px",
                  // marginTop: "20px",
                  height: '100%'
                }}
              >
                <Row
                  gutter={[20, 20]}
                  justify={"space-between"}
                  align={"middle"}
                >
                  <Col span={24} lg={24} className="maps-child" style={{backgroundColor: "white", padding: "8px", borderRadius: "4px"}}>
                    <GoogleMapReact
                      apiKey={process.env.REACT_APP_API_KEY}
                      defaultCenter={{ lat: -0.05530936497822656, lng:109.34320385951695 }}
                      defaultZoom={12}
                      
                    >
                      <div lat={-0.05530936497822656} lng={109.34320385951695}>
                        <Image preview={false} src={PinMapsIcon}  />
                      </div>
                    </GoogleMapReact>
                  </Col>
                  <Col span={24} lg={24}>
                    <Text className="p" style={{ fontSize: "24px" }}>
                      <b>Pontianak - Kalimantan Barat</b>
                      <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "18px" }}>
                    Komplek Central Perdana No. A2-A3, 78124, Jl. Perdana, Parit Tokaya, South Pontianak, Pontianak, West Kalimantan 78115 <br /> <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "16px", backgroundColor: "white", borderRadius: "4px", padding: "4px" }}>
                      <Image
                        preview={false}
                        src={Contact3Image}
                        width={"20px"}
                      />{" "}
                      cs.pontianak@sfsekuritas.co.id 
                    </Text>
                    <Text className="p" style={{ fontSize: "16px", backgroundColor: "white", borderRadius: "4px", padding: "4px", margin: "8px" }}>
                      <Image
                        preview={false}
                        src={Contact4Image}
                        width={"20px"}
                      />{" "}
                      +62 815 6322 8789
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <br /><hr />

          <Text
            className="p"
            style={{
              fontSize: "24px",
              
            }}
          >
            <br /><b>Galeri Investasi:</b>
          </Text>

          <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
            <Col span={24} lg={12}>
              <Card
                className=""
                style={{
                  backgroundColor: "#A1C4FD",
                  borderRadius: "16px",
                  height: '100%'
                  // marginBottom: "20px",
                  // marginTop: "20px",
                }}
              >
                <Row
                  gutter={[20, 20]}
                  justify={"space-between"}
                  align={"middle"}
                >
                  
                  <Col span={24} lg={24}>
                  <Image preview={false} src={Frame} width="50%" /><br /><br />
                    <Text className="p" style={{ fontSize: "20px" }}>
                      <b>PERKUMPULAN  SARJANA DAII PROFESIONAL BUDDHIS INDONESIA (SIDDHI)</b>
                      <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "18px" }}>
                    Jl. Dr. Muwardi I No.7, RT.7/RW.3, Grogol, Kec. Grogol petamburan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11450 <br />
                    </Text>
                    
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* <Col span={24} lg={12}>
              <Card
                className=""
                style={{
                  backgroundColor: "#A1C4FD",
                  borderRadius: "16px",
                  // marginBottom: "20px",
                  // marginTop: "20px",
                  height: '100%'
                }}
              >
                <Row
                  gutter={[20, 20]}
                  justify={"space-between"}
                  align={"middle"}
                >
                  
                  <Col span={24} lg={24}>
                  <Image preview={false} src={Frame} width="50%" /><br /><br />
                    <Text className="p" style={{ fontSize: "20px" }}>
                      <b>Rumah BUMN Denpasar</b>
                      <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "18px" }}>Jl. Raya Sesetan No.130, Sesetan, Denpasar Selatan, Kota Denpasar, Bali 80225</Text>
                    
                  </Col>
                </Row>
              </Card>
            </Col> */}

            <Col span={24} lg={12}>
              <Card
                className=""
                style={{
                  backgroundColor: "#A1C4FD",
                  borderRadius: "16px",
                  // marginBottom: "20px",
                  // marginTop: "20px",
                  height: '100%'
                }}
              >
                <Row
                  gutter={[20, 20]}
                  justify={"space-between"}
                  align={"middle"}
                >
                  
                  <Col span={24} lg={24}>
                  <Image preview={false} src={Image17} width="40%" /><br /><br />
                    <Text className="p" style={{ fontSize: "20px" }}>
                      <b>University of Singaperbangsa Karawang (UNSIKA)</b>
                      <br />
                    </Text>
                    <Text className="p" style={{ fontSize: "18px" }}>Jl. HS.Ronggo Waluyo, Puseurjaya, Telukjambe Timur, Karawang, Jawa Barat 41361</Text>
                    
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>


        </div>
      </div>
    </LayoutPage>
  );
}

export default Contact;
