import { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import ServiceContext from "../../Services";
import "../../Assets/Css/SFQuotes.css";
import moment from "moment";

function SFQuotes() {
  const { getAllService } = useContext(ServiceContext);
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: "Stock",
      key: "stock",
    },
    {
      title: "Buy / Sell Entry",
      key: "buySellEntry",
    },
    {
      title: "Recommendation",
      key: "recommendation",
    },
    {
      title: "Stop Loss",
      key: "stop_loss",
    },
    {
      title: "Target",
      key: "target",
    },
  ];

  const getSFQuotes = async () => {
    const data = await getAllService("sf-quotes",{"filters[listingDate][$eq]":moment().format("YYYY-MM-DD")});
    if (data) {
      setDataSource(data?.data);
    }
  };

  useEffect(() => {
    getSFQuotes();
  }, []);

  return (
    <div className="page">
      <table>
        <thead>
          <tr>
            {columns &&
              columns.map((item) => <th key={item.key}>{item.title}</th>)}
          </tr>
        </thead>
        <tbody>
          {dataSource &&
            dataSource.map((item, index) => (
              <tr key={index}>
                <td>{item?.attributes?.stock}</td>
                <td>{item?.attributes?.buySellEntry}</td>
                <td style={{ color: "#3fc451" }}>
                  {item?.attributes?.recommendation}
                </td>
                <td>{item?.attributes?.stoploss}</td>
                <td>{item?.attributes?.target}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="disclaimer">
        <p>*Disclaimer</p>
        <p>
          Data ini disusun oleh PT Surya Fajar Sekuritas kepada klien kami dan
          disediakan hanya untuk tujuan informasi.
        </p>
        <p>
          Data ini tidak dapat digunakan untuk, atau dianggap sebagai, tawaran
          untuk menjual, atau ajakan untuk berinvestasi.
        </p>
        <p>
          Informasi yang terkandung dalam laporan ini seluruhnya diproduksi
          secara independen dan dimiliki oleh PT Surya Fajar Sekuritas.
        </p>
        <p>
          Informasi yang diberikan dalam data ini tidak ada jaminan bahwa hasil
          atau peristiwa di masa depan akan konsisten dengan informasi ini.
        </p>
        <p>
          Data ini dapat berubah tanpa pemberitahuan sebelumnya dan PT Surya
          Fajar Sekuritas berhak untuk membuat modifikasi dan perubahan terhadap
          pernyataan ini sebagaimana yang diperlukan dari waktu ke waktu.
        </p>
        <p>
          PT Surya Fajar Sekuritas tidak bertanggung jawab atas kerugian
          langsung, tidak langsung, konsekuensial, atau kerugian lainnya,
          termasuk namun tidak terbatas pada kerugian yang disebabkan oleh
          pengguna atau pihak ketiga lainnya yang timbul dari penggunaan data
          ini.
        </p>
        <p>
          Target harga saham dalam laporan ini merupakan nilai fundamental,
          bukan nilai pasar wajar atau harga transaksi yang direferensikan oleh
          peraturan.
        </p>
      </div>
    </div>
  );
}

export default SFQuotes;
