import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import "../../Assets/Css/MenuMobile.css";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import VideoTutorial from "../../Assets/videoTutorial.png";
import FlagEnglish from "../../Assets/Icons/flag-english.png";
import FlagIndonesia from "../../Assets/Icons/flag-indonesia.png";
import Modal from "antd/lib/modal/Modal";

const { Text } = Typography;

const MenuMobile = (props) => {
  const [language, setLanguage] = useState("Indonesia");
  const [submenu, setSubmenu] = useState(false);
  const [subIndex, setSubIndex] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const youtube = useRef("");
  // const opts = {
  //   height: "718px",
  //   width: "100%",
  //   playerVars: {
  //     autoplay: 1,
  //   },
  // };

  // const showModal = () => {
  //   setIsModalOpen(true);
  //   youtube.current.resetPlayer();
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   youtube.current.internalPlayer.stopVideo();
  // };

  const subMenuChange = (index) => {
    if (submenu) {
      setSubmenu(false);
      setSubIndex("");
    } else {
      setSubmenu(true);
      setSubIndex(index);
    }
  };

  const setDefault = () => {
    props.setActive("");
    setSubmenu(false);
    setSubIndex("");
  };

  return (
    <>
      <Row
        style={{ alignItems: "start" }}
        gutter={[20, 20]}
        className={`sfs-menu ${props.active} ${submenu ? "submenu" : ""}`}
      >
        <Col className="menu" span={24}>
          <Link to="/daftar" className="menu-mobile">
            Cara Pendaftaran
          </Link>
        </Col>
        <Col
          className={`menu ${subIndex === 0 && submenu ? "active" : ""}`}
          span={24}
        >
          <div className="menu-mobile has-sub" onClick={() => subMenuChange(0)}>
            <div className="submenu-mobile-title">Tentang Kami</div>
          </div>
        </Col>
        <Col
          className={`menu ${subIndex === 1 && submenu ? "active" : ""}`}
          span={24}
        >
          <div className="menu-mobile has-sub" onClick={() => subMenuChange(1)}>
            <div className="submenu-mobile-title">Produk & Layanan</div>
          </div>
        </Col>
        <Col
          className={`menu ${subIndex === 2 && submenu ? "active" : ""}`}
          span={24}
        >
          <div className="menu-mobile has-sub" onClick={() => subMenuChange(2)}>
            <div className="submenu-mobile-title">Riset & Rekomendasi</div>
          </div>
        </Col>
        <Col className="menu" span={24}>
          <Link to="/berita" className="menu-mobile">
            Berita & Pengumuman
          </Link>
        </Col>

        {subIndex === 0 && submenu && (
          <>
            <Col className="menu sub first" span={24}>
              <Link
                to="/profil/profil-perusahaan"
                onClick={setDefault}
                className="menu-mobile"
              >
                Profil Perusahaan
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/profil/kenapa-kami"
                onClick={setDefault}
                className="menu-mobile"
              >
                Kenapa Pilih Kami
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/profil/sejarah-perusahaan"
                onClick={setDefault}
                className="menu-mobile"
              >
                Sejarah Perusahaan
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/profil/tata-kelola-perusahaan"
                onClick={setDefault}
                className="menu-mobile"
              >
                Tata Kelola Perusahaan
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/profil/tim-kami"
                onClick={setDefault}
                className="menu-mobile"
              >
                Tim Kami
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/hubungi-kami"
                onClick={setDefault}
                className="menu-mobile"
              >
                Hubungi Kami
              </Link>
            </Col>
          </>
        )}

        {subIndex === 1 && submenu && (
          <>
            <Col className="menu sub first" span={24}>
              <Link
                to="/produk-layanan/perdagangan-efek"
                onClick={setDefault}
                className="menu-mobile"
              >
                Perdagangan Saham
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/produk-layanan/invesment-banking"
                onClick={setDefault}
                className="menu-mobile"
              >
                Investment Banking
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/produk-layanan/reksa-dana"
                onClick={setDefault}
                className="menu-mobile"
              >
                Reksa Dana
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/produk-layanan/fixed-income"
                onClick={setDefault}
                className="menu-mobile"
              >
                Fixed Income
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/produk-layanan/sfast-app"
                onClick={setDefault}
                className="menu-mobile"
              >
                SFAST App
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/produk-layanan/research-recommendation"
                onClick={setDefault}
                className="menu-mobile"
              >
                Riset & Rekomendasi
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/produk-layanan/investor-incubator"
                onClick={setDefault}
                className="menu-mobile"
              >
                Investor Incubator
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <a
                href="https://lms.sfsekuritas.co.id/"
                target="_blank"
                rel="noreferrer"
                onClick={setDefault}
                className="menu-mobile"
              >
                SF LMS
              </a>
            </Col>
          </>
        )}

        {subIndex === 2 && submenu && (
          <>
            <Col className="menu sub first" span={24}>
              <Link
                to="/riset/riset?type=riset-harian"
                onClick={setDefault}
                className="menu-mobile"
              >
                Riset Harian
              </Link>
            </Col>
            <Col className="menu sub" span={24}>
              <Link
                to="/riset/prospektus?type=prospektus-umum"
                onClick={setDefault}
                className="menu-mobile"
              >
                Prospektus
              </Link>
            </Col>
          </>
        )}

        <Divider style={{ margin: "0" }} />

        <Col span={12} style={{ textAlign: "center" }}>
          <Switch
            checkedChildren={<Image preview={false} src={FlagIndonesia} />}
            unCheckedChildren={<Image preview={false} src={FlagEnglish} />}
            defaultChecked
            onChange={(data) =>
              data ? setLanguage("Indonesia") : setLanguage("English")
            }
          />
          <Text className="language-title">{language}</Text>
        </Col>

        <Col span={12} style={{ textAlign: "center" }}>
          <a href={"https://sfast.id/"} target="_blank" rel="noreferrer">
            <Button className="button-header">SFAST APP</Button>
          </a>
        </Col>
      </Row>

      {/* <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={"100%"}
        className={"modal-youtube"}
        footer={null}
        closeIcon={null}
      >
        <YouTube ref={youtube} videoId="-4Byh-VBPrc" onEnd={true} opts={opts} />
      </Modal> */}
    </>
  );
};

export default MenuMobile;
